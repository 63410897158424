import React, {Component} from "react";
import parse from "html-react-parser";

const Stars = (n) => {
    let stars = []
    for (let i = 0; i < n; ++i) {
        stars.push(`<i class="icon star"></i>`)
    }

    if (stars.length === 0) {
        return (<>{parse("<span class='muted'>Not yet rated</span>")}</>)
    }

    return (<>{parse(stars.join(''))}</>)
}

export default class ModderCard extends Component {
    render() {
        return (
            <a href={this.props.mod.workshopURL} className="ui card link" key={this.props.mod.name} target="_blank" rel="noopener noreferrer" title="View on workshop">
                <div className="image">
                    <img src={this.props.mod.thumbnail} alt={this.props.mod.name} />
                </div>

                <div className="content">
                    <div className="header">{this.props.mod.name}</div>
                    <div className="meta">
                        <span className="date">By {this.props.mod.author}</span>
                    </div>
                </div>

                <div className="extra content">
                    <div className="star-rating">
                        {Stars(this.props.mod.rating)}
                    </div>
                </div>
            </a>
        )
    }
}
