import React from 'react';
import Loader from "../../Utils/Loader";
import ItemCardRedo from "../Items/ItemCardRedo";
import {API_URL} from "../../Utils/Config";
import SettingsContext from "../../SettingsContext";
import InventoryGrid from "../Items/InventoryGrid"; // Import SettingsContext

export default class Database extends React.Component {
    static contextType = SettingsContext; // Use SettingsContext in class component
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            filtered: null,
            noResults: false,
            data: null,
            inputFilter: "",
            showDLC: false,
        }
    }

    handleChange = (event) => {
        window.location.href = `#${event.target.value}`;
    };

    handleChangeSearch = (event) => {
        const { showDLC } = this.context.settings;
        const filteredItemsR = [];

        this.state.data.forEach(group => {
            const filteredItems = group.items.filter(item => {
                const itemLowerCase = item.name.toLowerCase();
                const searchLowerCase = event.target.value.toLowerCase();
                const isDlcItemVisible = showDLC || item.gameVersion !== "volcalidus";
                return itemLowerCase.includes(searchLowerCase) && isDlcItemVisible;
            });
            filteredItems.forEach(item => filteredItemsR.push(item));
        });

        this.setState({noResults: filteredItemsR.length === 0, filtered: null});

        if (event.target.value !== "") {
            this.setState({filtered: filteredItemsR});
        }
    };

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        // Check if showDLC setting has changed
        if (prevState.showDLC !== this.context.settings.showDLC) {
            this.fetchData(); // Re-fetch data if showDLC setting has changed
        }
    }

    fetchData = async () => {
        const { showDLC } = this.context.settings;
        const url = API_URL + `/api/groups/`;
        const response = await fetch(url);
        const data = await response.json();
        const sortedAndFiltered = data.results.map(group => ({
            ...group,
            items: group.items.filter(item => showDLC || item.gameVersion !== "volcalidus")
        })).filter(group => group.items.length > 0)
            .sort((a, b) => a.name.localeCompare(b.name));

        this.setState({data: sortedAndFiltered, loading: false, showDLC: showDLC});
    };

    render() {
        return (
            <>
                <div className="Page-header sticky database-header">
                    <div className="ui container">
                        <h1>
                            <span>Item Database</span>
                            {this.state.loading || !this.state.data ?
                                (
                                    <Loader/>
                                ) : (
                                    <div className="ui form">
                                        <div className="ui input fluid">
                                            <input type="text" placeholder="Type here to filter the item database..." onChange={this.handleChangeSearch}/>
                                        </div>
                                    </div>
                                )
                            }
                        </h1>
                    </div>
                </div>

                <div className="ui container">

                    <div className="Database">
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <a href="/list" className="ui button icon brand"><i
                                className="shopping cart icon"/> &nbsp; Make a
                                list</a>
                        </div>
                        {this.state.loading || !this.state.data ?
                            (
                                <Loader/>
                            ) : (
                                <>
                                    {this.state.filtered !== null ? (
                                        <>
                                            <div className="ui segment">
                                                <h1>Filtered results</h1>
                                                {this.state.noResults ? (
                                                    <>
                                                        No results found, please clear your filter and try again.
                                                    </>
                                                ) : (
                                                    <>
                                                        <InventoryGrid itemarray={this.state.filtered}/>
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {this.state.data.map(data => (
                                                <div className="ui segment">
                                                    <h1 id={data.name}>
                                                        <a href={`/database/groups/${data.name}`}>{data.name}</a>
                                                        {data.description && (
                                                            <>
                                                                <br/>
                                                                <br/>
                                                                <small>{data.description}</small>
                                                            </>
                                                        )}
                                                    </h1>
                                                    <InventoryGrid itemarray={data.items}/>

                                                </div>
                                            ))}
                                        </>
                                    )}
                                </>
                            )
                        }
                    </div>
                </div>
            </>
        )
    }
}