import React, { useContext, useEffect, useState } from 'react';
import Loader from "../../Utils/Loader";
import { safeName } from "../../Utils/Utils";
import { API_URL } from "../../Utils/Config";
import ItemTooltip from "../../Components/Items/ItemTooltip";
import SettingsContext from "../../SettingsContext";
import TabNavigation from "../../Layouts/TabNavigation";
import PageTitle from "../../Helpers/PageTitle";

const DigsiteList = () => {
    const { settings } = useContext(SettingsContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchDigsites = async () => {
            const url = API_URL + "/api/digsites/all/";
            try {
                const response = await fetch(url);
                const jsonData = await response.json();
                setData(jsonData.results);
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch digsites:", error);
                setLoading(false);
            }
        };

        fetchDigsites();
    }, []);

    return (
        <>

            <PageTitle title={"The digsites of " + settings.favouriteIsland.charAt(0).toUpperCase() + settings.favouriteIsland.slice(1) + ""} subheading={`Learn more about digsites on ${settings.favouriteIsland.charAt(0).toUpperCase() + settings.favouriteIsland.slice(1)}, what they do and what their nearest conveniences are.`} imageClass={settings.favouriteIsland} />

            <div className="ui container">
                <div className="ui breadcrumb inverted"><a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"></i>
                    <a href="/guide-book" className="section">Guide Book</a>
                    <i className="right angle icon divider"/>
                    <span className="section">{settings.favouriteIsland}</span>
                    <i className="right angle icon divider"></i>
                    <div className="active section">Digsites</div>
                </div>

                <TabNavigation/>

                <div className="ui bottom attached tab segment active">
                    {loading || !data ? (
                        <Loader/>
                    ) : (
                        <div className="Digsite-list">
                            {data.filter(item => item.gameVersion ? item.gameVersion.includes(settings.favouriteIsland) : 'bastion').map(item => (
                                <div key={item.id} className="Digsite ui segment">
                                    <div className={"Digsite-header " + safeName(item.name)}>
                                        <h3>{item.name}</h3>
                                    </div>
                                    <div className="Digsite-description">
                                        {item.description}
                                    </div>
                                    <div className="ui middle aligned divided list stackable">
                                        <div className="digsite-item">
                                            Cost:
                                            <span className="value">
                                {item.price.toLocaleString(undefined, {maximumFractionDigits: 0})}c </span>
                                        </div>
                                        <div className="digsite-item">
                                            Closest town:
                                            <span className="value">
                                        {item.town.name} </span>
                                        </div>
                                        <div className="digsite-item">
                                            Depth:
                                            <span className="value">
                                                    {item.depth} Blocks
                                                </span>
                                        </div>
                                    </div>
                                    {item.relics[0] !== "0" && (
                                        <details>
                                            <summary>Show Relics</summary>
                                            <div>
                                                <div className="digsite-relic-list">
                                                    {item.relics.map(r => (
                                                        <ItemTooltip key={r} item_lookup={r}/>
                                                    ))}
                                                </div>
                                                <details>
                                                    <summary>Show Relic Locations</summary>
                                                    <div>
                                                        <img alt={item.name}
                                                             src={`/assets/images/relics-${safeName(item.name)}.jpg`}
                                                             width="100%"/>
                                                    </div>
                                                </details>
                                            </div>
                                        </details>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default DigsiteList;
