import React from 'react';
import {MasterworkRequirementTableRedo} from "../../../Components/Items/ItemDetails";

export default function MasterworkForge() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted"><a href="/" className="section">Bridgepour Wiki Home</a><i
                className="right angle icon divider"></i><a href="/guide-book" className="section">Guide Book</a><i
                className="right angle icon divider"></i>
                <div className="active section">Masterwork Forge</div>
            </div>
            <div className="Page-header">
                <h1>Using the Icehelm Forge
                    <div className="sub-heading">Your subterranean solution for expanding your mines!</div>
                </h1>
            </div>
            <div className="ui segment"><h2>Overview/Intro</h2><p>In the snowy mountains east of Ember Cradle lies a
                long forgotten Dwarven settlement known as Icehelm. Deep under the ground you will find a colossal magma
                chamber that houses what remains of a once great civilization. Monuments to ancient heroes still stand
                guard over the great Forge that holds the secrets of machinery.</p></div>

            <div className="ui segment">
                <h2>Forging your Design</h2>
                <p>First, place your desired design on the pedestal. Head up the stairs and drop in the required resources, see table below for requirements.</p>
                <p>When all resources have been placed in the crucible, <kbd>Left click</kbd> the Hammer Lever to start the forging process.</p>
                <p>The crucible will move along a track and pour out the molten metal, the big hammer will strike the Masterwork Anvil, and your forged item will appear on the top of the Anvil.</p>
                <p>Any excess bar(s) will be deposited at the front left of the Masterwork Anvil.</p>
            </div>

            <div className="ui segment">
                <h2>Masterwork Designs</h2>
                <p>Below are the current Masterwork Designs and the
                requirements (<strong>in weight</strong>) to forge them.</p>
                <p>The numbers along the base of each Design lets you know how much weight of each resource you will need to craft the machine. Designs are not consumed when used to craft new machines.</p>

                <MasterworkRequirementTableRedo />
            </div>
            <p>Should your item not appear, #BlameItchy over in the Hydroneer Discord server.</p><br/></div>
    )
}