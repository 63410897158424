import React from 'react';
import {Helmet} from "react-helmet";
import {Routes, Route, BrowserRouter as Router} from "react-router-dom";
import Home from "./Pages/Home";
import BlogList from "./Pages/BlogList";
import BlogPost from "./Components/Blog/BlogPost";
import Database from "./Components/Database/Database";
import Navigation from "./Pages/Navigation";
import Footer from "./Layouts/Footer";
import Header from "./Layouts/SubHeader";
import DatabaseView from "./Pages/DatabaseView";
import GroupedItems from "./Components/Items/GroupedItems";
import About from "./Pages/About";
import FAQ from "./Pages/FAQ";
import MPFAQ from "./Pages/MPFAQ";
import PatchNotes from "./Pages/PatchNotes";
import Privacy from "./Pages/Privacy";
import Contact from "./Pages/Contact";
import AboutHydroneer from "./Pages/AboutHydroneer";
import Guidebook from "./Pages/Guidebook/Guidebook-OLD";
import DigsiteList from "./Pages/Guidebook/DigsiteList";
import GuidebookTowns from "./Pages/Guidebook/GuidebookTowns";
import GuidebookShopView from "./Pages/Guidebook/GuidebookShopView";
import Speedrun from "./Pages/Speedrun";
import Mods from "./Pages/Mods";
import ModFAQ from "./Pages/ModFAQ";
import Search from "./Pages/Search";
import RedirectLink from "./Components/RedirectLink";
import GuideFirstTimer from "./Pages/Guidebook/Guides/FirstTimer";
import GuideGettingStarted from "./Pages/Guidebook/Guides/GettingStarted";
import SmithingCrafting from "./Pages/Guidebook/Guides/SmithingCrafting";
import HydroLogica from "./Pages/Guidebook/Guides/HydroLogic/HydroLogic_Landing";
import HydroLogicb from "./Pages/Guidebook/Guides/HydroLogic/HydroLogic_LogicBehaviour";
import HydroLogicc from "./Pages/Guidebook/Guides/HydroLogic/HydroLogic_LogicElements";
import HydroLogicd from "./Pages/Guidebook/Guides/HydroLogic/HydroLogic_LogicElementsContinued";
import HydroLogice from "./Pages/Guidebook/Guides/HydroLogic/HydroLogic_LogicElementsContinuedAgain";
import HydroLogicf from "./Pages/Guidebook/Guides/HydroLogic/HydroLogic_BuildExamples";
import MasterworkForge from "./Pages/Guidebook/Guides/MasterworkForge";
import FishingGuide from "./Pages/Guidebook/Guides/FishingGuide";
import FishingCatches from "./Pages/Guidebook/Guides/FishingCatches";
import FishingTools from "./Pages/Guidebook/Guides/FishingTools";
import CreativeGuide from "./Pages/Guidebook/Guides/CreativeGuide";
import LogicBuilder from "./Pages/LogicBuilder";
import NPCGuide from "./Pages/Guidebook/Guides/NPCs";
import TierOneGuide from "./Pages/Guidebook/Guides/TierOne";
import KingsQuest from "./Pages/Guidebook/Guides/KingsQuest";
import VehiclesView from "./Pages/VehiclesView";
import ShoppingList from "./Pages/ShoppingList";
import List from "./Pages/List";
import Pokes from "./Pages/Pokes";
import SettingsPage from "./Pages/Settings";
import { SettingsProvider } from './SettingsContext';
import GuidebookTownView from "./Pages/Guidebook/GuidebookTownView";
import ConstructionTracker from "./Pages/Guidebook/ConstructionTracker";
import GuideView from "./Pages/Guidebook/GuideView";
import SpeedrunAll from "./Pages/SpeedrunAll";
import EasterEggs from "./Pages/EasterEgg";

class Error extends React.Component {
    render() {
        return (
            <>
                <div className="error-page">
                    <h1>4<img src="https://cdn.bridgepour.com/images/items/cloutium-ore.png" alt="0" height="128px"/>4
                    </h1>
                    <p>Your shovel fell under the world.</p>
                    <a href="/">Go back to the homepage</a>
                </div>
            </>
        )
    }
}

// If a Hash is present in the requested URL, we'll scroll to its location on the page.
function hashLinkScroll() {
    const {hash} = window.location;
    if (hash !== '') {
        setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) element.scrollIntoView();
        }, 0);
    }
}

export default function App() {
    return (
        <SettingsProvider>
            <Router onUpdate={hashLinkScroll}>
                <Helmet>
                    <title>Bridgepour | Official Hydroneer Wiki | Tips, Guides, Calculators, Item Database and
                        more!</title>
                    <meta name="description"
                          content="Bridgepour is a fan made wiki for the game Hydroneer, the Hydroneer Wiki. Bridgepour wiki aims to take the role of a tour guide to help players, both new and old, make their time within the world of Hydroneer less of a rush and more of an ease."/>
                </Helmet>
                <div className="Page">
                    {window.location.hostname === 'localhost' ? <div className="dev-banner"><span>DEV</span></div> : ""}
                    <div className="Container">
                        <Header/>
                        <div className="Content">
                            <Routes>
                                <Route path='/' index exact element={<Home/>}/>/>
                                <Route path='/search' exact element={<Search/>}/>
                                {/*<Route path='/easteregg' exact element={<EasterEggs />}/>*/}
                                <Route path='/blog' exact element={<BlogList/>}/>
                                <Route path='/blog/:post_id' exact element={<BlogPost/>}/>
                                <Route path='/database' exact element={<Database/>}/>
                                <Route path='/database/vehicles' exact element={<VehiclesView/>}/>
                                <Route path='/database/:item_safe_name' exact element={<DatabaseView/>}/>
                                <Route path='/database/groups/:group_name' exact element={<GroupedItems/>}/>
                                <Route path='/list/' exact element={<ShoppingList/>}/>
                                <Route path='/list/:encoded_list/:list_name?' exact element={<List/>}/>
                                <Route path='/about' exact element={<About/>}/>
                                <Route path='/about-hydroneer' exact element={<AboutHydroneer/>}/>
                                <Route path='/faq' exact element={<FAQ/>}/>
                                <Route path='/mp-faq' exact element={<MPFAQ/>}/>
                                <Route path='/patch-notes' exact element={<PatchNotes/>}/>
                                <Route path='/privacy' exact element={<Privacy/>}/>
                                <Route path='/speedrun' exact element={<Speedrun/>}/>
                                <Route path='/speedrun/all' exact element={<SpeedrunAll/>}/>
                                <Route path='/contact' exact element={<Contact/>}/>
                                <Route path='/guide-book' exact element={<Guidebook/>}/>
                                <Route path='/guide-book/logic-builder' exact element={<LogicBuilder/>}/>
                                <Route path='/guide-book/digsites' exact element={<DigsiteList/>}/>
                                <Route path='/guide-book/towns' exact element={<GuidebookTowns/>}/>
                                <Route path='/guide-book/towns/:town_safe_name/:town_id' exact element={<GuidebookTownView/>}/>
                                <Route path='/guide-book/towns/:town_safe_name/progress' exact element={<ConstructionTracker/>}/>
                                <Route path='/guide-book/towns/:town_safe_name/:shop_safe_name/:shop_id' exact element={<GuidebookShopView/>}/>
                                <Route path="/guide-book/guide/:guide_id/:guide_title/:guide_page_number?" exact element={<GuideView/>}/>
                                <Route path="/guide-book/guide/first-timer" exact element={<GuideFirstTimer/>}/>
                                <Route path="/guide-book/guide/tier-one" exact element={<TierOneGuide/>}/>
                                <Route path="/guide-book/guide/npc" exact element={<NPCGuide/>}/>
                                <Route path="/guide-book/guide/getting-started" exact element={<GuideGettingStarted/>}/>
                                <Route path="/guide-book/guide/smithing-crafting" exact element={<SmithingCrafting/>}/>
                                <Route path="/guide-book/guide/masterwork-forge" exact element={<MasterworkForge/>}/>
                                <Route path="/guide-book/guide/fishing" exact element={<FishingGuide/>}/>
                                <Route path="/guide-book/guide/fishing-catches" exact element={<FishingCatches/>}/>
                                <Route path="/guide-book/guide/fishing-poles" exact element={<FishingTools/>}/>
                                <Route path="/guide-book/guide/kings-quest" exact element={<KingsQuest/>}/>
                                <Route path="/guide-book/guide/creative-mode" exact element={<CreativeGuide/>}/>
                                <Route path="/guide-book/guide/hydro-logic" exact element={<HydroLogica/>}/>
                                <Route path="/guide-book/guide/hydro-logic/logic-behaviour" exact element={<HydroLogicb/>}/>
                                <Route path="/guide-book/guide/hydro-logic/logic-elements" exact element={<HydroLogicc/>}/>
                                <Route path="/guide-book/guide/hydro-logic/logic-elements-part-two" exact element={<HydroLogicd/>}/>
                                <Route path="/guide-book/guide/hydro-logic/logic-elements-part-three" exact element={<HydroLogice/>}/>
                                <Route path="/guide-book/guide/hydro-logic/build-examples" exact element={<HydroLogicf/>}/>
                                <Route path='/mods' exact element={<Mods/>}/>
                                <Route path='/pokes' exact element={<Pokes/>}/>
                                <Route path='/pokes/all' exact element={<Pokes all={true}/>}/>
                                <Route path='/mod-faq' exact element={<ModFAQ/>}/>
                                <Route path='/link/:search_query' element={<RedirectLink/>}/>
                                <Route path='/settings' exact element={<SettingsPage/>}/>
                                <Route path='*' exact element={<Error/>}/>
                            </Routes>
                        </div>
                        <Navigation/>
                        <Footer/>
                    </div>
                </div>
            </Router>
        </SettingsProvider>
    );
}