import React, { useContext } from 'react';
import SettingsContext from "../SettingsContext";

const SubHeader = () => {

    return (
        <div className="ui menu inverted subheader">
            <div className="right menu">
                <a className="item icon" rel="noopener noreferrer" target="_blank"
                   href="//www.reddit.com/r/Hydroneer/">
                    <i className="reddit icon"/>
                </a>
                <a className="item icon" rel="noopener noreferrer" target="_blank"
                   href="//discord.gg/hydroneer">
                    <i className="discord icon"/>
                </a>
                <a className="item icon" rel="noopener noreferrer" target="_blank"
                   title="@bridgepour"
                   href="//twitter.com/HydroneerGame">
                    <i className="twitter icon"/>
                </a>
                <form className="search-input-min" action="/search">
                    <div className="ui icon fluid input">
                        <input type="text" name="q" placeholder="Search..."/>
                        <i className="search icon"/>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SubHeader;
