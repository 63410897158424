import React, {useEffect, useState} from 'react';
import PageTitle from "../../Helpers/PageTitle";
import {API_URL, CDN_URL} from "../../Utils/Config";
import {useParams} from "react-router-dom";
import Loader from "../../Utils/Loader";

const ConstructionTracker = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const {town_safe_name} = useParams();
    const [stores, setStores] = useState([]);
    const [allBuilt, setallBuilt] = useState(false);
    const [constructionStatus, setConstructionStatus] = useState(() => {
        const storedStatus = localStorage.getItem('constructionStatus');
        return storedStatus ? JSON.parse(storedStatus) : [];
    });

    function initializeConstructionStatusWithRequirements(constructionRequirements) {
        const defaultStatus = constructionRequirements.map(store => ({
            storeID: store.id,
            location: null,
            built: false,
        }));

        if (!localStorage.getItem('constructionStatus')) {
            localStorage.setItem('constructionStatus', JSON.stringify(defaultStatus));
        }
    }

    const checkAllStoresBuilt = () => {
        return constructionStatus.every(store => store.built === true);
    };

    useEffect(() => {
        setallBuilt(checkAllStoresBuilt());
    }, [constructionStatus]); // Rerun when constructionStatus changes

    useEffect(() => {
        const fetchData = async () => {
            const url = API_URL + "/api/towns/all/";
            const response = await fetch(url);
            const data = await response.json();
            setData(data.results.filter(town => town.town_safe_name === town_safe_name)[0]);
            let constructionStores = data.results.filter(town => town.town_safe_name === town_safe_name)[0].shops.filter(shop => shop.constructionRequirements !== null && shop.constructionRequirements !== "")
            setStores(constructionStores);
            initializeConstructionStatusWithRequirements(constructionStores)

            setLoading(false);
        };

        fetchData();
    });

    function updateStoreStatus(storeID, newLocation, isBuilt) {
        const status = JSON.parse(localStorage.getItem('constructionStatus'));
        const updatedStatus = status.map(store => {
            if (store.storeID === storeID) {
                return { ...store, location: newLocation, built: isBuilt };
            }
            return store;
        });

        localStorage.setItem('constructionStatus', JSON.stringify(updatedStatus));
        setConstructionStatus(updatedStatus);
    }

    const handleConstructionClick = (event, storeID) => {
        event.preventDefault(); // Prevent default behavior, if any
        const newBuiltStatus = !getBuiltStatusByStoreId(storeID);
        const newStatus = constructionStatus.map(store => {
            if (store.storeID === storeID) {
                return { ...store, built: newBuiltStatus }; // Assume location doesn't change here
            }
            return store;
        });

        updateStoreStatus(storeID, getStoreLocationByStoreId(storeID), newBuiltStatus);
        setConstructionStatus(newStatus);
    };

    const handleLocationChange = (event, storeID) => {
        event.stopPropagation();
        const newLocation = event.target.value;
        updateStoreStatus(storeID, newLocation, getBuiltStatusByStoreId(storeID));
    };

    const getBuiltStatusByStoreId = (storeId) => {
        const store = constructionStatus.find(s => s.storeID === storeId);
        return store ? store.built : false;
    };

    const getStoreLocationByStoreId = (storeId) => {
        const store = constructionStatus.find(s => s.storeID === storeId);
        return store ? store.location : null;
    };

    const addDefaultSrc = (e) => (
        e.target.src = `${CDN_URL}/images/items/no_image.png`
    )

    return (
        loading || !data ? (
            <Loader/>
        ) : (
            <>
                <PageTitle title={data.name + " Progress Tracker"} imageClass={data.town_safe_name}/>

                <div className="ui container">
                    <div className="ui breadcrumb inverted"><a href="/" className="section">Bridgepour Wiki Home</a>
                        <i className="right angle icon divider"></i>
                        <a href="/guide-book" className="section">Guide Book</a>
                        <i className="right angle icon divider"/>
                        <a href="/guide-book/towns" className="section">Towns</a>
                        <i className="right angle icon divider"/>
                        <a href="/guide-book/towns/new-glade/8" className="section">{data.name}</a>
                        <i className="right angle icon divider"></i>
                        <div className="active section">Progress Tracker</div>
                    </div>

                    {allBuilt && (
                        <>
                            <div className="ui message inverted">
                                Congratulations, you have constructed all stores in {data.name}!
                            </div>
                        </>
                    )}

                    <div className="Construction-list">
                        {stores.map((store, index) => (
                            <div key={index}
                                 className={"ui segment " + getStoreLocationByStoreId(store.id) + (getBuiltStatusByStoreId(store.id) ? ' unlocked' : '')}>
                                    <span className="ui right green corner label status">
                                        <i className="check icon"></i>
                                    </span>
                                <div className="content" id={store.name}>
                                    <h3><a href={`/guide-book/towns/${data.town_safe_name}/${store.safe_name}/${store.id}`}>{store.name}</a></h3>
                                <span onClick={(e) => handleConstructionClick(e, store.id)}
                                          className="ui button brand right floated"><i
                                        className="hammer icon"/> {(getBuiltStatusByStoreId(store.id) ? ' Unbuild' : 'Build')}</span>
                                    {store.constructionRequirements.split('|').map(res => (
                                        <span className="ui image black label medium" key={res.split('*')[0]}>
                                            <img onError={addDefaultSrc}
                                                 src={CDN_URL + "/images/items/" + res.split('*')[0] + ".png"}
                                                 alt={res.split('*')[0]}
                                                 width="16px"
                                                 loading="lazy"
                                            /> {res.split('*')[0].replace("-", " ")} [ {(res.split('*')[1]).toLocaleString(undefined, {maximumFractionDigits: 0})} ]
                                        </span>
                                    ))}
                                    {/*<span className="ui image black label medium">*/}
                                    {/*    <select*/}
                                    {/*        name={`locationSelect-${store.name}`}*/}
                                    {/*        id={`locationSelect-${store.name}`}*/}
                                    {/*        className="ui dropdown"*/}
                                    {/*        value={store.location}*/}
                                    {/*        onChange={(e) => handleLocationChange(e, store.id)}*/}
                                    {/*    >*/}
                                    {/*        <option value="null">Unset</option>*/}
                                    {/*        <option value="construction-one">Construction One</option>*/}
                                    {/*        <option value="construction-two">Construction Two</option>*/}
                                    {/*        <option value="construction-three">Construction Three</option>*/}
                                    {/*        <option value="construction-four">Construction Four</option>*/}
                                    {/*        <option value="construction-five">Construction Five</option>*/}
                                    {/*    </select>*/}
                                    {/*</span>*/}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        )
    );
};

export default ConstructionTracker;
