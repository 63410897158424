import PageTitle from "../Helpers/PageTitle";
import SettingsContext from "../SettingsContext";
import {useContext} from "react";

const SettingsPage = () => {
    const { settings, updateSettings } = useContext(SettingsContext);

    const handleCheckboxChange = (e) => {
        updateSettings({ ...settings, showDLC: e.target.checked });
    };

    return (
        <>
            <PageTitle title="Bridgepour Settings" subheading="Enable or disable features of the wiki."/>

            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">Settings</div>
                </div>


                <div className="ui segment">
                    <div className="settings-table">
                        <div className="setting">
                            <div className="ui checkbox">
                                <input
                                    type="checkbox"
                                    name="ShowDLC"
                                    id="ShowDLC"
                                    checked={settings.showDLC}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor="ShowDLC">Show Journey to Volcalidus content?</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SettingsPage;
