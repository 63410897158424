import React, {Component} from "react";

export default class SectionTitle extends Component {
    render() {
        return (
            <h3 className="ui header">
                {this.props.iconURL && (
                    <img className="ui image" src={this.props.iconURL} alt={this.props.title} />
                )}
                <div className="content">
                    {this.props.title}

                    {this.props.subtitle && (
                        <>
                            <br/>
                            <small className="muted">{this.props.subtitle}</small>

                        </>
                    )}
                </div>
            </h3>
        )
    }
}


