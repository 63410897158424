import React, {useContext} from "react";
import PageTitle from "../Helpers/PageTitle";
import VehicleList from "../Components/VehicleList";
import SettingsContext from "../SettingsContext";
import TabNavigation from "../Layouts/TabNavigation";

const VehiclesView = () => {
    const { settings } = useContext(SettingsContext);

    return (
        <>
            <PageTitle
                title={settings.favouriteIsland.charAt(0).toUpperCase() + settings.favouriteIsland.slice(1) + " Vehicles"}
                subheading={"Getting around " + settings.favouriteIsland.charAt(0).toUpperCase() + settings.favouriteIsland.slice(1) + " can be a breeze. But looking cool whilst doing so is even better."}
                imageClass={settings.favouriteIsland}/>

            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <a href="/guide-book" className="section">Guide Book</a>
                    <i className="right angle icon divider"/>
                    <a href="/guide-book/towns" className="section">{settings.favouriteIsland}</a>
                    <i className="right angle icon divider"/>
                    <span className="section">Vehicles</span>
                </div>

                <TabNavigation/>
                <div className="ui bottom attached tab segment active">
                    <VehicleList store={{id: 0}} showAll={true}/>
                </div>
            </div>
        </>
    )
}


export default VehiclesView;
