import React from 'react';
import PostSummary from "./PostSummary";
import Loader from "../../Utils/Loader";

import "./Blog.css";
import {API_URL} from "../../Utils/Config";

export default class BlogFeed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            limit: props.limit || 99
        }
    }

    async componentDidMount() {
        const url = API_URL + `/api/blogposts/`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, loading: false})
    }

    render() {
        return (
            <div className={`Blog-feed ${this.state.limit !== 99 ? "big-first" : ""}`}>
                {this.state.loading || !this.state.data ?
                    (
                        <Loader />
                    ) : (
                        <>
                            {this.state.data.slice(0, this.state.limit).map(data => (
                                <PostSummary post={data} key={data.title} />
                            ))}
                        </>
                    )
                }
            </div>
        )
    }
}