import React from 'react';

export default function HydroLogicc() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted">
                <a href="/" className="section">Bridgepour Wiki Home</a>
                <i className="right angle icon divider"/>
                <a href="/guide-book" className="section">Guide Book</a>
                <i className="right angle icon divider"/>
                <a href="/guide-book/guide/hydro-logic" className="section">HydroLogic</a>
                <i className="right angle icon divider"/>
                <div className="active section">II. Logic elements</div>
            </div>

            <div className="Page-header">
                <h1>
                    II. Logic elements
                </h1>
            </div>

            <div className="ui segment">
                <p>In Hydroneer, you can find many different logic elements. There are 21 (for now, in 1.6).</p>
                <p>Don't worry, they are all very easy to understand.</p>
                <p>Let's break it down in three categories : the basic cables, the gates and... everything else.</p>
            </div>

            <div className="ui segment">
                <h3>a. Logic cables</h3>
                <p>May I introduce the <span className="orange">straight</span> logic cable:</p>
                <p>You already know this one, we talked about it just before.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />

                <p>It means that you also know it's elbow variation:</p>
                <p>It acts exactly like a straight cable, only the shape is different.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />

                <p>You can also find the 'T' shaped variation:</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />

                <p>WAIT!</p>
                <p>As you can see, this one has 3 ends.</p>
                <p>So what happens when a signal arrives inside?</p>
                <p>Which way will it go?</p>
                <p>Well, it will go on the two other ends, like that:</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />

                <p>Just like the <span className="orange">straight</span> (and elbow) cable, it doesn't matter where the
                    signal enters.</p>
                <p>It will always travel to the two other ends and give them it's value.</p>

                <p>Still here ? Good. Let's start the interesting stuff.</p>
                <p>Meet the <span className="orange">delay</span> cable:</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />

                <p>According to the name, you probably assumed that it will delay a signal.</p>
                <p>If so, you're right. Else... well... hum</p>
                <p>The <span className="orange">delay</span> cable will act like a <span
                    className="orange">straight</span> cable, but instead of letting the signal travel instantaneously,
                    it will wait 1s before updating the value of the output end.</p>
                <p>Time ("time", get it?) for a new schematic:</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />

                <p>We are sending the signal. It will stay trapped inside the cable during 1 second:</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />

                <p>Once that time is over, the signal will reach the output (the 'B' end here):</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />

                <p>An easy one now, the <span className="orange">reader</span> cable:</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />

                <p>That cable will simply read the value that is memorized inside it's ends ('A' or 'B', it doesn't
                    matter as they have the same value).</p>

                <p>More serious, the <span className="orange">flip</span> cable:</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>That cable will change the value of the signal that travels inside.</p>
                <p>More schematics will do a wonderful explanation:</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>We stepped up a bit and used a <span className="orange">keypad</span> this time, to send a '0' value
                    to the cable.</p>
                <p>As you can see, the value changed to a '1' after going through the cable.</p>
                <p>A '0' will always change to a '1' inside a <span className="orange">flip</span> cable.</p>
                <p>Now let's send an different value:</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>We sent a '42' value to the <span className="orange">flip</span> cable. And the value changed to '0'.
                </p>
                <p>And it will act the same for any other value, from 1 to 999999999.</p>
                <p>A NON-'0' will always change to a '0' inside a flip cable.</p>
                <p>Finally, the very convenient <span className="orange">diode</span> cable:</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>If you are familiar with electronics, you already know how that one works.</p>
                <p>The signal will travel inside it just like it was a basic <span
                    className="orange">straight</span> cable, but only from 'A' to 'B'. 'A' is the input, and 'B' the
                    output. Period.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" /><br/>
                <img src="http://placehold.it/450x100" alt="placeholder" />
            </div>

            <a className="ui brand button" href="/guide-book/guide/hydro-logic/logic-behaviour/"><i className="left arrow icon"/> Back</a>
            <a className="ui brand button right floated" href="/guide-book/guide/hydro-logic/logic-elements-part-two/">Logic elements (part 2)<i className="right arrow icon" /></a>
            <br/>
        </div>
    )
}