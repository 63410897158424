import React from 'react';

export default function HydroLogicb() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted">
                <a href="/" className="section">Bridgepour Wiki Home</a>
                <i className="right angle icon divider"/>
                <a href="/guide-book" className="section">Guide Book</a>
                <i className="right angle icon divider"/>
                <a href="/guide-book/guide/hydro-logic" className="section">HydroLogic</a>
                <i className="right angle icon divider"/>
                <div className="active section">I. Logic behaviour</div>
            </div>

            <div className="Page-header">
                <h1>
                    I. Logic behavior
                </h1>
            </div>

            <div className="ui segment">
                <p>Okay, here we go.</p>
                <p>Let's unravel the mystery right now: in Hydroneer, wires have memory!</p>
                <p>Well... that's not exactly true but you'll get the idea.</p>
                <br/>
                <p>Let's take a <span className="orange">straight</span> logic cable, the most basic logic thing:</p>
                <img src="http://placehold.it/450x100" alt="placeholder"/>
                <br/>
                <p>To make things easier, we will represent it in a more schematic way:</p>
                <img src="http://placehold.it/450x100" alt="placeholder"/>
                <br/>
                <p>As you can see, the cable has two ends: A and B.</p>
                <p>You can connect many things to these ends. Any logic thing in fact.</p>
                <br/>
                <p>Remember what i just told you? That wires have memory?</p>
                <p>To be precise, the ends (A and B) have memory.</p>
                <br/>
                <p>It means that when a signal goes through an end, the end will remember the value of the signal.</p>
                <p>Forever.</p>
                <p>Or at least until an other signal is sent through the end and changes it's value to something
                    new.</p>
                <br/>
                <p>We have a <span className="orange">button</span> sending a signal with the value '1'.</p>
                <p>The signal will reach the A end, giving it a '1' value.</p>
                <img src="http://placehold.it/450x100" alt="placeholder"/>
                <br/>
                <p>The signal will then travel along the wire, and reach the B end (always carrying the '1' value).</p>
                <p>So the B end will "memorize" the '1' value.</p>
                <img src="http://placehold.it/450x100" alt="placeholder"/>
                <br/>
                <p>Finally, the signal will pop out of the cable, still with that '1' value, producing some green
                    lightning (blue on this schematic, I'm doing with what I have).</p>
                <img src="http://placehold.it/450x100" alt="placeholder"/>
                <br/>
                <p>Now the system will be inert again, but the values will still be trapped in the A and B ends.</p>
                <br/>
                <p>And if you take out the cable (with the pickup button) and place it down again, the values will still
                    be here!!</p>
                <p>Like I said, in memory forever (until an other signal comes and replaces the values with it's
                    own).</p>
                <br/>
                <p>Note that the exact same thing will happen if the signal arrives at the 'B' end. It will travel to
                    the 'A' end and give it it's value.</p>
                <br/>
                <p>There you go, you know everything now!</p>
                <p>Everything that will follow on this guide rests upon that concept: wire's ends memory.</p>
            </div>

            <a className="ui brand button" href="/guide-book/guide/hydro-logic/"><i className="left arrow icon"/> Back</a>
            <a className="ui brand button right floated" href="/guide-book/guide/hydro-logic/logic-elements/">Logic elements <i className="right arrow icon" /></a>
            <br/>
        </div>
    )
}