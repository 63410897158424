import React from "react";
import parse from "html-react-parser";
import {CDN_URL} from "../Utils/Config";

const PageTitle = ({title, subheading, imageClass, imageURL, itemBadges, itemIcon=""}) => {
    const backgroundImageStyle = imageURL ? {backgroundImage: `url(${imageURL})`} : {};

    return (
        <>
            <div className={`Page-header ${imageClass ? imageClass : ''}`} style={backgroundImageStyle}>
                {itemIcon && (
                    <img className="Page-header-icon" alt={title} src={itemIcon} />
                )}

                <div className="ui container">
                    {itemBadges ? (
                        <h1 className="ui two column grid stackable">
                            <div className="eight wide column">
                                {title}

                                {subheading && (
                                    <div className="sub-heading">{parse(subheading)}</div>
                                )}
                            </div>
                            <div className="eight wide column">
                                <div className="badges">
                                    {itemBadges.replace("(", "").replace(")", "").split(",").map((badge, index) => (
                                        <div className="badge" title={badge.trim()} key={index}>
                                            <img src={`${CDN_URL}/images/badges/${badge.trim()}.png`} alt={badge.trim()} loading="lazy"/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </h1>
                    ) : (
                        <h1>
                            {title}

                            {subheading && (
                                <div className="sub-heading">{parse(subheading)}</div>
                            )}
                        </h1>
                    )}
                </div>
            </div>
        </>
    );
};

export default PageTitle;
