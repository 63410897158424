import React, {useContext, useEffect, useState} from 'react';
import Loader from "../../Utils/Loader";
import {useParams} from "react-router-dom";
import parse from 'html-react-parser';
import {API_URL} from "../../Utils/Config";
import PageTitle from "../../Helpers/PageTitle";
import SettingsContext from "../../SettingsContext";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}



const BlogPost = () => {
    const {settings} = useContext(SettingsContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const {post_id} = useParams();


    useEffect(() => {
        const fetchBlogPost = async () => {
            const url = API_URL + `/api/blogposts/${post_id}/`;
            try {
                const response = await fetch(url);
                const jsonData = await response.json();
                setData(jsonData.results[0]);
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch blog post:", error);
                setLoading(false);
            }
        };
        fetchBlogPost();
    }, [post_id]);

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <PageTitle title={data.title} subheading={data.html_summary} imageURL={`url(/assets/images/blog/${data.thumbnail}`}/>

            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <a href="/blog/" className="section">Blog</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">{data.title}</div>
                </div>

                <div className="ui segment blog-post">
                    <>{parse(data.html_body)}</>
                    <div className="ib">
                        {data.author}
                    </div>
                </div>
            </div>
        </>
    )
}

export default withParams(BlogPost);