import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {API_URL, CDN_URL} from "../../Utils/Config";
import Loader from "../../Utils/Loader";
import PageTitle from "../../Helpers/PageTitle";
import ItemTooltip from "../../Components/Items/ItemTooltip";
import ItemCardRedo from "../../Components/Items/ItemCardRedo";
import VehicleList from "../../Components/VehicleList";
import ItemTableRow from "../../Components/Items/ItemTableRow";
import {PoleRequirementTable} from "../../Components/Items/ItemDetails";

const GuidebookShopView = () => {
    const { shop_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [constructionStatus, setConstructionStatus] = useState(() => {
        const localData = localStorage.getItem('constructionStatus');
        return localData ? JSON.parse(localData) : {};
    });

    useEffect(() => {
        const fetchData = async () => {
            const url = `${API_URL}/api/shop/${shop_id}/`;
            try {
                const response = await fetch(url);
                const jsonData = await response.json();
                setData(jsonData.results[0]);
            } catch (error) {
                console.error("Failed to fetch shop data:", error);
            }
            setLoading(false);
        };

        fetchData();
    }, [shop_id]);

    const getBuiltStatusByStoreId = (storeId) => {
        const store = constructionStatus.find(s => s.storeID === storeId);
        return store ? store.built : false;
    };

    const getStoreLocationByStoreId = (storeId) => {
        const store = constructionStatus.find(s => s.storeID === storeId);
        return store ? store.location : null;
    };

    const updateConstructionStatus = (shopId, newLocation, isBuilt) => {
        const existingStatus = JSON.parse(localStorage.getItem('constructionStatus')) || [];

        const shopIndex = existingStatus.findIndex(shop => shop.storeID === shopId);

        if (shopIndex !== -1) {
            existingStatus[shopIndex] = { ...existingStatus[shopIndex], location: newLocation, built: isBuilt };
        } else {
            existingStatus.push({ storeID: shopId, location: newLocation, built: isBuilt });
        }

        // Save the updated array back to local storage
        localStorage.setItem('constructionStatus', JSON.stringify(existingStatus));
    };

    const toggleBuiltStatus = (shopId) => {
        updateConstructionStatus(shopId, getStoreLocationByStoreId(shopId), !getBuiltStatusByStoreId(shopId))
    };

    const changeLocation = (shopId, newLocation) => {
        updateConstructionStatus(shopId, newLocation, getBuiltStatusByStoreId(shopId))
    };

    if (loading || !data) {
        return <Loader />;
    }

    const addDefaultSrc = (e) => (
        e.target.src = `${CDN_URL}/images/items/no_image.png`
    )

    const storeType = ["store", "carts", "logic-power"].includes(data.category) ? 'sells' : ["stocks", "jeweler", "grocer"].includes(data.category) ? 'buys' : '';
    const shouldDisplayCategory = ["store", "stocks", "jeweler", "carts", "logic-power", "grocer"].includes(data.category);


    return (
        <>
            <PageTitle title={data.name} subheading={data.description} imageClass={data.town.town_safe_name} />
            <div className="ui container">
                <Breadcrumbs data={data}/>

                <div className="ui bottom attached tab segment active">
                    {/*{(data.constructionRequirements !== null && data.constructionRequirements !== "") && (*/}
                    {/*    <>*/}
                    {/*        <div className="Construction-list">*/}
                    {/*            <div*/}
                    {/*                className={"ui segment " + getStoreLocationByStoreId(data.id) + (getBuiltStatusByStoreId(data.id) ? ' unlocked' : '')}>*/}

                    {/*                <h3>*/}
                    {/*                    {data.name} Requirements*/}
                    {/*                </h3>*/}
                    {/*                <a href={`/guide-book/towns/${data.town.town_safe_name}/progress`} className="ui button brand right floated"><i className="hammer icon" /> Track Progress</a>*/}
                    {/*                <span className="ui right green corner label status">*/}
                    {/*                    <i className="check icon"></i>*/}
                    {/*                </span>*/}

                    {/*                {data.constructionRequirements.split('|').map(res => (*/}
                    {/*                    <span className="ui image black label medium" key={res.split('*')[0]}>*/}
                    {/*                        <img onError={addDefaultSrc}*/}
                    {/*                             src={CDN_URL + "/images/items/" + res.split('*')[0] + ".png"}*/}
                    {/*                             alt={res.split('*')[0]}*/}
                    {/*                             width="16px"*/}
                    {/*                             loading="lazy"*/}
                    {/*                        /> {res.split('*')[0].replace("-", " ")} [ {(res.split('*')[1]).toLocaleString(undefined, {maximumFractionDigits: 0})} ]*/}
                    {/*                    </span>*/}
                    {/*                ))}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <br/>*/}
                    {/*    </>*/}
                    {/*)}*/}


                    {shouldDisplayCategory && (
                        <>
                            {data.name === "Pole Trader" ? (
                                <>
                                    <h3>How to unlock the tiered Poles</h3>
                                    Fishing Poles can be unlocked by handing in a specific catch to the Pole
                                    Trader in Burnscroft:
                                    <PoleRequirementTable/>
                                </>
                            ) : (
                                <>
                                    <p>
                                        This store <span>{storeType}</span> the following items for <ItemTooltip item_lookup={data.currency}/>:
                                    </p>

                                    <table className="ui table basic inverted striped unstackable Item-table">
                                        <thead>
                                        <tr>
                                            <th>Item</th>
                                            <th>Badges</th>
                                            <th>Value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data.inventory.sort((a, b) => a.name.localeCompare(b.name)).map(item => (
                                            <ItemTableRow item={item} key={item.name} currency={data.currency}/>
                                        ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        </>
                    )}

                    {data.category === "legacy" && <p>Whilst this store is no longer in the game, its' memory lives on.</p>}

                    {data.category === "vehicles" && <VehicleList store={data}/> }

                    {data.category === "scrapyard" &&
                        <>
                            <p>The Scrapyard buys all construction items in return for <ItemTooltip
                                item_lookup="scrap-metal"/> worth for 50% of the original purchase
                                price.
                            </p>
                            <p>Scrap Metal can also be obtained by blowing construction items up using
                                [logic explosives].</p>
                            <p>This can be increased further by smelting Scrap Metal in a <ItemTooltip
                                item_lookup="small-furnace"/>, <ItemTooltip item_lookup="smelter"/> or
                                a <ItemTooltip item_lookup="logic-smelter"/>. This yields a <ItemTooltip
                                    item_lookup="scrap-bar"/> which is worth
                                20% more than the Scrap Metal, bringing the total return to 60% of the
                                original construction items' value.</p>

                            <div className="Item-grid">
                                <ItemCardRedo item_lookup="scrap-metal"/>
                                <ItemCardRedo item_lookup="scrap-bar"/>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    );
};

const Breadcrumbs = ({data}) => (
    <div className="ui breadcrumb inverted">
        <a href="/" className="section">Bridgepour Wiki Home</a>
        <i className="right angle icon divider"/>
        <a href="/guide-book" className="section">Guide Book</a>
        <i className="right angle icon divider"/>
        <a href="/guide-book/towns" className="section">{data.gameVersion} Towns</a>
        <i className="right angle icon divider"/>
        <div className="section">{data.town.name}</div>
        <i className="right angle icon divider"/>
        <div className="active section">{data.name}</div>
    </div>
);

export default GuidebookShopView;
