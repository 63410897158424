import React from 'react';

export default function FishingGuide() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted"><a href="/" className="section">Bridgepour Wiki Home</a><i
                className="right angle icon divider"></i><a href="/guide-book" className="section">Guide Book</a><i
                className="right angle icon divider"></i>
                <div className="active section">Fishing</div>
            </div>
            <div className="Page-header">
                <h1>How to get started with Fishing in Hydroneer
                    <div className="sub-heading">Set out to catch some awesome sea creatures in this fun side
                        activity!
                    </div>
                </h1>
            </div>
            <div className="ui segment">
                <h2>Fishing, in a mining game!?</h2><p>Fishing was always a part of
                Hydroneer, it was once a simple mechanic. Cast your fishing pole out, wait a few seconds, catch
                a boring fish. Rinse and repeat.</p><p>Now though, fishing has been expanded in the 1.7 update!
                Players now have the ability to climb the ranks of the fishing order and acquire new and unique
                fishing poles along the way, by showing off their skill of catching various sea creatures!</p>
                <p>Sound fun to you? Read on!</p>
            </div>
            <div className="ui segment"><h3>How do I fish using a Fishing Pole?</h3><p>Pick up the Fishing Pole
                using <kbd>E</kbd>. Stand next to any body of water, look out at the water and <kbd>Left click</kbd> to
                cast out your line. Once the bobber has bobbed three times <kbd>Left click</kbd> again to pull your
                catch in.</p></div>
            <div className="ui segment"><h3>How do I fish using the Fishing Pot?</h3><p>Pick up the Fishing Pot
                using <kbd>E</kbd>. Stand next to any body of water, enter place mode for the Fishing Pot using <kbd>Left
                    click</kbd>, place the Fishing Pot under the water. After a minute or two, you'll see a Common Fish
                inside! <kbd>Left click</kbd> the fish and you'll pick it up.</p></div>
            <div className="ui segment"><h3>How do I get a better Fishing Pole?</h3><p>When you first start out fishing,
                you'll need the Basic Fishing Pole, purchasable from the Tools &amp; Equipment store near Ember Cradle,
                the Repair (and Fishing) Store in Burnscroft, or in the Housing store of Bridgepour - this will cost you
                52c.</p><p>To upgrade your pole, head to the Pole Trader, by the rivers edge, just next to Burnscroft.
                You'll see your next available pole in the shiny display case. To unlock the case, you need to deposit
                the sea creature that matches the board on the left of the display case.</p><p>There are 8 poles to
                unlock, each with an increased chance to catch a rarer fish!</p><p>Check out all the fish and poles
                available on the next pages.</p></div>
            <a className="ui brand button right floated" href="/guide-book/guide/fishing-catches">What the Fish!?? <i
                className="right arrow icon"></i></a><p>Next up, learn what fish can be caught in Hydroneer!</p><br/>
        </div>
    )
}