import L from 'leaflet';

import brown_pin from "./brown.png";
import yellow_pin from "./yellow-b.png";
import red_pin from "./red.png";
import digsite_pin from "./digsite.png";
import stock_market_pin from "./stock-market.png";
import jeweler_pin from "./jeweler.png";
import grocer_pin from "./grocer.png";
import store_pin from "./store.png";
import npc_pin from "./npc.png";
import forgemark from "./forgemark.png";
import dinobone from "./bone.png";

const brownIcon = new L.Icon({
    iconUrl: brown_pin,
    shadowUrl: brown_pin,
    iconSize:     [16, 24], // size of the icon
    shadowSize:   [16, 24], // size of the shadow
    iconAnchor:   [8, 12], // point of the icon which will correspond to marker's location
    shadowAnchor: [8, 12],  // the same for the shadow
    popupAnchor:  [0, -10] // point from which the popup should open relative to the iconAnchor
})

const yellowIcon = new L.Icon({
    iconUrl: yellow_pin,
    shadowUrl: yellow_pin,
    iconSize:     [16, 24], // size of the icon
    shadowSize:   [16, 24], // size of the shadow
    iconAnchor:   [8, 12], // point of the icon which will correspond to marker's location
    shadowAnchor: [8, 12],  // the same for the shadow
    popupAnchor:  [0, -10] // point from which the popup should open relative to the iconAnchor
})

const redIcon = new L.Icon({
    iconUrl: red_pin,
    shadowUrl: red_pin,
    iconSize:     [16, 24], // size of the icon
    shadowSize:   [16, 24], // size of the shadow
    iconAnchor:   [8, 12], // point of the icon which will correspond to marker's location
    shadowAnchor: [8, 12],  // the same for the shadow
    popupAnchor:  [0, -10] // point from which the popup should open relative to the iconAnchor
})

const digsiteIcon = new L.Icon({
    iconUrl: digsite_pin,
    shadowUrl: digsite_pin,
    iconSize:     [16, 24], // size of the icon
    shadowSize:   [16, 24], // size of the shadow
    iconAnchor:   [8, 12], // point of the icon which will correspond to marker's location
    shadowAnchor: [8, 12],  // the same for the shadow
    popupAnchor:  [0, -10] // point from which the popup should open relative to the iconAnchor
})

const stockMarketIcon = new L.Icon({
    iconUrl: stock_market_pin,
    shadowUrl: stock_market_pin,
    iconSize:     [16, 24], // size of the icon
    shadowSize:   [16, 24], // size of the shadow
    iconAnchor:   [8, 12], // point of the icon which will correspond to marker's location
    shadowAnchor: [8, 12],  // the same for the shadow
    popupAnchor:  [0, -10] // point from which the popup should open relative to the iconAnchor
})

const jewelerIcon = new L.Icon({
    iconUrl: jeweler_pin,
    shadowUrl: jeweler_pin,
    iconSize:     [16, 24], // size of the icon
    shadowSize:   [16, 24], // size of the shadow
    iconAnchor:   [8, 12], // point of the icon which will correspond to marker's location
    shadowAnchor: [8, 12],  // the same for the shadow
    popupAnchor:  [0, -10] // point from which the popup should open relative to the iconAnchor
})

const grocerIcon = new L.Icon({
    iconUrl: grocer_pin,
    shadowUrl: grocer_pin,
    iconSize:     [16, 24], // size of the icon
    shadowSize:   [16, 24], // size of the shadow
    iconAnchor:   [8, 12], // point of the icon which will correspond to marker's location
    shadowAnchor: [8, 12],  // the same for the shadow
    popupAnchor:  [0, -10] // point from which the popup should open relative to the iconAnchor
})

const storeIcon = new L.Icon({
    iconUrl: store_pin,
    shadowUrl: store_pin,
    iconSize:     [16, 24], // size of the icon
    shadowSize:   [16, 24], // size of the shadow
    iconAnchor:   [8, 12], // point of the icon which will correspond to marker's location
    shadowAnchor: [8, 12],  // the same for the shadow
    popupAnchor:  [0, -10] // point from which the popup should open relative to the iconAnchor
})

const npcIcon = new L.Icon({
    iconUrl: npc_pin,
    shadowUrl: npc_pin,
    iconSize:     [16, 24], // size of the icon
    shadowSize:   [16, 24], // size of the shadow
    iconAnchor:   [8, 12], // point of the icon which will correspond to marker's location
    shadowAnchor: [8, 12],  // the same for the shadow
    popupAnchor:  [0, -10] // point from which the popup should open relative to the iconAnchor
})

const boneIcon = new L.Icon({
    iconUrl: dinobone,
    shadowUrl: dinobone,
    iconSize:     [16, 24], // size of the icon
    shadowSize:   [16, 24], // size of the shadow
    iconAnchor:   [8, 12], // point of the icon which will correspond to marker's location
    shadowAnchor: [8, 12],  // the same for the shadow
    popupAnchor:  [0, -10] // point from which the popup should open relative to the iconAnchor
})

const forgeIcon = new L.Icon({
    iconUrl: forgemark,
    shadowUrl: forgemark,
    iconSize:     [16, 24], // size of the icon
    shadowSize:   [16, 24], // size of the shadow
    iconAnchor:   [8, 12], // point of the icon which will correspond to marker's location
    shadowAnchor: [8, 12],  // the same for the shadow
    popupAnchor:  [0, -10] // point from which the popup should open relative to the iconAnchor
})

export {brownIcon, yellowIcon, redIcon, digsiteIcon, stockMarketIcon, jewelerIcon, storeIcon, grocerIcon, npcIcon, boneIcon, forgeIcon};