import React from 'react';
import {API_URL} from "../../Utils/Config";
import ItemCardRedo from "./ItemCardRedo";
import Loader from "../../Utils/Loader";

export default class RandomItem extends React.Component {
    state = {
        loading: true,
        data: null
    }

    async componentDidMount() {
        const url = API_URL + `/api/items/random/`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results[0], loading: false})
    }
    render() {
        return (
            <>
                {
                    this.state.loading || !this.state.data ?
                        (
                            <Loader/>
                        ) : (
                            <ItemCardRedo item={this.state.data}/>
                        )
                }
            </>
        )
    }
}