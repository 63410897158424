import React from 'react';
import {API_URL, CDN_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import {Link} from "react-router-dom";
import {safeName} from "../Utils/Utils";
import PageTitle from "../Helpers/PageTitle";

export default class ShoppingList extends React.Component {
    state = {
        loading: true,
        data: null,
        filtered_items: null,
        list_name: "Testing",
        shopping_list_items: [],
        unique_code: ""
    }

    async componentWillMount() {
        if (localStorage.getItem("shopping_list") === "") {
            localStorage.setItem('shopping_list', "");
        }

        if (localStorage.getItem("shopping_list")) {
            let storage_items = localStorage.getItem('shopping_list').split(',');
            let y = []
            let z = []

            for (let x in storage_items) {
                y = y.concat(Number(storage_items[x].split("|")[0]));
                z = z.concat(Number(storage_items[x].split("|")[1]));
            }

            let item_list = [];
            for (let item in y) {
                const url = API_URL + "/api/items/exact/" + y[item];
                const response = await fetch(url);
                let data = await response.json();
                data = {...data.results[0], quantity: z[item]};
                item_list = [...item_list.concat(data)];
            }
            this.setState({shopping_list_items: item_list})
        } else {
            this.setState({shopping_list_items: []})
            localStorage.setItem('shopping_list', "");
        }

        this.update_code();
    }

    async componentDidMount() {
        const url = API_URL + "/api/items/all/";
        const response = await fetch(url);
        const data = await response.json();
        let more_filtered_items = data.results.filter(x => x.value > 0)
        this.setState({data: more_filtered_items, filtered_items: more_filtered_items, loading: false})
    }

    new_filter = (event) => {
        this.setState({loading: true})

        const filteredItemsR = [];

        const filteredItems = this.state.data.filter(item => item.name.toLowerCase().includes(event.target.value.toLowerCase()));
        filteredItems.forEach(item => {
            filteredItemsR.push(item)
        })

        this.setState({loading: false, noResults: false, filtered_items: this.state.data});

        if (event.target.value !== "") {
            let newItems = filteredItemsR
            if (newItems.length === 0) {
                this.setState({noResults: true});
            }
            this.setState({loading: false, filtered_items: newItems});
        }
    }

    addDefaultSrc = (e) => {
        e.target.src = `${CDN_URL}/images/items/no_image.png`
    }

    async update_shopping_list(e, action) {
        let new_list = []

        if (action === "add") {
            const url = API_URL + "/api/items/exact/" + e;
            const response = await fetch(url);
            const data = await response.json();
            new_list = [...this.state.shopping_list_items.concat(data.results)]
        } else {
            new_list = this.state.shopping_list_items.filter(i => i.id.toString() !== e)
        }

        this.setState({shopping_list_items: new_list}, function () {
            this.update_code()
        });
    }

    doShoppingList = (e) => {
        const this_item = e.target.name;
        let unique_code = this.state.unique_code

        if (unique_code.includes(this_item + "|1")) {
            this.update_shopping_list(e.target.name, "remove");
        } else {
            this.update_shopping_list(e.target.name, "add");
        }
    }

    update_code = () => {
        let code_to_encode = [];

        for (let item in this.state.shopping_list_items) {
            code_to_encode = [...code_to_encode.concat(this.state.shopping_list_items[item].id + "|" + (this.state.shopping_list_items[item].quantity || 1))];
        }

        localStorage.setItem('shopping_list', code_to_encode);
        this.setState({unique_code: code_to_encode})
    }

    clear_list = () => {
        localStorage.setItem('shopping_list', "");

        this.setState({
            shopping_list_items: [],
            unique_code: "",
            filtered_items: this.state.data
        })
    }

    render() {
        return (
            <>
                <PageTitle title="Build a shopping list!"/>

                <div className="ui container fluid">
                    <div className="ui breadcrumb inverted">
                        <a href="/" className="section">Bridgepour Wiki Home</a>
                        <i className="right angle icon divider"/>
                        <a href="/database/" className="section">Item Database</a>
                        <i className="right angle icon divider"/>
                        <div className="active section">Shopping List</div>
                    </div>

                    <div className="ui message inverted">Hey! The <strong>Shopping List</strong> feature is a work in
                        progress, please report any bugs / issues / feedback in the Discord and tag @ItchyBeard,
                        thank-you!
                    </div>

                    <div className="ui two column stackable grid">
                        <div className="column">
                            <div className="ui segment">
                                <h2>Shopping Catalogue</h2>
                                <form className="ui form" onSubmit={e => e.preventDefault()}>
                                    <div className="field">
                                        <label><i className="search icon"/> Find an item</label>
                                        <input name="filter" type="text" onChange={this.new_filter}/>
                                    </div>
                                </form>

                                <table className="ui table basic inverted striped">
                                    <thead>
                                    <tr>
                                        <th width="64px"><i
                                            className="shopping basket icon"/> ({this.state.shopping_list_items.length})
                                        </th>
                                        <th width="32px"><i className="thumbnail icon"/></th>
                                        <th>Name</th>
                                        <th width="10%">Price</th>
                                        <th className="hide-mobile" width="60%">Description</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <>
                                        {this.state.loading || !this.state.filtered_items ?
                                            (
                                                <tr>
                                                    <td colSpan="5"><Loader/></td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {this.state.filtered_items.map(item => (
                                                        <>
                                                            <tr key={item.id}>
                                                                <td>
                                                                    {item.value && (
                                                                        <>
                                                                            {(this.state.shopping_list_items.length > 0 && this.state.shopping_list_items.filter(i => i.id === item.id).length > 0) ? (
                                                                                <input type="checkbox" name={item.id}
                                                                                       onChange={this.doShoppingList}
                                                                                       checked/>
                                                                            ) : (
                                                                                <input type="checkbox" name={item.id}
                                                                                       onChange={this.doShoppingList}/>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <Link to={"/database/" + safeName(item.name)}
                                                                          item={safeName(item.name)}>
                                                                        <img onError={this.addDefaultSrc}
                                                                             src={CDN_URL + "/images/items/" + safeName(item.name) + ".png"}
                                                                             alt={item.name}
                                                                             loading="lazy"
                                                                        />
                                                                    </Link>
                                                                </td>
                                                                <td><a
                                                                    href={"/database/" + safeName(item.name)}>{item.name}</a>
                                                                </td>
                                                                <td className="Item-value">
                                                                    {item.value &&
                                                                        <>
                                                                            {(item.value).toLocaleString(undefined, {maximumFractionDigits: 0})}
                                                                        </>
                                                                    }

                                                                    {!item.value &&
                                                                        <span>-</span>
                                                                    }
                                                                </td>
                                                                <td className="hide-mobile">
                                                                    {item.description &&
                                                                        item.description
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))
                                                    }
                                                </>
                                            )
                                        }
                                    </>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui segment scrollWith">
                                <h2>
                                    Shopping List
                                    {this.state.shopping_list_items.length > 0 && (
                                        <span className="ui button mini brand right floated"
                                              onClick={this.clear_list}><i
                                            className="trash icon"/> Clear</span>
                                    )}
                                </h2>

                                <table className="ui table basic inverted striped unstackable">
                                    <thead>
                                    <tr>
                                        <th width="96px"><i className="shopping basket icon"/> Qty</th>
                                        <th width="32px"><i className="thumbnail icon"/></th>
                                        <th>Name</th>
                                        <th width="10%">Price</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.shopping_list_items.length === 0 ? (
                                        <tr>
                                            <td colSpan="5">Add items to your list to get started!</td>
                                        </tr>
                                    ) : (
                                        <>
                                            {this.state.shopping_list_items.map(item => (
                                                <tr>
                                                    <td>
                                                        <form className="ui form">
                                                            <div className="field">
                                                                <input type="number" name={item.name}
                                                                       defaultValue={item.quantity || 1} disabled
                                                                       min="1"
                                                                       max="999"/>
                                                            </div>
                                                        </form>
                                                    </td>
                                                    <td>
                                                        <Link to={"/database/" + safeName(item.name)}
                                                              item={safeName(item.name)}>
                                                            <img onError={this.addDefaultSrc}
                                                                 src={CDN_URL + "/images/items/" + safeName(item.name) + ".png"}
                                                                 alt={item.name}
                                                                 loading="lazy"
                                                            />
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to={"/database/" + safeName(item.name)}
                                                              item={safeName(item.name)}>
                                                            {item.name}
                                                        </Link>
                                                    </td>
                                                    <td>{(item.value).toLocaleString(undefined, {maximumFractionDigits: 0})}</td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                    </tbody>
                                </table>

                                {this.state.shopping_list_items.length > 0 && (
                                    <a href={"/list/" + btoa(this.state.unique_code)}><i className="list icon"/> View
                                        list to
                                        amend quantities</a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}