import React from 'react';
import BlogFeed from "../Components/Blog/BlogFeed";
import RandomItem from "../Components/Items/RandomItem";
import PageTitle from "../Helpers/PageTitle";
import Spotlight from "../Components/Spotlight";
import GoogleAd from "../Components/GoogleAd";

export default class Home extends React.Component {
    state = {
        settings: {}
    }

    async componentDidMount() {
        let defaultSettings = {
            showDLC: false
        };

        if (localStorage.getItem("settings")) {
            let storedSettings = JSON.parse(localStorage.getItem("settings"));
            this.setState({settings: storedSettings});
        } else {
            this.setState({settings: defaultSettings});
            localStorage.setItem('settings', JSON.stringify(defaultSettings));
        }
    }

    render() {

        return (
            <>
                <PageTitle title="Welcome to Bridgepour, The Official Hydroneer Wiki"
                           subheading={`Bridgepour Wiki is The Official Hydroneer Wiki. Bridgepour Wiki aims to take the role of a tour guide to help players, both new and old, make their time within the world of Hydroneer less of a rush and more of an ease. Bridgepour Wiki (the Hydroneer Wiki) will bring together a tonne of how-to guides, item database, and various calculators.<br/><br/> <small>Hydroneer is a game developed by <a href="http://foulballhangover.com" rel="noopener noreferrer" target="_blank">Foulball Hangover</a></small>`}/>

                <div className="ui container">
                    <div className="ui stackable three column center aligned grid">
                        <div className="column">
                            <div className="ui segment">
                                <h2 className="ui center aligned icon header">
                                    <a href="/database" rel="noopener noreferrer"><i
                                        className="circular database icon"/></a>
                                    Items
                                </h2>
                                <p>Take a look at the items in our Hydroneer wiki item database.</p>
                            </div>
                            <a href="/database" rel="noopener noreferrer" className="ui button brand block fluid">View the database</a>
                        </div>
                        <div className="column">
                            <div className="ui segment">
                                <h2 className="ui center aligned icon header">
                                    <a href="/guide-book/towns" rel="noopener noreferrer"><i
                                        className="circular map icon"/></a>
                                    Towns
                                </h2>
                                <p>Check out the towns in the main areas within the world of Hydroneer.</p>
                            </div>
                            <a href="/guide-book/towns" rel="noopener noreferrer"
                               className="ui button brand block fluid">Visit Locations</a>
                        </div>
                        <div className="column">
                            <div className="ui segment">
                                <h2 className="ui center aligned icon header">
                                    <a href="/guide-book" rel="noopener noreferrer"><i className="circular book icon"/></a>
                                    Guides
                                </h2>
                                <p>Learn how to play Hydroneer with our handy user created guides.</p>
                            </div>
                            <a href="/guide-book" rel="noopener noreferrer" className="ui button brand block fluid">Learn
                                Hydroneer</a>
                        </div>
                    </div>

                    {/*{!this.state.settings.showDLC && (*/}
                    {/*    <div className="ui message inverted">*/}
                    {/*        <h3>Journey to Volcalidus is here - to show the DLC content on the wiki, toggle it in*/}
                    {/*            your <a href="/settings">settings</a>.</h3>*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    <div className="ui stackable two column grid">
                        <div className="eleven wide column">
                            <h2 title="The Hydroneer Wiki"><i className="newspaper icon"/> Latest on Bridgepour Wiki
                            </h2>
                            <BlogFeed limit={5}/>
                        </div>
                        <div className="sidebar five wide column">
                            <Spotlight/>

                            <GoogleAd slot="2735059186"/>

                            <h2><i className="dice d20 icon"/> Random Item</h2>
                            <RandomItem/>

                            <h2><i className="discord icon"/> Official Discord
                                <a className="ui mini brand button right floated"
                                   href="//discord.gg/hydroneer" rel="noopener noreferrer" target="_blank"><i
                                    className="external link icon"/> Join</a>
                            </h2>
                            <iframe src="https://discordapp.com/widget?id=383329651910639628&theme=dark" width="100%"
                                    height="300"
                                    title="Discord Widget"
                                    sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"/>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
}