import React from 'react';
import ScrollToTop from "../Utils/ScrollToTop";

export default function Footer() {
    return (
        <footer>
            <div className="ui container">
                <p>
                    <strong>Bridgepour wiki</strong> is the official community wiki for the game Hydroneer, <strong>the</strong> Hydroneer Wiki
                </p>

                <div className="ui list">
                    <a href="https://twitter.com/HydroneerGame" target="_blank" rel="noopener noreferrer"
                       className="item">
                        <i className="twitter icon"/>
                    </a>
                    <a href="/about" className="item">
                        About
                    </a>
                    <a href="/contact" className="item">
                        Contact Us
                    </a>
                    <a href="/privacy" className="item">
                        Privacy
                    </a>
                    <a href="https://www.youtube.com/channel/UCfwyMqFlEDfqoSWhYVgNeDQ" target="_blank"
                       rel="noopener noreferrer" className="item">
                        <i className="youtube icon"/>
                    </a>
                </div>
                <ScrollToTop />
            </div>
        </footer>
    )
}
