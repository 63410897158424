import React from 'react';
import ItemTooltip from "../../../Components/Items/ItemTooltip";

export default function SmithingCrafting() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted">
                <a href="/" className="section">Bridgepour Wiki Home</a>
                <i className="right angle icon divider"/>
                <a href="/guide-book" className="section">Guide Book</a>
                <i className="right angle icon divider"/>
                <div className="active section">Smithing and Crafting</div>
            </div>

            <div className="Page-header">
                <h1>
                    Smithing and Crafting
                    <div className="sub-heading">Crafting is the main way to make money in Hydroneer, learn how to get
                        started with crafting in the handy dandy guide below.</div>
                </h1>
            </div>

            <div className="ui segment">
                <div className="ui two column stackable grid">
                    <div className="column"><h3>Initial Setup</h3><p>To get started with smithing you’ll need a couple
                        of basic items - this will cost you (146c, or 463c if you want to get the Anvil, Blacksmithing
                        Hammer and Grinding Wheel ahead of time).</p><p>You will need to place the Small Furnace and the
                        Anvil - in order for them to work properly. You can do this by picking them up using
                        the <kbd>E</kbd> key when looking at them, then <kbd>Left click</kbd> to enter place mode. Look
                        at where you want to place the item, then <kbd>Left click</kbd> again.</p><p>You’ll also need a
                        handful of Gold Ore and/or Iron Ore.</p></div>
                    <div className="column">
                        <table className="ui table basic inverted striped unstackable">
                            <tbody>
                            <tr>
                                <td width="40%">
                                    <ItemTooltip item_lookup="small-furnace" /> (86c)
                                </td>
                                <td className="hide-mobile">Place down to use. Can heat up Crucibles and Bars for
                                    crafting.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ItemTooltip item_lookup="crucible" /> (18c)
                                </td>
                                <td className="hide-mobile">
                                    Heats iron, gold, cloutium ore, and shards placed inside to
                                    produce bars. Pour into mold when melted.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ItemTooltip item_lookup="casting-mold" /> (42c)
                                </td>
                                <td className="hide-mobile">Pour molten metal from the crucible into the mold to produce
                                    bars.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ItemTooltip item_lookup="anvil" /> (86c)
                                </td>
                                <td className="hide-mobile">Creates weapons and jewellery. Select a chalk outline, place
                                    materials on anvil face. Bars must be hot.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ItemTooltip item_lookup="blacksmithing-hammer" /> (105c)
                                </td>
                                <td className="hide-mobile">Used with an anvil to create weapons and jewellery. Hit the
                                    anvil with the required resources placed on top.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ItemTooltip item_lookup="grinding-wheel" /> (126c)
                                </td>
                                <td className="hide-mobile">Grind uncut gems into cut gems easily with this mounted
                                    stone wheel.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="ui segment">
                <h3>Smelting and making Bars</h3>
                <p>Start by picking up your ore of choice, for
                    this guide, we’ll use Gold Ore as our example. Pick up each Gold Ore nugget using
                    the <kbd>E</kbd> key
                    when looking at it. Now, position your green drop cursor inside the Crucible, and
                    press <kbd>E</kbd> key
                    again to drop the Gold Ore into the Crucible. Do this for as many Gold Ore you have.</p>
                <p>NOTE - Try
                    not to put any other type (Iron / Cloutium) in the Crucible at the same time, otherwise it’ll be
                    deleted. You cannot mix ores in Hydroneer.</p>
                <p>Once all Gold Ore is in the Crucible, pick the Crucible
                    up using the <kbd>E</kbd> key when looking at it. Walk over to your Small Furnace, line up the green
                    drop marker inside the Small Furnace, and press <kbd>E</kbd> to drop the Crucible on top of the
                    Small
                    Furnace. After a short time, you should see the Crucible start to glow orange, and a short time
                    after, a
                    bubbling sound will play, signalling your ore has melted and is ready to pour into a mold.</p>
                <p>Once
                    you see the ore is melted, pick up the crucible, using the <kbd>E</kbd> key, carry your Crucible
                    over to
                    your Bar Mold, and line up either your cursor, or the green drop circle, over the Mold, and <kbd>Left
                        click</kbd> to pour the liquid into the mold.</p>
                <p>Et voilà, your very first bar! Carry on reading
                    to find out what you can do with this shiny bar.</p>
            </div>

            <div className="ui segment">
                <h3>What can be done with bars?</h3>
                <p>You have a couple of options. If you
                    didn’t purchase the Anvil and Blacksmithing Hammer earlier, you can go ahead and sell the bar you
                    made,
                    in hopes of making enough money to purchase those two. Might need to make another bar or two,
                    too!</p>
                <p>Note - There is no minimum amount of resource required to make a bar. The only maximum is the overall
                    value of 21,474,836 Hydrocoins. This is very unlikely to happen unless you have a super optimised,
                    gigantic build and have been playing for tens of hours on a single save (or you’re in <a
                        href="/guide-book/guide/creative-mode">creative mode</a>, but that’s another story). If that’s
                    the case for you - you probably don’t need to be reading this guide.</p>
                <p>Now that you’ve hopefully
                    got your Anvil and Blacksmithing Hammer, you can start crafting! Your first craft, with a single
                    bar, will be a Bracelet with your Gold Bar, or a Dagger with your Iron Bar.</p>
                <p>Let’s go ahead and
                    get the bar heated, ready to be hammered. Pick up your bar using the <kbd>E</kbd> key - then walk it
                    over to your Small Furnace and drop it straight on the coals. You’ll see it slowly starts to glow
                    brighter over time. Whilst we wait for the Bar to heat up, go to your Anvil - whilst looking at the
                    anvil, <kbd>Left click</kbd> it to cycle through the available recipes.</p>
                <p>The recipes, in order
                    are; Dagger, Axe, Sword, Bracelet, Ring, Necklace.</p>
                <p>Now you’ve worked out how to choose a
                    recipe on the Anvil, select the Bracelet recipe - the icons above the bracelet show what hot bar is
                    required along with how many bars. In this case, we need one single hot Gold Bar. Grab the bar you
                    started warming up just now and drop it on the anvil, (you should know how to pick up stuff and how
                    to drop it nicely, by now, surely), Pick up your Blacksmithing Hammer, and give the Anvil a whack -
                    BAM - you should now have a shiny Bracelet sitting in place of the Gold Bar.</p>
                <p>Pick it up, and
                    run to the Jeweller and sell it for profit.</p>
            </div>

            <div className="ui segment">
                <h3>What next?!</h3>
                <p>You now know the basics on how to melt ores into bars
                    using your Crucible and Bar Mold, and also how to turn those bars into craftable items using your
                    Anvil
                    and Blacksmithing hammer. You can go ahead and start making Bracelets (1 Gold Bar), Daggers (1 Iron
                    Bar), Axes (2 Iron Bars) and Swords (3 Iron Bars) - but what about the Rings and Necklaces?</p>
                <p>Rings
                    and Necklaces require a cut Gem. For this, you’ll need the Grinding Wheel mentioned earlier. Once
                    placed, you can use an Uncut Gem on the Grinding Wheel to cut and polish it. Now you can simply plop
                    this on the Anvil when making a Ring (1 Gold Bar and 1 Cut Gem) or a Necklace (2 Gold Bar and 1 Cut
                    Gem).</p>
            </div>

            <a className="ui brand button" href="/guide-book/guide/getting-started"><i
                className="left arrow icon"/> Getting Started</a>
            <a className="ui brand button right floated" href="/guide-book/guide/tier-one">How to get started with automation <i className="right arrow icon"/></a>
        </div>
    )
}