import React from 'react';
import {API_URL} from "../../Utils/Config";
import Loader from "../../Utils/Loader";
import SectionTitle from "../../Helpers/SectionTitle";
import ItemButton from "./ItemButton";

export default class RecipeTable extends React.Component {
    state = {
        loading: true,
        data: null
    }

    async componentDidMount() {
        const url = API_URL + `/api/resources/` + this.props.item.safe_name;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, loading: false})
    }

    render() {
        return (
            <>
                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <>
                            {this.state.data.map(recipe => (
                                <>
                                    {recipe.input.length > 0 || recipe.output.length > 0 ? (
                                        <div className="ui segment">
                                            <SectionTitle title="This item is used in the following recipes" />
                                            <table className="ui table basic inverted striped recipe-table">
                                                <tbody>
                                                {this.state.data.map(recipe => (
                                                    <>
                                                        <tr>
                                                            <td width="30%">
                                                                <div className="ui link list">
                                                                    {recipe.input.map(input => (
                                                                        <>
                                                                            <ItemButton item_lookup={input.name} amt={input.amt} />
                                                                        </>
                                                                    ))}
                                                                </div>
                                                            </td>
                                                            <td width="40%" className={'recipe ' + (recipe.input.length > 0 ? 'input ' : '') + (recipe.output.length > 0 ? 'output ' : '')}>
                                                                <h3>{this.props.item.name}</h3>
                                                            </td>
                                                            <td width="30%">
                                                                <div className="ui link list">
                                                                    {recipe.output.map(output => (
                                                                        <>
                                                                            <ItemButton item_lookup={output} />
                                                                        </>
                                                                    ))}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ))}
                        </>
                    )}
            </>
        )
    }
}