import React from 'react';

export default function CreativeGuide() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted"><a href="/" className="section">Bridgepour Wiki Home</a><i
                className="right angle icon divider"></i><a href="/guide-book" className="section">Guide Book</a><i
                className="right angle icon divider"></i>
                <div className="active section">Creative Mode</div>
            </div>
            <div className="Page-header">
                <h1>Creative Mode in Hydroneer
                    <div className="sub-heading">Get creative and build that factory of your dreams with ease!</div>
                </h1>
            </div>
            <div className="ui segment">
                <h2>Creative mode is here!</h2><p>Hydroneers' Creative Mode sticks to the
                norm with Hydroneers' style. All creative functionality is held within actual world objects,
                which the player uses to interact differently with the world around them.</p><p>Not only are
                there tools to make life easier, players will not be punished for stealing items - Players are
                free to take items from all stores, without angering the store keepers.</p><p>Read on to find
                out what each tool is used for.</p>
            </div>
            <div className="ui segment">
                <div className="ui stackable grid">
                    <div className="two wide column"><img
                        src="https://cdn.bridgepour.com/images/items/creative-wand.png"
                        className="ui small rounded image" alt=""/></div>
                    <div className="fourteen wide column"><h2><a href="/database/creative-wand">Creative Wand</a></h2>
                        <p>The Creative Wand is used to duplicate and destroy items.</p><p>To use the Creative Wand,
                            pick it up using the <kbd>E</kbd> key.</p><p>To duplicate an item, first look at that item,
                            then <kbd>Left Click</kbd>, the item will spawn in front of you.</p><p>To delete an item,
                            first look at that item, then <kbd>Right Click</kbd>, the item will vaporize into thin air.
                        </p></div>
                </div>
            </div>
            <div className="ui segment">
                <div className="ui stackable grid">
                    <div className="two wide column"><img src="https://cdn.bridgepour.com/images/items/gravity-tool.png"
                                                          className="ui small rounded image" alt=""/></div>
                    <div className="fourteen wide column"><h2><a href="/database/gravity-tool">Gravity Tool</a></h2>
                        <p>The Gravity Tool is used to gravitate items, and YEET them into oblivion if you so
                            desire.</p><p>To use the Gravity Tool, pick it up using the <kbd>E</kbd> key.</p><p>To
                            gravitate an item, first look at that item, then <kbd>Left Click</kbd>, the item will be
                            floating just infront of the player, with a random rotation.</p><p>To YEET an item, first
                            look at that item, then <kbd>Right Click</kbd>, the rest is history.</p></div>
                </div>
            </div>
            <div className="ui segment">
                <div className="ui stackable grid">
                    <div className="two wide column"><img src="https://cdn.bridgepour.com/images/items/flight-cap.png"
                                                          className="ui small rounded image" alt=""/></div>
                    <div className="fourteen wide column"><h2><a href="/database/flight-cap">Flight Cap</a></h2><p>The
                        Flight Cap is purely used for.. flying!</p><p>To use the Flight Cap, make sure it's on the
                        floor, then hit the <kbd>Q</kbd> key to put it on your head.</p><p>Now, to control your height,
                        the <kbd>Spacebar</kbd> will move you up and the <kbd>C</kbd> key will move you down.</p><p>The
                        Flight Cap can also be stored on a Golden Head Statue to find it easier later on.</p></div>
                </div>
            </div>
            <div className="ui segment">
                <div className="ui stackable grid">
                    <div className="two wide column"><img src="https://cdn.bridgepour.com/images/items/hourglass.png"
                                                          className="ui small rounded image" alt=""/></div>
                    <div className="fourteen wide column"><h2><a href="/database/hourglass">Hourglass</a></h2><p>The
                        Hourglass is used to change time of day.</p><p>Place the Hourglass down in your world. <kbd>Left
                        Click</kbd> the buttons on the front to amend time.</p><p>The <i
                        className="left chevron icon"></i> button is used to change time backwards.</p><p>The <i
                        className="pause icon"></i> button is used to Pause and Play time.</p><p>The <i
                        className="right chevron icon"></i> button is used to change time forwards.</p></div>
                </div>
            </div>
            <div className="ui segment">
                <div className="ui stackable grid">
                    <div className="two wide column">
                        <img src="https://cdn.bridgepour.com/images/items/invincible-water-filter-hook.png" className="ui small rounded image" alt=""/>
                    </div>
                    <div className="fourteen wide column">
                        <h2><a href="/database/invincible-water-filter-hook">Invincible Water Filter Hook</a></h2>
                        <p>When placed on a water pipe, any machines placed after it will not take damage.</p>
                        <p>The Invincible Water Filter Hook will also never take damage, meaning you will never need to repair your machines any more.</p>
                    </div>
                </div>
            </div>
            <div className="ui segment">
                <div className="ui stackable grid">
                    <div className="two wide column">
                        <img src="https://cdn.bridgepour.com/images/items/hard-hat.png" className="ui small rounded image" alt=""/>
                    </div>
                    <div className="fourteen wide column">
                        <h2><a href="/database/hard-hat">Hard Hat</a></h2>
                        <p>The Hard Hat can be worn, to allow builders to infinitely place the item they are holding, without having to manually spawn another each time</p>
                        <p>Simply put the hat on by pressing <kbd>Q</kbd> when looking at it when it's on the ground, and it'll be equipped to your head.</p>
                        <p>Now, pick up a build item (such as floor, wall or ceiling) - and place it like normal. Once placed, you'll place a copy of the item, but also keep the one in your hand, allowing you to place another straight after.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}