import React from 'react';
import {API_URL, CDN_URL} from "../../Utils/Config";
import Loader from "../../Utils/Loader";

export default class ItemButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: "",
            loading: true,
            isShown: false
        }
    }

    async componentDidMount() {
        const url = API_URL + `/api/items/exact/${this.props.item_lookup}/`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({item: data.results[0], loading: false})
    }

    render() {
        return (
            <>
                {this.state.loading || !this.state.item ?
                    (
                        <Loader/>
                    ) : (
                        <a href={`/database/${this.state.item.name}`} className="item" key={this.state.item.name}>
                            {this.state.item.name} {this.props.amt > 1 && (<span>x {this.props.amt}</span>)}
                            <img onError={this.addDefaultSrc}
                                 src={CDN_URL + "/images/items/thumbs/" + this.props.item_lookup + ".png"}
                                 alt={this.state.item.name}
                                 className="ui avatar image"
                                 loading="lazy"
                            />
                        </a>
                    )
                }
            </>
        )
    }
}