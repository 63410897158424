import React, {useState, useEffect, useContext} from 'react';
import {LayerGroup, LayersControl, MapContainer, Marker, Popup, TileLayer, useMapEvents} from "react-leaflet";
import {FullscreenControl} from "react-leaflet-fullscreen";
import MouseCoordinates from "./MouseCoordinates";
import {API_URL} from "../../Utils/Config";
import {Link} from "react-router-dom";
import {digsiteIcon, grocerIcon, jewelerIcon, stockMarketIcon, storeIcon, npcIcon, forgeIcon, boneIcon} from "./pins/Pins";
import SettingsContext from "../../SettingsContext";
import Loader from "../../Utils/Loader";

const WorldMap = ({
                      zoom: propZoom = 1,
                      coords: propCoords = [0, 0],
                      npcver: propNpcver = false,
                      npcs: propNpcs = false
                  }) => {
    const [zoom, setZoom] = useState(propZoom);
    const [coords, setCoords] = useState(propCoords);
    const [npcver, setNpcver] = useState(propNpcver);
    const [npcs, setNpcs] = useState(propNpcs);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [categories, setCategories] = useState(null);
    const {settings, updateSettings} = useContext(SettingsContext);
    const favouriteIslandZoom = settings.favouriteIsland === 'volcalidus' ? 4 : 3;
    const [seed, setSeed] = useState(1);

    useEffect(() => {
        if (!npcver) {
            const fetchData = async () => {
                const response = await fetch(`${API_URL}/api/maplocations/`);
                const jsonData = await response.json();
                setData(jsonData.results[1].pins);
                setCategories(jsonData.results[0].categories);
                setLoading(false);
            };
            fetchData();
        }
    }, [npcver]);

    const handleClick = (event) => {
        const newIsland = event.target.id;
        setSeed(Math.random());
        updateSettings({...settings, favouriteIsland: newIsland});
    };

    const LocationFinderDummy = () => {
        const map = useMapEvents({
            click(e) {
                console.log(e.latlng.lat.toFixed(1), ",", e.latlng.lng.toFixed(1));
            },
        });
        return null;
    };

    const parsePinString = (pinString) => {
        const pinMap = {
            Digsite: digsiteIcon,
            "Stock Market": stockMarketIcon,
            Jeweler: jewelerIcon,
            Grocer: grocerIcon,
            Store: storeIcon,
            NPC: npcIcon,
            "Forge Mark": forgeIcon,
            "Hydrosaur Bone": boneIcon,
            "Idle Mine": digsiteIcon
        };
        return eval(pinMap[pinString]);
    };

    if (loading || !data) {
        return <Loader/>;
    }

    const filteredCategories = categories.filter(category =>
        category.pins.some(pin => pin.gameVersion ? pin.gameVersion === settings.favouriteIsland : 'bastion')
    );

    return (
        <>
            <div className="ui top attached tabular menu">
                <div className={`item ${settings.favouriteIsland === "bastion" ? "active" : ""}`} data-tab="bastion"
                     id="bastion" onClick={handleClick}>Bastion
                </div>
                {settings.showDLC && (
                    <div className={`item ${settings.favouriteIsland === "volcalidus" ? "active" : ""}`}
                         data-tab="volcalidus" id="volcalidus"
                         onClick={handleClick}>Volcalidus
                    </div>
                )}
            </div>

            <div className="mapContainer" key={seed}>
                <MapContainer
                    center={coords}
                    zoom={zoom}
                    style={{height: npcver ? "128px" : "512px"}}
                    minZoom={npcver ? 3 : 1}
                    maxZoom={favouriteIslandZoom}
                    zoomControl={!npcver}
                    maxBounds={[[90, -180], [-90, 180]]}
                >
                    <TileLayer
                        url={"/assets/images/map/" + settings.favouriteIsland + "/{z}/{x}/{y}.jpg"}
                        noWrap={true}
                    />

                    <LayersControl position="topright">
                        <>
                            {filteredCategories.map(cat => (
                                <>
                                    <LayersControl.Overlay name={cat.category}
                                                           checked={
                                                               (cat.category === "NPC" && npcs) ||
                                                               (!npcs && cat.category !== "NPC" && cat.category !== "Idle Mine" && cat.category !== "Forge Mark" && cat.category !== "Hydrosaur Bone" && !npcver)
                                                           }>
                                        <LayerGroup>
                                            {cat.pins.filter(pin => pin.gameVersion ? pin.gameVersion === settings.favouriteIsland : 'bastion').map(pin => (
                                                <Marker position={[pin.latitude, pin.longitude]}
                                                        icon={parsePinString(pin.category)}>
                                                    <Popup>
                                                        {pin.link ? (
                                                            <Link to={pin.link}>{pin.name}</Link>
                                                        ) : (
                                                            <>
                                                                {pin.name}
                                                            </>
                                                        )}
                                                        <br/>
                                                        {pin.description}
                                                    </Popup>
                                                </Marker>
                                            ))}
                                        </LayerGroup>
                                    </LayersControl.Overlay>
                                </>
                            ))}
                        </>
                        <LocationFinderDummy/>
                    </LayersControl>

                    {npcver && (
                        <Marker position={coords} icon={npcIcon}/>
                    )}

                    <div className="leaflet-bottom leaflet-left">
                        <MouseCoordinates/>
                    </div>

                    <FullscreenControl/>
                </MapContainer>
            </div>
        </>
    );
};

export default WorldMap;