import React from "react";
import PageTitle from "../Helpers/PageTitle";

export default class MPFAQ extends React.Component {
    render() {
        return (
            <>
                <div className="ui container">
                    <PageTitle title="Hydroneer Multiplayer FAQ" subheading="Hydroneer Multiplayer top frequently asked questions." />

                    <div className="ui segment">
                        <div className="ui inverted divided items">
                            <h1>PAGE TBC</h1>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}