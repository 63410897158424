import React from "react";
import {API_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import YouTube from "react-youtube";


export default class Spotlight extends React.Component {
    state = {
        loading: true,
        data: null,
        opts: {
            width: "100%",
            height: "170px",
            playerVars: {
                rel: 0,
                controls: 0,
                showinfo: 0
            }
        }
    }

    async componentDidMount() {
        const url = API_URL + "/api/spotlight/";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.result, loading: false})
    }

    render() {
        return (
            <>
                <h2><i className="lightbulb icon"/> Community Spotlight</h2>
                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <>
                            <div style={{marginLeft: "auto", marginRight: "auto", height: "170px", maxHeight: "170px"}}>
                                <YouTube videoId={this.state.data} opts={this.state.opts} onReady={this._onReady}/>
                            </div>
                        </>
                    )
                }
            </>
        )
    }
}