import React from 'react';

export default function HydroLogicf() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted">
                <a href="/" className="section">Bridgepour Wiki Home</a>
                <i className="right angle icon divider"/>
                <a href="/guide-book" className="section">Guide Book</a>
                <i className="right angle icon divider"/>
                <a href="/guide-book/guide/hydro-logic" className="section">HydroLogic</a>
                <i className="right angle icon divider"/>
                <div className="active section">III. Build examples</div>
            </div>

            <div className="Page-header">
                <h1>
                    III. Build examples
                </h1>
            </div>

            <div className="ui segment">
                <p>To finish this guide, here are some logic system that can be useful.</p>
                <p>We will not explain how they work, you should be able to understand them by yourself, you know
                    everything you need for that now.</p>
                <p>The goal here is to give you some ideas about what can be done in the game.</p>

            </div>

            <div className="ui segment">
                <h3>a. Clock</h3>
                <p>That system will send a signal every second.</p>
                <p>Just add more <span className="orange">delays</span> to send a signal every 2, 3, 4, etc. seconds.
                </p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>A simple variation will allow you to send alternatively a '1' and a '0'.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />

            </div>

            <div className="ui segment">
                <h3>b. Timed signal</h3>
                <p>Press the <span className="orange">button</span> to send '1' signal.</p>
                <p>Then, 3 seconds later, a '0' signal will be sent.</p>
                <p>Once again, the delay can be adjusted.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />

            </div>

            <div className="ui segment">
                <h3>c. Auto-repair system</h3>
                <p>Very very very convenient.</p>
                <p>R is the <span className="orange">durability reader</span>, T is the <span className="orange">spanner thrower</span>.
                </p>
                <p>That one will trigger when the durability reaches '0'.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>You can also choose the durability threshold that will trigger the system.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />

            </div>

            <div className="ui segment">
                <h3>d. Auto-smelter (by rexxar)</h3>
                <p><strong>The most simple, compact and reliable logic system for auto-smelting.</strong></p>
                <p>A <span className="orange">counter</span> associated with a <span
                    className="orange">bigger than</span> gate will trigger the system when a defined number of ores
                    passed under the <span className="orange">counter</span>.</p>
                <p>The <span className="orange">magnet</span> will activate to hold any incoming ores while the <span
                    className="orange">smelter</span> is creating an ingot, before releasing them once the <span
                    className="orange">smelter</span> is done.</p>
                <p>C is the <span className="orange">counter</span>, S is the <span
                    className="orange">smelter</span> and M is a <span className="orange">power valve</span> linked to
                    the <span className="orange">magnet</span></p>
                <img src="http://placehold.it/450x100" alt="placeholder" />

            </div>

            <div className="ui segment">
                <h3>e. Light chaser (and RGB lamp)</h3>
                <p>That system will allow you to light RGB lamps with alternating colors.</p>
                <p>Each module is for a different color, you can put as many modules as you want.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />

                <p>A word about RGB lamps:</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>Send it a logic value with the RGB format.</p>
                <p>The lamp will light with the color associated to that RGB code.</p>
                <p>Here are some basic colors:</p>
                <p><span className="red">255</span><span className="green">000</span><span className="blue">000</span> →
                    Red</p>
                <p><span className="red">000</span><span className="green">255</span><span className="blue">000</span> →
                    Green</p>
                <p><span className="red">000</span><span className="green">000</span><span className="blue">255</span> →
                    Blue</p>
                <p><span className="red">255</span><span className="green">000</span><span className="blue">255</span> →
                    Purple</p>
                <p><span className="red">255</span><span className="green">255</span><span className="blue">000</span> →
                    Yellow</p>
                <p><span className="red">000</span><span className="green">255</span><span className="blue">255</span> →
                    Cyan</p>
                <p><span className="red">255</span><span className="green">255</span><span className="blue">255</span> →
                    White</p>
            </div>

            <a className="ui brand button" href="/guide-book/guide/hydro-logic/logic-elements-part-three/"><i className="left arrow icon"/> Back</a>
            <br/>
        </div>
    )
}