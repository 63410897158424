import React from "react";
import {API_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import {Navigate, useParams} from "react-router-dom";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class RedirectLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            url: null,
            query: this.props.params.search_query
        }
    }

    async componentDidMount() {
        const item_url = API_URL + `/api/items/exact/${this.state.query}/`;
        const item_response = await fetch(item_url);
        const item_data = await item_response.json();

        let url = "/"

        if (this.state.query.includes("store-")) {
            const shop_url = API_URL + `/api/shop/${this.state.query.replace("store-", "")}/`;
            const shop_response = await fetch(shop_url);
            const shop_data = await shop_response.json();

            if (shop_data.results.length >= 1) {
                url = `/guide-book/towns/${shop_data.results[0].town.town_safe_name}/${shop_data.results[0].safe_name}/${shop_data.results[0].id}`
            }
        }

        const town_url = API_URL + `/api/towns/exact/${this.state.query}/`;
        const town_response = await fetch(town_url);
        const town_data = await town_response.json();

        const groups_url = API_URL + `/api/groups/${this.state.query}`;
        const groups_response = await fetch(groups_url);
        const groups_data = await groups_response.json();

        // const vehicle_url = API_URL + `/api/vehicles/${this.state.query}/`;
        // const vehicle_response = await fetch(vehicle_url);
        // const vehicle_data = await vehicle_response.json();

        if (this.state.query.includes("blog-")) {
            const blog_url = API_URL + `/api/blogposts/exact/${this.state.query.replace("blog-", "")}/`;
            const blog_response = await fetch(blog_url);
            const blog_data = await blog_response.json();

            if (blog_data.results.length >= 1) {
                url = `/blog/${blog_data.results[0].id}`
            }
        }

        if (this.state.query.includes("patch-")) {
            const patch_url = API_URL + `/api/patchnote/exact/${this.state.query}/`;
            const patch_response = await fetch(patch_url);
            const patch_data = await patch_response.json();

            if (patch_data.results.length >= 1) {
                url = `/patchnotes/`
            }
        }

        if (groups_data.results.length >= 1) {
            url = `/database/groups/${this.state.query}`
        }

        if (item_data.results.length >= 1) {
            url = `/database/${this.state.query}`
        }

        if (town_data.results.length >= 1) {
            url = `/guide-book/towns/`
        }

        // if (vehicle_data.results.length >= 1) {
        //     url = `/guide-book/towns/`
        // }

        if (["ember-cradle", "south-hope", "mildews-aquifer", "dreck-quarry", "icehelm", "snowrune-falls", "dawn-rest", "shattered-breach", "cinder-footing", "waterside", "scoria-chamber", "lumberton-bluff"].includes(this.state.query)) {
            url = `/guide-book/digsites/`
        }

        this.setState({
            loading: false,
            url: url
        })
    }

    render() {
        return (
            <div className="ui container">
                {this.state.loading ?
                    (
                        <Loader/>
                    ) : (
                        <Navigate to={this.state.url} />
                    )
                }
            </div>
        )
    }
}

export default withParams(RedirectLink);