import React from 'react';
import ItemCardRedo from "../../../Components/Items/ItemCardRedo";

export default function FishingTools() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted"><a href="/" className="section">Bridgepour Wiki Home</a><i
                className="right angle icon divider"></i><a href="/guide-book" className="section">Guide Book</a><i
                className="right angle icon divider"></i><a href="/guide-book/guide/fishing"
                                                            className="section">Fishing</a><i
                className="right angle icon divider"></i>
                <div className="active section">Fishing Tools</div>
            </div>
            <div className="Page-header">
                <h1>What Fishing Poles and Tools are available when Fishing in Hydroneer?
                    <div className="sub-heading">Fishing in Hydroneer has a great progression system, see all of the poles and tools here!</div>
                </h1>
            </div>
            <div className="ui segment">
                <div className="ui stackable grid">
                    <div className="column">
                        <h2>Fishing, in a mining game!?</h2>
                        <p>Fishing was always a part of Hydroneer, it was once a simple mechanic. Cast your fishing pole out, wait a few seconds, catch a boring fish. Rinse and repeat.</p>
                        <p>Now though, fishing has been expanded in the 1.7 update! Players now have the ability to climb the ranks of the fishing order and acquire new and unique fishing poles along the way, by showing off their skill of catching various sea creatures!</p>
                        <p>Sound fun to you? Read on!</p>
                    </div>
                </div>
            </div>
            <div className="ui segment">
                <h3>Common Catches</h3>
                <div className="Item-grid">
                    {(["my-first-fishing-pole", "wonky-fishing-pole", "'ol' slippy grip' Fishing Pole", "'the plaice holder' Fishing Pole", "Emerald Fishing Pole", "'a reel dream' Fishing Pole", "'The Night Rod' Fishing Pole", "Master Fishing Pole"]).map(x => (
                        <ItemCardRedo item_lookup={x} hide_details/>
                    ))}
                </div>
            </div>

            <a className="ui brand button" href="/guide-book/guide/fishing-catches"><i
                className="left arrow icon"></i> Back to the catches</a>
        </div>
    )
}