import React from "react";
import {API_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import PageTitle from "../Helpers/PageTitle";


export default class Pokes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null
        }
    }

    async componentDidMount() {
        let url = API_URL + `/api/pokes/`;

        if (this.props.all) {
            url = url + 'all';
        }

        const response = await fetch(url);
        const data = await response.json();

        this.setState({
            loading: false,
            data: data.results
        })
    }

    render() {
        return (
            <div className="ui container">

                <PageTitle title="View the Poke Leaderboard" subheading="View the ranks of Pokers and Pokees!" />

                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <>
                            <div className="ui segment">
                                <a className="ui button brand right floated" href={this.props.all ? ('/pokes') : ('/pokes/all')}>{this.props.all ? ('Back') : ('Show all')}</a>
                                <br/>
                                <br/>
                                <table className="ui table very basic inverted unstackable sortable">
                                    <thead>
                                        <tr>
                                            <th>Rank</th>
                                            <th>Username</th>
                                            <th>Pokes</th>
                                        </tr>
                                    </thead>
                                    {this.state.data.map(poke => (
                                        <tr>
                                            <td>{poke.rank}</td>
                                            <td>{poke.username}</td>
                                            <td>{poke.pokes}</td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        </>
                    )
                }
            </div>
        )
    }
}