import React, {useState, useEffect, useContext} from 'react';
import {API_URL, CDN_URL} from '../Utils/Config';
import Loader from '../Utils/Loader';
import ItemTooltip from './Items/ItemTooltip';
import SectionTitle from '../Helpers/SectionTitle';
import {safeName} from '../Utils/Utils';
import SettingsContext from "../SettingsContext";

const VehicleList = ({showAll, store}) => {
    const [data, setData] = useState(null);
    const { settings } = useContext(SettingsContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const url = API_URL + `/api/vehicles/`;
            try {
                const response = await fetch(url);
                const json = await response.json();
                setData(json.results);
            } catch (error) {
                console.error("Error fetching vehicles:", error);
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    const addDefaultSrc = (e) => {
        e.target.src = `${CDN_URL}/images/items/no_image.png`;
    };

    if (loading) {
        return <Loader/>;
    }


    const filteredVehicles = data && data
        // .filter(vehicle => !showAll || vehicle.gameVersion ? vehicle.gameVersion.includes(settings.favouriteIsland) : 'bastion') // Apply gameVersion filter only if !showAll
        .filter(vehicle => showAll || vehicle.availableStores.some(availableStore => availableStore.id === store.id)); // Apply availableStores filter based on showAll


    return (
        <>
            {filteredVehicles.map(vehicle => (
                <React.Fragment key={vehicle.id}>
                    <div className="ui segment">
                        <div className="ui eight column doubling grid container">
                            <VehicleDetails vehicle={vehicle} showAll={showAll} store={store} addDefaultSrc={addDefaultSrc}/>
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </>
    );
};

const VehicleDetails = ({vehicle, showAll, store, addDefaultSrc}) => (
    <>
        <div className="six wide column">
            <SectionTitle title={vehicle.name}/>
            {!showAll && vehicle.price && (
                <p>You can purchase this vehicle for {(vehicle.price / 100).toLocaleString()} <ItemTooltip
                    item_lookup={vehicle.currency}/>.</p>
            )}
            <UsageInstructions vehicle={vehicle}/>

            <p>{vehicle.name} requires {vehicle.gameVersion === 'volcalidus' ? 'lava' : 'water'} as fuel. If your vehicle isn't moving, it's more than likely out of
                fuel.</p>
            <p>Simply grab a <ItemTooltip item_lookup="pan"/>, <ItemTooltip item_lookup="bucket"/>{vehicle.gameVersion === 'bastion' && (<>or <ItemTooltip
                item_lookup="watering-can"/></>)}, fill that up from the nearest {vehicle.gameVersion === 'volcalidus' ? 'lava' : 'water'} source, then drip the {vehicle.gameVersion === 'volcalidus' ? 'lava' : 'water'} into the
                fuel tank.</p>
            <p>Top tip: You can also refuel vehicles by a drip from a {vehicle.gameVersion === 'volcalidus' ? 'lava' : 'water'} pipe{vehicle.gameVersion === 'bastion' && (<>, or even better, a <ItemTooltip item_lookup="sprinkler-hook"/></>)}</p>
        </div>

        <div className="five wide column">
            <img onError={addDefaultSrc} src={CDN_URL + "/images/items/" + safeName(vehicle.name) + ".png"}
                 alt={vehicle.name} loading="lazy"/>
        </div>

        <div className="sidebar five wide column">
            <VehicleStats stats={vehicle.stats}/>
        </div>
    </>
);

const UsageInstructions = ({vehicle}) => {
    if (vehicle.name === "Hydro Prospector") {
        return (
        <>
            <p>The Hydro Prospector is used to locate Ore Veins under the surface of your digsite.</p>
            <p>Whilst driving the Prospector around your digsite - the radar will rotate towards the nearest Ore
                Vein.</p>
            <p>When the radar is spinning out of control, you have found an ore vein. Stop your vehicle, then <kbd>Right
                Click</kbd> to extend the drill arm.</p>
            <p>The Drill arm will slowly dig down until it reaches the Ore Vein, and when it does, you'll see a particle
                effect on the top of the drill arm.</p>
            <p>This will show you which resource the ore vein is for.</p>
            <p><kbd>Right click</kbd> again to retract the drill arm.</p>
            <p>Now you have to get out, and dig by hand, or use the Hydro Mole, to uncover the ore vein.</p>
        </>)
    }
    if (vehicle.name === "Hydro Mole") {
        return (
        <>
            <p>The Hydro Mole is for digging tunnels and excavating large areas in your digsite.</p>
            <p>You can rotate the drill head by using <kbd>R</kbd> (down), <kbd>T</kbd> (up) and <kbd>Y</kbd> (forward).
                These rotations are world based, so lock in a rotation and drive forward to create a tunnel.</p>
            <p><kbd>Left click</kbd> to turn on the drill head, <kbd>Left click</kbd> again to turn it off.</p>
        </>)
    }
    if (vehicle.name === "Hydro Roller") {
        return (
        <>
            <p>The Hydro Roller is used to flatten terrain, preferably on the surface of your digsite.</p>
            <p>Use <kbd>Right click</kbd> to raise or lower the roller drum. Driving with the roller down will flatten
                terrain.</p>
        </>)
    }
};

const VehicleStats = ({stats}) => (
    <table className="ui table basic inverted striped unstackable">
        <tbody>
        <tr>
            <td width="30%">Acceleration</td>
            <td>
                <progress value={stats.acceleration} max="10"
                          style={{width: "100%"}}/>
            </td>
        </tr>
        <tr>
            <td>Top Speed</td>
            <td>
                <progress value={stats["top-speed"]} max="10"
                          style={{width: "100%"}}/>
            </td>
        </tr>
        <tr>
            <td>Handling</td>
            <td>
                <progress value={stats.handling} max="10"
                          style={{width: "100%"}}/>
            </td>
        </tr>
        <tr>
            <td>Braking</td>
            <td>
                <progress value={stats.braking} max="10"
                          style={{width: "100%"}}/>
            </td>
        </tr>
        <tr>
            <td>Storage Capacity</td>
            <td>
                <progress value={stats["storage-capacity"]}
                          max="10" style={{width: "100%"}}/>
            </td>
        </tr>
        <tr>
            <td>Weight</td>
            <td>
                <progress value={stats.weight} max="10"
                          style={{width: "100%"}}/>
            </td>
        </tr>
        </tbody>
    </table>
);

export default VehicleList;
