import React from 'react';
import './ItemCard.css';
import {CDN_URL} from "../../Utils/Config";
import {safeName} from "../../Utils/Utils";

const InventoryGrid = (props) => {
    const addDefaultSrc = (e) => {
        e.target.src = `${CDN_URL}/images/items/no_image.png`;
    };

    return (
        <div className="inventory-grid">
            {props.itemarray ? (
                <>
                    {props.itemarray.map(item => (
                        <a key={item} href={`/database/${safeName(item.name)}`} className={`inventory-item ${(item.alias + "").trim().toLowerCase().includes('fish') ? 'water' : ''} ${item.gameVersion === 'volcalidus' ? 'volcalidus' : 'bastion'}`}>
                            <img
                                src={`${CDN_URL}/images/items/${safeName(item.name)}.png`}
                                onError={addDefaultSrc}
                                alt={item}
                                width="128px"
                                loading="lazy"
                                title={item.name.replace(',', '')}
                            />

                            <span className="details">
                                {item.name}
                            </span>
                        </a>
                    ))}
                </>
            ) : (
                <>
                    {props.items.sort((a, b) => a.localeCompare(b)).map(item => (
                        <a key={item} href={`/database/${safeName(item)}`}
                           className={`inventory-item ${(item.alias + "").trim().toLowerCase().includes('fish') ? 'water' : ''} ${item.gameVersion === 'volcalidus' ? 'volcalidus' : 'bastion'}`}>
                            <img
                                src={`${CDN_URL}/images/items/${safeName(item)}.png`}
                                onError={addDefaultSrc}
                                alt={item}
                                width="128px"
                                loading="lazy"
                                title={item.replace(',', '')}
                            />

                            <span className="details">
                                {item.replace(',', '')}
                            </span>
                        </a>
                    ))}
                </>
            )}
        </div>
    );
};

export default InventoryGrid;