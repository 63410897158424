import React from 'react';
import {Link, useParams} from "react-router-dom";
import {API_URL, CDN_URL, PUBLIC_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import {safeName} from "../Utils/Utils";
import PageTitle from "../Helpers/PageTitle";
import TabNavigation from "../Layouts/TabNavigation";
import SettingsContext from "../SettingsContext";
function withParams(Component) {
    return props => <Component {...props} params={useParams()}/>;
}

class List extends React.Component {
    static contextType = SettingsContext;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            total_items: 0,
            total_value: 0,
            list_name: props.params.list_name || "Custom List",
            unique_code: props.params.encoded_list,
            shopping_list: (atob(props.params.encoded_list)).split(","),
            towns: []
        }
    }

    async componentDidMount() {
        let item_list = [];

        for (let item in this.state.shopping_list) {
            let item_parts = this.state.shopping_list[item].split("|");
            const url = API_URL + "/api/items/exact/" + item_parts[0] + "/";
            const response = await fetch(url);
            let data = await response.json();
            let this_item = data.results[0];
            data = {...this_item, quantity: item_parts[1]};
            item_list = [...item_list.concat(data)];
        }

        const uniqueTowns = Object.values(
            item_list.reduce((towns, item) => {
                item.stocked.forEach(town => {
                    if (!towns[town.name]) {
                        towns[town.name] = {
                            name: town.name,
                            town_safe_name: town.town_safe_name,
                            id: town.id,
                            stores: [],
                        };
                    }

                    const t = towns[town.name];
                    if (!t.stores.some(store => store.id === town.stores[0].id)) {
                        t.stores.push(town.stores[0]);
                    }
                });
                return towns;
            }, {})
        );

        this.setState({data: item_list, towns: uniqueTowns, loading: false})
        this.update_totals();
    }

    async update_item_qty(e) {
        let item_id = e.target.name;
        let new_qty = e.target.value;
        let current_data = this.state.data;

        for (let item in current_data) {
            if (current_data[item].id.toString() === item_id) {
                current_data[item].quantity = Number(new_qty);
            }
        }

        this.setState({data: current_data, loading: false})
    }

    update_qty = (e) => {
        this.setState({loading: true})
        this.update_item_qty(e);
        this.update_totals();
    }

    update_totals() {
        let items = this.state.data;
        let new_quantity = 0;
        let new_value = 0;

        for (let item in items) {
            new_quantity = Number(new_quantity) + Number(items[item].quantity);
            new_value = Number(new_value) + Number(items[item].value) * Number(items[item].quantity);
        }

        this.setState({
            total_items: new_quantity,
            total_value: new_value
        }, function () {
            this.update_code()
        });
    }

    update_code() {
        let code_to_encode = [];

        for (let item in this.state.data) {
            code_to_encode = [...code_to_encode.concat(this.state.data[item].id + "|" + this.state.data[item].quantity)];
        }

        localStorage.setItem('shopping_list', code_to_encode);
        this.setState({unique_code: btoa(code_to_encode)})
    }

    addDefaultSrc = (e) => {
        e.target.src = `${CDN_URL}/images/items/no_image.png`
    }

    remove_item = (e) => {
        let x = e.target.name.split("|");

        this.setState({data: this.state.data.filter(item => item.id !== Number(x[0]))},
            function () {
                this.update_totals()
            });
    }

    render() {
        const settings = this.context;

        return (
            <>
                <PageTitle title={this.state.list_name || "Build a shopping list!"} />

                <div className="ui container">
                    <div className="ui breadcrumb inverted">
                        <a href="/" className="section">Bridgepour Wiki Home</a>
                        <i className="right angle icon divider"/>
                        <a href="/database/" className="section">Item Database</a>
                        <i className="right angle icon divider"/>
                        <a href="/list/" className="section">Shopping List</a>
                        <i className="right angle icon divider"/>
                        <div className="active section">{this.state.list_name}</div>
                    </div>

                    <div className="ui message inverted">Hey! The <strong>Shopping List</strong> feature is a work in
                        progress, please report any bugs / issues / feedback in the Discord and tag @ItchyBeard, thanks!
                    </div>

                    {(this.state.shopping_list.length > 10 && this.state.loading) && (
                        <div className="ui message inverted warning">This list contains quite a few items, please
                            wait.</div>
                    )}

                    <h1>{this.state.list_name}</h1>

                    <div className="ui segment">
                        <div className="ui two column stackable grid">
                            <div className="three wide column">
                                <a href={"/list/" + this.state.unique_code + "/" + this.state.list_name}
                                   className="ui button basic brand fluid">
                                    <i className="save icon"/> Save / Share
                                </a>
                            </div>
                            <div className="thirteen wide column">
                                <form className="ui form">
                                    <div className="field">
                                        <input type="text"
                                               value={PUBLIC_URL + "/list/" + this.state.unique_code + "/" + this.state.list_name}
                                               disabled/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="ui two column stackable grid">
                        <div className="column">
                            <div className="ui segment">
                                <h3>
                                    Shopping List
                                    <a href="/list/" className="ui button basic mini brand right floated">
                                        <i className="plus circle icon"/> Add more items
                                    </a>
                                </h3>
                                <table className="ui table basic inverted striped unstackable">
                                    <thead>
                                    <tr>
                                        <th width="32px"><i className="shopping basket icon"/></th>
                                        <th width="150px">Quantity</th>
                                        <th width="32px"><i className="thumbnail icon"/></th>
                                        <th>Name</th>
                                        <th width="12%">Each</th>
                                        <th width="13%">Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <>
                                        {this.state.loading || !this.state.data ?
                                            (
                                                <tr>
                                                    <td colSpan="6"><Loader/></td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {this.state.data.map(item => (
                                                        <tr key={item.id}>
                                                            <td>
                                                                <button className="ui icon button mini basic brand"
                                                                        type="submit" onClick={this.remove_item}
                                                                        name={item.id + "|" + item.quantity}>
                                                                    <i className="trash icon"
                                                                       style={{pointerEvents: "none"}}/>
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <form className="ui form">
                                                                    <div className="field">
                                                                        <input type="number" name={item.id}
                                                                               defaultValue={item.quantity}
                                                                               onChange={this.update_qty}/>
                                                                    </div>
                                                                </form>
                                                            </td>
                                                            <td>
                                                                <Link to={"/database/" + safeName(item.name)}
                                                                      item={safeName(item.name)}>
                                                                    <img onError={this.addDefaultSrc}
                                                                         src={CDN_URL + "/images/items/" + safeName(item.name) + ".png"}
                                                                         alt={item.name}/>
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to={"/database/" + safeName(item.name)}
                                                                      item={safeName(item.name)}>
                                                                    {item.name}
                                                                </Link>
                                                            </td>
                                                            <td className="text-right">{(item.value).toLocaleString(undefined, {maximumFractionDigits: 0})}</td>
                                                            <td className="text-right">{(item.value * item.quantity).toLocaleString(undefined, {maximumFractionDigits: 0})}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )
                                        }
                                    </>
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colSpan="4">{this.state.total_items} total items</td>
                                        <td colSpan="2" className="text-right">
                                            {(this.state.total_value).toLocaleString(undefined, {maximumFractionDigits: 0})}c
                                        </td>
                                    </tr>
                                    </tfoot>
                                </table>

                            </div>
                        </div>
                        <div className="column">
                            {/* TODO - access settings component correctly */}
                            {/*<TabNavigation/>*/}
                            <div className="ui bottom attached tab segment active">
                                <h3>Notable Locations</h3>
                                <p>The below are the stores you can visit to purchase the items required.</p>

                                <div className="Town-list">
                                    {this.state.loading || !this.state.towns ?
                                        (
                                            <Loader/>
                                        ) : (
                                            <>
                                                {this.state.towns.map(town => (
                                                    <>
                                                        <div key={town.stores.id}
                                                             className={"ui segment " + town.town_safe_name}>
                                                            <h3>{town.name}</h3>
                                                            <div className="button-grid">
                                                                {town.stores.map(store => (
                                                                    <a href={"/guide-book/towns/" + town.town_safe_name + "/" + store.safe_name + "/" + store.id}
                                                                       className="ui brand button"
                                                                       key="2">
                                                                        {store.name}
                                                                    </a>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withParams(List);