import React, {Component} from "react";
import ModderCard from "../Components/Mods/ModderCard";
import ModCard from "../Components/Mods/ModCard";
import {API_URL, STEAM_WORKSHOP_LINK} from "../Utils/Config";
import PageTitle from "../Helpers/PageTitle";
import ModCreators from "../Data/ModCreators.json"
import Loader from "../Utils/Loader";
import SectionTitle from "../Helpers/SectionTitle";
import GoogleAd from "../Components/GoogleAd";

export default class Mods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            lastUpdate: null
        }
    }

    async componentDidMount() {
        const url = API_URL + "/api/mods";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, loading: false})
    }

    render() {
        return (
            <>
                <PageTitle title="Hydroneer Mods"
                           subheading="Check out this page for a link to Steam Workshop supported mods."/>
                <div className="ui container">
                    <div className="ui breadcrumb inverted">
                        <a href="/" className="section">Bridgepour Wiki Home</a>
                        <i className="right angle icon divider"/>
                        <div className="active section">Mods</div>
                    </div>


                    <div className="ui message inverted">
                        <h3>GAME CRASHING ON LAUNCH?</h3>
                        <p>
                            If you receive a "Corrupt Data found" error, or the game fails to launch - please remove all
                            mods and re-download them from the mods page, and follow the instructions on the FAQ page on
                            installing them again.
                        </p>
                    </div>

                    <div className="ui two column doubling grid container">
                        <div className="eleven wide column">
                            <a href={STEAM_WORKSHOP_LINK}
                               target="_blank" rel="noopener noreferrer"
                               className="workshop-banner">&nbsp;</a>
                            <div className="ui segment">
                                <SectionTitle title="Installing Mods"/>
                                <p>
                                    Steam Workshop integration was built directly in to Hydroneer as of May 8th 2022
                                    (version 2.0)
                                </p>
                                <p>
                                    To install mods, load up your game and click "Mods" from the main menu.
                                </p><
                                p>After activating a mod in the "Installed Mods" tab, you will need to click "Reload
                                Mods".</p>
                            </div>


                            <div className="ui segment mod-grid">
                                <SectionTitle title="Top 30 Workshop Mods in the last 7 days"/>
                                <p>Links below are populated from the Steam Workshop, clicking any of them will take you
                                    to their respective page on the Steam Workshop.</p>

                                {this.state.loading || !this.state.data ?
                                    (
                                        <Loader/>
                                    ) : (
                                        <div className="Item-grid">
                                            {this.state.data.map(workshopMod => (
                                                <ModCard mod={workshopMod}/>
                                            ))}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="sidebar five wide column">
                            <a className="ui brand button fluid"
                               href={STEAM_WORKSHOP_LINK}
                               target="_blank" rel="noreferrer noopener">
                                View Steam Workshop
                            </a>
                            <br/>
                            <a href="/mod-faq" className="ui brand button fluid">View the Mods FAQ</a>
                            {/* Display Mod Contributors */}
                            {ModCreators.map(Creator => (
                                <ModderCard name={Creator.name} git={Creator.git} workshop={Creator.workshop}/>
                            ))}
                            <br/>
                            <GoogleAd slot="3167129403"/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
