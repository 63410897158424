import React from 'react';
import BlogFeed from "../Components/Blog/BlogFeed";
import PageTitle from "../Helpers/PageTitle";


export default class BlogList extends React.Component {
    render() {

        return (
            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">Blog</div>
                </div>

                <PageTitle title="Bridgepour Blog" />

                <BlogFeed />
            </div>
        )
    }
}