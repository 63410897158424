import React, {useState, useEffect, useContext} from 'react';
import './ItemCard.css';
import { safeName } from "../../Utils/Utils";
import { API_URL, CDN_URL } from "../../Utils/Config";
import Loader from "../../Utils/Loader";
import SettingsContext from "../../SettingsContext";
import {Navigate} from "react-router-dom";

const ItemCardRedo = ({ item, item_lookup, no_link, horizontal, badge_links, hide_details, amt }) => {
    const [loading, setLoading] = useState(true);
    const [itemState, setItemState] = useState(item);
    // const [itemClicks, setItemClicks] = useState(0);

    useEffect(() => {
        const fetchItem = async () => {
            if (item_lookup) {
                const url = `${API_URL}/api/items/exact/${item_lookup}/`;
                try {
                    const response = await fetch(url);
                    const data = await response.json();
                    setItemState(data.results[0]);
                } catch (error) {
                    console.error("Failed to fetch item details:", error);
                }
            }
            setLoading(false);
        };

        fetchItem();
    }, [item_lookup]);

    const addDefaultSrc = (e) => {
        e.target.src = `${CDN_URL}/images/items/no_image.png`;
    };

    if (loading || !itemState) {
        return <Loader />;
    }

    const cardClassName = `ui card ${horizontal ? 'horizontal' : 'fluid'}`;
    const imageClassName = `image ${(itemState.alias + "").trim().toLowerCase().includes('fish') ? 'water' : ''} ${itemState.gameVersion && itemState.gameVersion === 'volcalidus' ? 'volcalidus' : 'bastion'}`;

    return (
        <>
            {no_link ? (
                <div className={cardClassName} key={safeName(itemState.name)}>
                    <div className={imageClassName}>
                        {/*{itemState.name === 'Forge Mark' ? (*/}
                        {/*    <span onClick={() => setItemClicks(Math.min(itemClicks + 1, 5))}>*/}
                        {/*        {itemClicks === 5 && (<Navigate to="/easteregg" />)}*/}
                        {/*        <img onError={addDefaultSrc} src={`${CDN_URL}/images/items/${safeName(itemState.name)}.png`} alt={itemState.name} loading="lazy"/>*/}
                        {/*    </span>*/}
                        {/*) : (*/}
                        {/*    <img onError={addDefaultSrc} src={`${CDN_URL}/images/items/${safeName(itemState.name)}.png`} alt={itemState.name} loading="lazy"/>*/}
                        {/*)}*/}

                        <img onError={addDefaultSrc} src={`${CDN_URL}/images/items/${safeName(itemState.name)}.png`} alt={itemState.name} loading="lazy"/>

                        {itemState.gameVersion && itemState.gameVersion.includes('volcalidus') && (<span className="ui brand right corner label dlc"><span>&emsp;&emsp;DLC</span></span>)}
                    </div>
                    <CardContent item={itemState} badgeLinks={badge_links} hideDetails={hide_details} value={itemState.value} description={itemState.description} modded={itemState.modded} amt={amt}/>
                </div>
            ) : (
                <a className={cardClassName} key={safeName(itemState.name)} href={`/database/${safeName(itemState.name)}`}>
                    <div className={imageClassName}>
                        <img onError={addDefaultSrc} src={`${CDN_URL}/images/items/${safeName(itemState.name)}.png`} alt={itemState.name} loading="lazy"/>
                        {itemState.gameVersion && itemState.gameVersion.includes('volcalidus') && (<span className="ui brand right corner label dlc"><span>&emsp;&emsp;DLC</span></span>)}
                    </div>
                    <CardContent item={itemState} badgeLinks={badge_links} hideDetails={hide_details} value={itemState.value} description={itemState.description} modded={itemState.modded} amt={amt} />
                </a>
            )}
        </>
    );
};

const CardContent = ({ item, badgeLinks, hideDetails, value, description, modded, amt }) => {
    const renderBadges = (badges, badgeLinks) => {
        return badges.replace("(", "").replace(")", "").split(",").map((badge, index) => (
            badgeLinks ? (
                <a className="badge" title={badge.trim()} key={index} href={`#${badge.trim()}`}>
                    <img src={`${CDN_URL}/images/badges/${badge.trim()}.png`} alt={badge.trim()} loading="lazy"/>
                </a>
            ) : (
                <div className="badge" title={badge.trim()} key={index}>
                    <img src={`${CDN_URL}/images/badges/${badge.trim()}.png`} alt={badge.trim()} loading="lazy"/>
                </div>
            )
        ));
    };

    return (
        <div className="content">
            <div className="header">{item.name}</div>
            <div className="meta badges">{renderBadges(item.badges, badgeLinks)}</div>
            <div className="meta">{modded && <><i className="wrench icon"/> Modded</>}</div>
            {amt && <span className="Item-amount">{amt}</span>}
            {description && <div className="description">{description}</div>}
            {(!hideDetails || value !== 0) && <ExtraContent value={value} hideDetails={hideDetails} itemName={item.name} />}
        </div>
    );
};

const ExtraContent = ({ value, hideDetails, itemName }) => (
    <div className="extra content">
        <table className="ui table very basic inverted unstackable">
            <tbody>
            {value !== 0 && (
                <tr>
                    <td width="40%">Value</td>
                    <td className="text-right">{value.toLocaleString(undefined, {maximumFractionDigits: 0})}</td>
                </tr>
            )}
            {!hideDetails && (
                <tr>
                    <td colSpan="2">
                        <a href={`/database/${safeName(itemName)}`} className="ui brand button mini fluid">Details</a>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    </div>
);

export default ItemCardRedo;
