import React, { useContext } from 'react';
import SettingsContext from "../SettingsContext";

const TabNavigation = ({ customTabs }) => {
    const { settings, updateSettings } = useContext(SettingsContext);

    const defaultTabs = [
        { id: "bastion", label: "Bastion" },
        ...(settings.showDLC ? [{ id: "volcalidus", label: "Volcalidus" }] : []),
    ];

    const tabs = customTabs || defaultTabs;

    const handleTabClick = (tabId) => {
        updateSettings({ ...settings, favouriteIsland: tabId });
    };

    return (
        <div className="ui top attached tabular menu">
            {tabs.map(tab => (
                <div key={tab.id}
                     className={`item ${settings.favouriteIsland === tab.id ? "active" : ""}`}
                     onClick={() => handleTabClick(tab.id)}>
                    {tab.label}
                </div>
            ))}
        </div>
    );
};

export default TabNavigation;