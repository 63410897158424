import React from 'react';
import {API_URL} from "../../../Utils/Config";
import Loader from "../../../Utils/Loader";
import WorldMap from "../../../Components/Map/Map";

export default class NPCGuide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            fullscreenImage: null,
            fullscreenDisplay: "none"
        }
    }

    handleChange = (event) => {
        window.location.href = `#${event.target.value}`;
    };

    handleClick = (event) => {
        if (event.target.src.includes("/thumbs")) {
            this.setState({fullscreenImage: event.target.src.replace("/thumbs", ""), fullscreenDisplay: "flex"})
        } else {
            this.setState({fullscreenImage: null, fullscreenDisplay: "none"})
        }
    };

    async componentDidMount() {
        const url = API_URL + `/api/npc/`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, loading: false})
    }

    render() {
        return (
            <>
                <div className="ui container">
                    <div className="ui breadcrumb inverted">
                        <a href="/" className="section">Bridgepour Wiki Home</a>
                        <i className="right angle icon divider"/>
                        <a href="/guide-book" className="section">Guide Book</a>
                        <i className="right angle icon divider"/>
                        <div className="active section">NPC's</div>
                    </div>

                    <div className="Page-header">
                        <h1>
                            Hydroneers Inhabitants (NPC's)
                            <div className="sub-heading">Find out where each NPC resides to easily navigate back to
                                them!</div><br/>

                            {this.state.loading || !this.state.data ?
                                (
                                    <Loader />
                                ) : (
                                    <>
                                        <div className="ui form">
                                            <div className="field">
                                                <select onChange={this.handleChange}>
                                                    {this.state.data.map(data => (
                                                        <option value={data.name}>{data.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </h1>
                    </div>

                    <div className="ui container">
                        {this.state.loading || !this.state.data ?
                            (
                                <Loader/>
                            ) : (
                                <>
                                    {this.state.data.map(npc => (
                                        <div className="ui segment" key={npc.name}>
                                            <table width="100%" id={npc.name}>
                                                <tr>
                                                    <td width="75%" style={{paddingRight: "2ch"}}>
                                                        {npc.task_giver && (
                                                            <span className="ui brand ribbon label">
                                                                <i className="checkmark icon"></i>
                                                                Task giver</span>
                                                        ) }
                                                        <h3>{npc.name}</h3>
                                                        <p>{npc.about}</p>
                                                    </td>
                                                    <td><img src={`https://cdn.bridgepour.com/images/npcs/thumbs/${npc.name.replace(/\s+/g, '-')}.png`} className="npc-mugshot" alt="NPC Mugshot" loading="lazy" onClick={this.handleClick} /></td>
                                                    {/*<td width="25%">*/}
                                                    {/*    <WorldMap coords={JSON.parse(npc.coords)} zoom={3} npcver={npc.name} />*/}
                                                    {/*</td>*/}
                                                </tr>
                                            </table>
                                        </div>
                                    ))}

                                    <div className="big-image" style={{display: this.state.fullscreenDisplay}}>
                                        <img src={this.state.fullscreenImage} alt="NPC" onClick={this.handleClick} />
                                    </div>
                                </>
                            )
                        }
                    </div>
                    <br/>
                </div>
            </>

        )
    }
}