import React, {Component} from "react";
import {DISCORD_INVITE_LINK, STEAM_WORKSHOP_LINK} from "../Utils/Config";
import PageTitle from "../Helpers/PageTitle";
import SectionTitle from "../Helpers/SectionTitle";

export default class ModFAQ extends Component {
    async componentDidMount() {
        const btnMakeModsEl = document.querySelector('#btn-make-mods');
        const MakeModsEl = document.querySelector('#make-mods');
        const btnRemoveModsEl = document.querySelector('#btn-remove-mods');
        const RemoveModsEl = document.querySelector('#remove-mods');

        if (window.location.hash) {
            document.querySelector(window.location.hash).classList.add('outline');
        }

        btnMakeModsEl.addEventListener("click", (e) => {
            MakeModsEl.classList.add('outline');
            RemoveModsEl.classList.remove('outline');
        });

        btnRemoveModsEl.addEventListener("click", (e) => {
            RemoveModsEl.classList.add('outline');
            MakeModsEl.classList.remove('outline');
        });
    }

    render() {
        return (
            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <a className="section" href="/mods">Mods</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">Mod FAQ</div>
                </div>

                <PageTitle title="Hydroneer Modding FAQ" subheading="Helpful information on how to install mods or create mods for Hydroneer." />

                <div className="ui two column doubling grid container">
                    <div className="eleven wide column">
                        <div className="ui message inverted">
                            <h3>GAME CRASHING ON LAUNCH?</h3>
                            <p>
                                If you receive a "Corrupt Data found" error, or the game fails to launch - please
                                <a href="#remove-mods"> remove all mods </a>
                                and re-download them from the <a href={STEAM_WORKSHOP_LINK}>Steam Workshop</a>. After downloading the desired mods
                                restart your game or go to the mods menu in the "main menu" navigate to "installed mods" and hit "reload mods" at the bottom.
                            </p>
                        </div>
                        <div className="ui segment" id="landing-selection">
                            <SectionTitle title="This is a modding FAQ for Hydroneer" />
                            <p>
                                If your question is not answered below, please use the #modding channel on the
                                <a href={DISCORD_INVITE_LINK} target="_blank" rel="noreferrer noopener"> Hydroneer Discord server </a> to ask your question.
                            </p>
                        </div>
                        {/* Removing Mods */}
                        <div className="ui segment" id="remove-mods">
                            <SectionTitle title="How do I remove installed mods?" />

                            {/* Legacy mods */}
                            <div className="ui segment">
                                <SectionTitle title="How do I get rid of Manually Installed (Legacy) Mods" />
                                <ol>
                                    <li>
                                        Open the Run prompt by holding the WINDOWS key and pressing R.
                                    </li>
                                    <li>
                                        Type the line below into the box (or copy/paste) then hit ENTER and delete the contents:
                                        <kbd>%localappdata%\Mining\Saved\Paks%</kbd>
                                    </li>
                                </ol>
                            </div>
                            {/* Workshop Mods */}
                            <div className="ui segment">
                                <SectionTitle title="How do I get rid of Steam Workshop Mods" />
                                <ol>
                                    <li>
                                        Open the Run prompt by holding the WINDOWS key and pressing R.
                                    </li>
                                    <li>
                                        Type the line below into the box (or copy/paste) then hit ENTER and delete the contents:
                                        <kbd>%ProgramFiles(x86)%\Steam\steamapps\workshop\content\1106840</kbd>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        {/* Making Mods */}
                        <div className="ui segment" id="make-mods">
                            <SectionTitle title="How do I make Hydroneer mods?" />
                            <p>
                                One of the newest modding groups on the block, RFC Mining Company,
                                have set up a dedicated section on their Github with extensive information on how to get started with making mods for Hydroneer.
                            </p>
                            <a href="https://github.com/RFC-MiningCompany/HydroneerTemplate#readme" className="ui brand button fluid">
                                Check it out here
                            </a>
                        </div>

                    </div>
                    {/* Sidebar right*/}
                    <div className="sidebar five wide column">
                        <a href="/mods" className="ui brand button fluid">All mods</a> <br/>
                        <a href="#remove-mods" className="ui brand button fluid" id="btn-remove-mods">Remove mods</a> <br/>
                        <a href="#make-mods" className="ui brand button fluid"  id="btn-make-mods">Make mods</a>
                    </div>
                </div>
            </div>
        )
    }
}
