import React, {createContext, useState, useEffect} from 'react';

export const defaultSettings = {
    showDLC: false,
    favouriteIsland: "bastion"
};

const SettingsContext = createContext();

export const SettingsProvider = ({children}) => {
    const [settings, setSettings] = useState(() => {
        const storedSettings = localStorage.getItem('settings');
        return storedSettings ? JSON.parse(storedSettings) : defaultSettings;
    });

    useEffect(() => {
        localStorage.setItem('settings', JSON.stringify(settings));
    }, [settings]);

    const updateSettings = (newSettings) => {
        setSettings(prev => ({...prev, ...newSettings}));
    };

    return (
        <SettingsContext.Provider value={{settings, updateSettings}}>
            {children}
        </SettingsContext.Provider>
    );
};

export default SettingsContext;
