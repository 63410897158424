import React, {useContext, useEffect, useState} from 'react';
import {API_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import parse from 'html-react-parser';
import SettingsContext from "../SettingsContext";
import PageTitle from "../Helpers/PageTitle";


const PatchNotes = () => {
    const {settings, updateSettings} = useContext(SettingsContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [currentTab, setCurrentTab] = useState(settings.favouriteIsland);


    useEffect(() => {
        const fetchPatchNotes = async () => {
            const url = API_URL + "/api/patchnotes/";
            try {
                const response = await fetch(url);
                const jsonData = await response.json();
                setData(jsonData.results);
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch patch notes:", error);
                setLoading(false);
            }
        };

        fetchPatchNotes();
    }, []);

    useEffect(() => {
        setCurrentTab(settings.favouriteIsland);
    }, [settings.favouriteIsland]);


    return (
        <>

            <PageTitle title={`Hydroneer Patch Notes`} imageClass={settings.favouriteIsland} />

            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">Official Patch Notes</div>
                </div>

                <div className={`ui bottom attached tab segment active`}>
                    {loading || !data ? (
                        <Loader/>
                    ) : (
                        <>
                            {data.map((data, index) => (
                                <details className="ui segment" key={data.summary} open={0 === index}>
                                    <summary>
                                        {data.summary}
                                    </summary>
                                    <>
                                        {parse(data.details)}
                                    </>
                                </details>
                            ))}
                        </>
                    )
                    }
                </div>
            </div>
        </>
    )
}

export default PatchNotes;