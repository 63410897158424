import React, {Component} from "react";

export default class ModderCard extends Component {
    render() {
        return (
            <div className="ui labeled brand button modderLinks" tabIndex="0">
                <div className="ui basic label">
                    {this.props.name}
                </div>
                {this.props.git !== "" && (
                    <a className="ui button icon" href={this.props.git} target="_blank" rel="noopener noreferrer">
                        <i className="github icon"></i>
                    </a>
                )}
                {this.props.workshop !== "" && (
                    <a className="ui button icon" href={this.props.workshop} target="_blank" rel="noopener noreferrer">
                        <i className="steam icon"></i>
                    </a>
                )}
            </div>
        )
    }
}
