import React from 'react';
import ItemCardRedo from "./ItemCardRedo";
import {safeName} from "../../Utils/Utils";
import {API_URL, CDN_URL} from "../../Utils/Config";
import Loader from "../../Utils/Loader";

export default class ItemTooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
            item_lookup: props.item_lookup,
            loading: true,
            isShown: false
        }
    }

    addDefaultSrc = (e) => (
        e.target.src = CDN_URL + `/images/items/no_image.png`
    )

    //todo - fix up this lookup / showing the help icon in the logic build page
    async componentDidMount() {
        if (this.state.item_lookup !== undefined) {
            const url = API_URL + `/api/items/exact/${this.state.item_lookup}/`;
            const response = await fetch(url);
            const data = await response.json();
            this.setState({item: data.results[0], loading: false})
        } else {
            this.setState({loading: false})
        }
    }

    render() {
        return (
            <>
                {this.state.loading || !this.state.item ?
                    (
                        <Loader/>
                    ) : (
                        <>
                            <span className="tooltip-container">
                                {this.state.item && (
                                    <span className="item">
                                    <a href={"/database/" + safeName(this.state.item.name)}
                                       onMouseEnter={() => this.setState({isShown: true})}
                                       onMouseLeave={() => this.setState({isShown: false})}>
                                           {this.props.showIcon && (
                                               <>
                                                   <img onError={this.addDefaultSrc}
                                                        src={CDN_URL + "/images/items/" + safeName(this.state.item.name) + ".png"}
                                                        alt={this.state.item.name}
                                                        width="16px"
                                                        loading="lazy"
                                                   /> &nbsp;
                                               </>
                                           )}

                                        {this.state.item.name}
                                        {this.props.showValue && (
                                            <>
                                                &nbsp;({this.state.item.value})
                                            </>
                                        )}
                                    </a>
                                        {this.state.isShown && (
                                            <div className="tooltip"
                                                 onMouseEnter={() => this.setState({isShown: true})}
                                                 onMouseLeave={() => this.setState({isShown: false})}>
                                                <ItemCardRedo item={this.state.item} hide_details no_link horizontal />
                                            </div>
                                        )}
                                    </span>
                                )}
                            </span>
                        </>
                    )
                }
            </>
        )
    }
}