import React, {useState, useEffect} from 'react';
import {API_URL} from "../../Utils/Config";
import Loader from "../../Utils/Loader";
import {useParams} from "react-router-dom";
import parse from "html-react-parser";
import PageTitle from "../../Helpers/PageTitle";
import {parseHTMLString, safeName} from "../../Utils/Utils";

export default function GuideView() {
    const {guide_id} = useParams();
    const {guide_page_number} = useParams();
    const [guide, setGuide] = useState([]);
    const [loading, setLoading] = useState(true);
    const [highlighted, setHighlighted] = useState("")

    useEffect(() => {
        async function fetchGuides() {
            try {
                const response = await fetch(`${API_URL}/api/guides/exact/${guide_id}`);
                const data = await response.json();
                setGuide(data.results[0]);
            } catch (error) {
                console.error('Failed to fetch guides:', error);
            }
            setLoading(false);
        }
        fetchGuides();

        const decodeHash = (hash) => decodeURIComponent(hash.replace('#', ''));

        const highlightFromHash = () => {
            const hash = window.location.hash;
            if (hash) {
                setHighlighted(decodeHash(hash));
            }
        };

        highlightFromHash();
        window.addEventListener('hashchange', highlightFromHash, false);

        return () => {
            window.removeEventListener('hashchange', highlightFromHash, false);
        };
    }, []);


    return (
        <>
            <PageTitle title={guide.title} subheading={guide.summary} imageURL={guide.imagePath} />

            <div className="ui container">
                {loading ? (
                    <Loader/>
                ) : (
                    <>
                        <div className="ui breadcrumb inverted">
                            <a href="/" className="section">Bridgepour Wiki Home</a>
                            <i className="right angle icon divider"/>
                            <a href="/guide-book/" className="section">Guide Book</a>
                            <i className="right angle icon divider"/>
                            <div className="active section">{guide.title}</div>
                        </div>
                        {guide.pages.filter(page => page.order === parseInt(guide_page_number)).map(page => (
                            <>
                                {page.sections.length > 0 ? (
                                    <>
                                        <div className="ui two column desktop stackable grid">
                                            <div className="four wide column">
                                                <div className="ui segment stickytop">
                                                    <h3>Guide sections</h3>
                                                    <div className="ui link list">
                                                        {guide_page_number > 1 && (
                                                            <a className="item" href={`/guide-book/guide/${guide.id}/${safeName(guide.title)}/${parseInt(guide_page_number)-1}`}>
                                                                <i className="icon left angle"/> {guide.pages.filter(page => page.order === (parseInt(guide_page_number) - 1))[0].title}
                                                            </a>
                                                        )}

                                                        {page.sections.map((section, index) => (
                                                            <a key={index} className={"item " + (highlighted === section.title ? ' highlighted' : '')} href={`#${section.title}`} onClick={() => setHighlighted(highlighted => highlighted === section.title ? "" : section.title)}>{index+1}. {section.title}</a>
                                                        ))}

                                                        {guide_page_number < guide.pages.length && (
                                                            <a className="item" href={`/guide-book/guide/${guide.id}/${safeName(guide.title)}/${parseInt(guide_page_number)+1}`}>
                                                                {guide.pages.filter(page => page.order === (parseInt(guide_page_number)+1))[0].title} <i className="icon right angle"/>
                                                            </a>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="twelve wide column">
                                                {page.sections.map(section => (
                                                    <>
                                                        {section.collapsible ? (
                                                            <details className={"ui segment" + (highlighted === section.title ? ' highlighted' : '')} key={section.id} open={highlighted === section.title}>
                                                                {section.title && (<summary>{section.title}</summary>)}
                                                                {parseHTMLString(section.content)}
                                                            </details>
                                                        ) : (
                                                            <div className={"ui segment" + (highlighted === section.title ? ' highlighted' : '')} key={section.id} id={section.title}>
                                                                {section.title && (<h3>{section.title}</h3>)}
                                                                {parseHTMLString(section.content)}
                                                            </div>
                                                        )}
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="ui message error">
                                        No sections found<br/>
                                        <small>Well that's awkward - this shouldn't happen. Please let @ItchyBeard know on Discord.</small>
                                    </div>
                                )}
                            </>
                        ))}


                        <div className="ui three columns stackable grid">
                            <div className="column">
                                {guide_page_number > 1 && (
                                    <a className="ui brand button left floated" href={`/guide-book/guide/${guide.id}/${safeName(guide.title)}/${parseInt(guide_page_number)-1}`}>
                                        <i className="icon left angle"/> {guide.pages.filter(page => page.order === (parseInt(guide_page_number) - 1))[0].title}
                                    </a>
                                )}
                            </div>

                            <div className="column">
                                <center>
                                    {guide.footerText && (guide.footerText)}
                                </center>
                            </div>

                            <div className="column">
                                {guide_page_number < guide.pages.length && (
                                    <a className="ui brand button right floated" href={`/guide-book/guide/${guide.id}/${safeName(guide.title)}/${parseInt(guide_page_number)+1}`}>
                                        {guide.pages.filter(page => page.order === (parseInt(guide_page_number)+1))[0].title} <i className="icon right angle"/>
                                    </a>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}