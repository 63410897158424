import React from 'react';
import {API_URL, CDN_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import PageTitle from "../Helpers/PageTitle";
import {safeName} from "../Utils/Utils";
import parse from "html-react-parser";

const RepeatItem = (n) => {
    let items = []
    for (let i = 0; i < n; ++i) {
        items.push(`<div className="item" id=${i} onDrop={this.handleDrop} onDragOver={this.allowDrop}>&nbsp;</div>`)
    }

    return (<>{parse(items.join(''))}</>)
}

export default class LogicBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            heldItem: null,
            grid: {
                columns: 6,
                rows: 6,
                cells: [
                    {
                        row: 0,
                        column: 0,
                        item: "logic-straight-pipe",
                        rotation: 1,
                        allowPassThrough: true,
                        hook: "logic-diode"
                    }, {
                        row: 0,
                        column: 1,
                        item: "logic-straight-pipe",
                        rotation: 1,
                        allowPassThrough: true,
                        hook: null
                    }
                ]
            }
        }
        this.increaseColumn = this.increaseColumn.bind(this);
        this.decreaseColumn = this.decreaseColumn.bind(this);
        this.increaseRow = this.increaseRow.bind(this);
        this.decreaseRow = this.decreaseRow.bind(this);

        this.handleDrag = this.handleDrag.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.allowDrop = this.allowDrop.bind(this);
    }

    async componentDidMount() {
        const url = API_URL + "/api/groups/Logic/";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results[0].items, loading: false})
    }

    increaseColumn() {
        let modified_grid = this.state.grid;
        modified_grid.columns = Math.min(12, this.state.grid.columns + 1);
        return this.setState({grid: modified_grid});
    }

    decreaseColumn() {
        let modified_grid = this.state.grid;
        modified_grid.columns = Math.max(1, this.state.grid.columns - 1);
        return this.setState({grid: modified_grid});
    }

    increaseRow() {
        let modified_grid = this.state.grid;
        modified_grid.rows = Math.min(16, this.state.grid.rows + 1);
        return this.setState({grid: modified_grid});
    }

    decreaseRow() {
        let modified_grid = this.state.grid;
        modified_grid.rows = Math.max(1, this.state.grid.rows - 1);
        return this.setState({grid: modified_grid});
    }

    handleDrag(event) {
        var clickedId = event.target;
        if (clickedId.className === "item") {
            console.log("yes")
            this.setState({heldItem: clickedId.dataset.item})
        }
    }

    handleDrop(event) {
        console.log(event.target)
        var clickedId = event.target;
        if (clickedId.className === "item") {
            console.log("yes")
            this.setState({heldItem: clickedId.dataset.item})
        }
    }

    allowDrop(event) {
        console.log(event.target)
        event.preventDefault();
    }

    render() {
        return (
            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <div className="active section"><i className="bug icon" /> Logic Builder</div>
                </div>

                <PageTitle title="Logic Builder" />

                <div className="Logic-builder">
                    <div className="Logic-items">
                        <p><span onClick={this.decreaseColumn}>-COL</span> &emsp; <span onClick={this.increaseColumn}>+COL</span></p>
                        <p><span onClick={this.decreaseRow}>-ROW</span> &emsp; <span onClick={this.increaseRow}>+ROW</span></p>

                        {this.state.loading || !this.state.data ?
                            (
                                <Loader/>
                            ) : (
                                <>
                                    {this.state.data.map(item => (
                                        <>
                                            <div className="item" draggable="true" onDragStart={this.handleDrag} data-item={JSON.stringify(item)}>
                                                {item.name}

                                                <img onError={this.addDefaultSrc}
                                                     src={CDN_URL + "/images/items/thumbs/" + safeName(item.name) + ".png"}
                                                     alt={item.name}
                                                     className="ui avatar image"
                                                     loading="lazy"
                                                />
                                            </div>
                                        </>
                                    ))}
                                </>
                            )
                        }
                    </div>
                    <div className="Logic-grid" style={{gridTemplateColumns: `repeat(${this.state.grid.columns}, 64px)`, gridTemplateRows: `repeat(${this.state.grid.rows}, 64px)` }}>
                        {RepeatItem(this.state.grid.columns * this.state.grid.rows)}
                    </div>
                </div>
            </div>
        )
    }
}