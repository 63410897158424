import React, {useContext, useState} from 'react';
import ItemTooltip from "./ItemTooltip";
import Loader from "../../Utils/Loader";
import {API_URL} from "../../Utils/Config";
import {safeName} from "../../Utils/Utils";
import SectionTitle from "../../Helpers/SectionTitle";
import Cookies from "js-cookie";
import AliceCarousel from "react-alice-carousel";
import InventoryGrid from "./InventoryGrid";
import TabNavigation from "../../Layouts/TabNavigation";
import SettingsContext from "../../SettingsContext";

export class ItemDrillsInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item
        }
    }

    render() {
        return (<>
            <details className="item-faq ui segment">
                <summary className="title">
                    <SectionTitle title={`How does the ${this.state.item.name} work?`}/>
                </summary>
                <div className="item-faq-content">
                    <p>Drills make things easier for the Hydroneer by automating the process of producing dirt
                        chunks.</p>
                    <p>Your first Tier 1 Drill can be purchased from the <a
                        href="/guide-book/towns/bastion-keep/travelling-merchant/19" target="_blank">Traveling
                        Merchant</a> near Ember Cradle for 432 Hydrocoins. Tier 2 Drills and additional Tier 1 Drills
                        need
                        to be crafted at Icehelm Forge using resources you’ve mined and poured into bars.</p>
                    <p>When placing a Drill it can be rotated with the <kbd>R</kbd> key. Remember, Drills need water, so
                        keep both the input and output in mind when placing!</p>
                    <p>Several factors can affect drill performance. These are; dirt tier, depth, water quality and
                        water
                        pressure.</p>
                    <p>The tier of dirt limits what kind of drill you are able to place on it. Tier 1 equipment is not
                        able
                        to work with Tier 2 dirt, while Tier 2 equipment is able to work with both Tier 1 and Tier
                        2.</p>
                    <p>Depth has a large effect on the amount of resources you get from panning or harvesting the dirt.
                        Remember, the deeper you dig, the better the resources - yes, that includes where you place your
                        machines too!</p>
                    <p>Poor water quality causes drills to take damage and eventually break down. A broken Drill will
                        stop
                        working and begin to emit sparks. You will need to repair it with a spanner to get it working
                        again.
                        Provide your drills with fully filtered water whenever possible.</p>
                    <p>Higher water pressure makes drills work faster. You can use Pressure Tanks on long pipelines to
                        bring
                        your drills back up to speed, just don’t forget to add Shard or Shard Bars!</p>
                </div>
            </details>
        </>)
    };
}

export class ItemHarvestersInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item
        }
    }

    render() {
        return (<>
            <details className="item-faq ui segment">
                <summary className="title">
                    <SectionTitle title={`How does the ${this.state.item.name} work?`}/>
                </summary>
                <div className="item-faq-content">
                    <p>A substantial upgrade to your mining operation is the Harvester. These machines do the work of
                        your
                        pan and brush automatically. You just need to provide them with dirt and water.</p>
                    <p>Your first Tier 1 Harvester can be purchased from the <a
                        href="/guide-book/towns/bastion-keep/travelling-merchant/19" target="_blank">Traveling
                        Merchant</a> near Ember Cradle for 320 Hydrocoins. Tier 2 Harvesters and additional Tier 1
                        Harvesters need to be crafted at Icehelm Forge using resources you’ve mined and poured into
                        bars.
                    </p>
                    <p>A single Harvester is able to process the dirt from many Drills.</p>
                    <p>Harvesters are limited to processing dirt of their Tier or lower. This means that a Tier 2
                        Harvester
                        is able to process both Tier 1 and Tier 2, while a Tier 1 Harvester is limited to Tier 1.</p>
                    <p>When placing a Harvester it can be rotated with the <kbd>R</kbd> key. Remember, Harvesters need
                        water, so keep both the input and output in mind when placing!</p>
                    <p>You should provide your Harvesters with filtered water whenever possible. They will work without
                        filters, but they will take damage and eventually break, read more on this below.</p>
                </div>
            </details>
        </>)
    };
}

export class ItemBuildItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item
        }
    }

    render() {
        return (
            <>
                <details className="item-faq ui segment">
                    <summary className="title">
                        <SectionTitle title={`${this.state.item.name} is a Build Item.`}
                                      iconURL="https://cdn.bridgepour.com/images/badges/BuildItem.png"/>
                    </summary>
                    <div className="item-faq-content">
                        <p>To place this item, look at it, pick it up using the <kbd>E</kbd> key. Now, <kbd>Left
                            click</kbd> to enter "Place Mode" - looking around, you will see the item turn BLUE if it
                            can be
                            placed, if it turns RED, it cannot be placed there.</p>
                        <p>Some items are able to be placed "in the air" whilst others have to be placed on a foundation
                            of
                            some kinds. In the case of Conveyors - these must be placed ON a water pipe.</p>
                        <p>Most items can be rotated on all axes, using
                            the <kbd>R</kbd>, <kbd>T</kbd> and <kbd>Y</kbd> keys.</p>
                        <p>When you are happy with where the item should be placed, simply <kbd>Left click</kbd> again.
                            If
                            you change your mind, <kbd>Right click</kbd> and the item will come back to you.</p>
                        <p>Whilst holding an item, you can hold the <kbd>E</kbd> key to enter "Precision Place Mode" -
                            where
                            the item floats out in front of you. Again,
                            the <kbd>R</kbd>, <kbd>T</kbd> and <kbd>Y</kbd> keys
                            can be used to rotate the item - then press the <kbd>E</kbd> key again to drop the item
                            where it
                            is.</p>
                    </div>
                </details>
            </>)
    };
}

export class ItemTakesDamage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item
        }
    }

    render() {
        return (<>
            <details className="item-faq ui segment">
                <summary className="title">
                    <SectionTitle title={`${this.state.item.name} can be damaged.`}
                                  iconURL="https://cdn.bridgepour.com/images/badges/TakesDamage.png"/>
                </summary>
                <div className="item-faq-content">
                    <p>This machine will take damage over time - visually, when the item reaches 0 durability,
                        you will notice sparks coming out of the front / side of the machine - along with it
                        functionally stop working.</p>
                    <p>To tell the durability of your machine, you can use a <ItemTooltip
                        item_lookup="Magnifying Glass"/>. Pick it up using the <kbd>E</kbd> key then simply aim
                        at your machine, a number will appear on the Magnifying Glass showing the durability
                        (0-100%).</p>
                    <p>The easiest way to repair your machine is to use a <ItemTooltip
                        item_lookup="Spanner"/> (single use) or a <ItemTooltip item_lookup="Tool Bag"/> (5
                        uses). Simply pick up the tool of choice using the <kbd>E</kbd> key - then look at
                        the machine, then simply <kbd>Left click</kbd> to repair the item to full
                        durability.</p>
                    <p>The more advanced way to repair your machine is to set up an "Auto repair" logic system
                        (Logic guide coming soon!).</p>
                </div>
            </details>
        </>)
    };
}

export class ItemRequiresWater extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item
        }
    }

    render() {
        return (<>
            <details className="item-faq ui segment">
                <summary className="title">
                    <SectionTitle title={`${this.state.item.name} is a Water Item.`}
                                  iconURL="https://cdn.bridgepour.com/images/badges/Water Item.png"/>
                </summary>
                <div className="item-faq-content">
                    <p>As the game name suggests, Hydroneer is all about water.</p>
                    <h3>Water Quality</h3>
                    <p>If a machine is provided unfiltered water, the machine will take damage. If the machine reaches 0
                        durability, it will break. See table below for hooks that can prevent machines from taking
                        damage.</p>
                    <table className="ui table basic inverted striped unstackable">
                        <tr>
                            <td><ItemTooltip item_lookup="water-filter-hook" showIcon/><br/><span className="muted">You'll need FIVE of these to get 100%</span>
                            </td>
                            <td>Increases water quality by 20%</td>
                        </tr>
                        <tr>
                            <td><ItemTooltip item_lookup="invincible-water-filter-hook" showIcon/><br/><span
                                className="muted">Can only be obtained in Creative Mode</span></td>
                            <td>Increases water quality by 100%</td>
                        </tr>
                    </table>
                    <h3>Water Pressure</h3>
                    <p>Each pipe in your system will decrement pressure, see table below for decrement %.</p>
                    <p>Machines and Conveyor Belts will run slower when provided lower pressure.</p>
                    <table className="ui table basic inverted striped unstackable">
                        <tr>
                            <td><ItemTooltip item_lookup="forged-iron-hydro-pipe" showIcon/></td>
                            <td>Decreases water pressure by 3%.</td>
                        </tr>
                        <tr>
                            <td><ItemTooltip item_lookup="cloutium-hydro-pipe" showIcon/></td>
                            <td>Decreases water pressure by 2.5%.</td>
                        </tr>
                        <tr>
                            <td><ItemTooltip item_lookup="corestone-hydro-pipe" showIcon/></td>
                            <td>Decreases water pressure by 2%.</td>
                        </tr>
                    </table>
                    <h3>How do I increase Water Pressure?</h3>
                    <p>To increase base pressure in your pipe network, you can use Intake Booster Hooks.</p>
                    <table className="ui table basic inverted striped unstackable">
                        <tr>
                            <td><ItemTooltip item_lookup="forged-iron-intake-booster-hook" showIcon/></td>
                            <td>Increases base intake pipe pressure by 20%
                                <br/><span className="muted">Up to a maximum total of 180% (including the intake base pressure)</span>
                            </td>
                        </tr>
                        <tr>
                            <td><ItemTooltip item_lookup="cloutium-intake-booster-hook" showIcon/></td>
                            <td>Increases base intake pipe pressure by 30%
                                <br/><span className="muted">Up to a maximum total of 270% (including the intake base pressure)</span>
                            </td>
                        </tr>
                        <tr>
                            <td><ItemTooltip item_lookup="corestone-intake-booster-hook" showIcon/></td>
                            <td>Increases base intake pipe pressure by 40%
                                <br/><span className="muted">Up to a maximum total of 510% (including the intake base pressure)</span>
                            </td>
                        </tr>
                    </table>
                    <p>Closer to your machines, you'll notice the pressure has dropped, to get pressure back up to the
                        base value, you can use Pressure Tanks.</p>
                    <table className="ui table basic inverted striped unstackable">
                        <tr>
                            <td><ItemTooltip item_lookup="forged-iron-pressure-tank" showIcon/></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><ItemTooltip item_lookup="cloutium-pressure-tank" showIcon/></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><ItemTooltip item_lookup="corestone-pressure-tank" showIcon/></td>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </details>
        </>)
    };
}

export class ItemRequiresLogic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item
        }
    }

    render() {
        return (
            <details className="item-faq ui segment">
                <summary className="title">
                    <SectionTitle title={`${this.state.item.name} is a Logic Item.`}
                                  iconURL="https://cdn.bridgepour.com/images/badges/Logic.png"/>
                </summary>
                <div className="item-faq-content">
                    <p>Logic in Hydroneer is a great way to automate repairs or pouring bars. Far too much information
                        to display in this little box.</p>
                    <p>We will be adding logic guides to the wiki soon, but in the mean time, if you do want to learn
                        more head over to the <a href="https://discord.gg/Hydroneer" target="_blank"
                                                 rel="noopener noreferrer">Discord
                            server</a> to get help.</p>
                </div>
            </details>
        )
    };
}

export class ItemHooks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item
        }
    }

    render() {
        return (
            <>
                <details className="item-faq ui segment">
                    <summary className="title">
                        <SectionTitle title={`What are Hooks and how are they used?`}
                                      iconURL="https://cdn.bridgepour.com/images/badges/Hook.png"/>
                    </summary>
                    <div className="item-faq-content">
                        <p>Hooks are items that attach (hook) on to another item. The benefit of the Hook system, is
                            that
                            players are free to place items where they want (or need) to, in their systems. At the time
                            of
                            writing (2.0) - there are four types of hook.</p>
                        <p>The <strong>Water Pipe Hooks</strong> can be hooked on to any tier of Straight, or "T" Water
                            Pipe.
                            These can range from a simple Valve to stop water flow, to a Water Filter.</p>
                        <p>The <strong>Intake Pipe Booster Hooks</strong> can be hooked on to any tier of Intake Water
                            Pipe
                            and will
                            boost the base pressure in the system by a set amount each.</p>
                        <p>The <strong>Logic Cable Hooks</strong> can be hooked on to a Straight, or "T" Logic Cable.
                            These
                            are
                            a bit more advanced than the Water Pipe Hooks as the Logic hooks alter the signal passing
                            through.
                            These range from a simple "Logic Reader" which displays the value passing through, to Logic
                            Flip-Flops or a Diode.</p>
                        <p>The <strong>Conveyor Hooks</strong> can be hooked on to any Straight Conveyor. These hooks
                            will
                            alter
                            the resources travelling along your belts. Ranging from a Centraliser to.. center.. your
                            resources,
                            to a Gem Polisher which will automatically cut and shine your gems.</p>
                    </div>
                </details>
            </>
        )
    };
}

export const ItemStores = ({item}) => {
    const [itemState] = useState(item);
    const {settings} = useContext(SettingsContext);

    const renderHeaderContent = () => {
        if (itemState.item_type === "Resource Item") {
            return `Where to sell ${itemState.name}`;
        }

        if (itemState.group.name === "Fishing Pole" && itemState.name === "My First Fishing Pole") {
            return `Where to buy ${itemState.name}`;
        }

        return itemState.group.name === "Fishing Pole" ? `Where to acquire ${itemState.name}` : `Where to buy ${itemState.name}`;
    };

    // Helper function to determine additional message based on badges
    const renderCreativeModeMessage = () => {
        return itemState.badges.includes("Creative") && (
            <p className="ui message inverted">
                This item is only available to purchase when in Creative Mode!
            </p>
        );
    };

    return (
        <>
            {itemState.stocked.length > 0 && (
                <div className="ui segment">
                    <h3 className="ui header">
                        <div className="content">
                            {renderHeaderContent()}
                        </div>
                    </h3>
                    <p>{itemState.item_type === "Resource Item" ? `${itemState.name} can be sold in the following places:` : `${itemState.name} can be bought in the following places:`}</p>
                    {renderCreativeModeMessage()}
                    <div className="Town-list">
                        <TabNavigation/>
                        <div className="ui bottom attached tab segment active">
                            {itemState.stocked.filter(x => x.gameVersion ? x.gameVersion.includes(settings.favouriteIsland) : 'bastion').length === 0 ? (
                                <p>This item is not available
                                    for {itemState.item_type === "Resource Item" ? `sale` : `purchase`} on this
                                    island.</p>
                            ) : (
                                <>
                                    {itemState.stocked.filter(town => town.gameVersion ? town.gameVersion.includes(settings.favouriteIsland) : 'bastion').map(town => (
                                        <div key={"town" + town.town_id}
                                             className={"ui segment " + town.town_safe_name}>
                                            <h3>{town.name}</h3>
                                            <div className="button-grid">
                                                {town.stores.map(shop => (
                                                    <a href={`/guide-book/towns/${town.town_safe_name}/${shop.safe_name}/${shop.id}`}
                                                       className="column four wide ui brand button"
                                                       key={"shop" + shop.shop_id}>
                                                        {shop.name}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};


export class ItemCreativeInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item
        }
    }

    render() {
        return (<>
            <details className="item-faq ui segment">
                <summary className="title">
                    <SectionTitle title={`${this.state.item.name} is a Creative Tool.`}
                                  iconURL="https://cdn.bridgepour.com/images/badges/Creative.png"/>
                </summary>
                <div className="item-faq-content">
                    <p>Something something, this item is used in Creative Mode.</p>
                    <p>Learn about Creative Tools in our handy one page Creative Mode guide</p>
                    <a href="/guide-book/guide/creative-mode" className="ui button brand">View Creative Mode guide</a>
                </div>
            </details>
        </>)
    };
}

export class ItemHatInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item
        }
    }

    render() {
        return (<>
            <details className="item-faq ui segment">
                <summary className="title">
                    <SectionTitle title={`${this.state.item.name} can be worn on the players' Head.`}
                                  iconURL="https://cdn.bridgepour.com/images/badges/Hat.png"/>
                </summary>
                <div className="item-faq-content">
                    <p>The {this.state.item.name} can be worn on the players head.</p>
                    <p>To put it on your head, leave it on the floor, aim your cursor at it. Instead of picking it up,
                        press <kbd>Q</kbd> instead, this will equip the hat.</p>
                    <p>To take it off of your head, press the <kbd>Q</kbd> key again.</p>
                    {this.state.item.name === 'Flight Cap' && (
                        <>
                            <p>When worn, the Flight Cap will allow the player to fly.</p>
                            <p><kbd>Space bar</kbd>: Fly up</p>
                            <p><kbd>C</kbd>: Fly down</p>
                        </>
                    )}
                    {this.state.item.name === 'Mining Helmet' && (
                        <>
                            <p>When worn, the Mining Helmet will allow the player to see easier when underground or at
                                night time, but only if it's turned on.</p>
                            <p><kbd>Left mouse</kbd>: Turn the head lamp on / off</p>
                        </>
                    )}
                    {this.state.item.name === 'Hard Hat' && (
                        <>
                            <p>When worn, the Hard Hat will allow the player to build items from their hand, infinitely.
                                To stop the item being "infinite" take the hat off using the <kbd>Q</kbd> key.</p>
                        </>
                    )}
                </div>
            </details>
        </>)
    };
}

export class ItemResourceCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            base_weight: 1,
            base_value: 1,
            weight: 1,
            value: 1,
            item: props.item,
            loading: true,
            data: null
        }
    }

    async componentDidMount() {
        const url = API_URL + `/api/resources/${safeName(this.state.item.name)}/`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({
            data: data.results[0],
            base_value: data.results[0].value,
            value: data.results[0].value,
            loading: false
        })
    }

    apply_weight = (e) => {
        this.setState({
            weight: e.target.value,
            value: (this.state.base_value * e.target.value).toFixed(2)
        })
    }

    apply_value = (e) => {
        this.setState({
            value: e.target.value,
            weight: (e.target.value / this.state.base_value).toFixed(2)
        })
    }

    render() {
        return (
            <>
                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <div className="ui segment">
                            <h3 className="ui header">
                                <img className="ui" src="https://cdn.bridgepour.com/images/badges/Valuable.png"
                                     alt="Valuable" name="Valuable"/>
                                <div className="content">
                                    Quickly calculate <strong>weight:value</strong>
                                </div>
                            </h3>
                            <form className="ui form">
                                <div className="field">
                                    <div className="two fields">
                                        <div className="field">
                                            <label>
                                                <i className="dumbbell icon"/> Weight
                                            </label>
                                            <input type="number" value={this.state.weight} id="weight"
                                                   onChange={this.apply_weight}/>
                                        </div>
                                        <div className="field">
                                            <label>
                                                <i className="coins icon"/> Value
                                            </label>
                                            <input type="number" value={this.state.value} id="value"
                                                   onChange={this.apply_value}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
            </>
        )
    };
}

export class SoupCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: null
        }
    }


    render() {
        return (
            <>
                <form className="ui form">
                    <div className="ui segment">
                        <SectionTitle title="Quickly calculate the price you'll get from making a certain soup!"
                                      iconURL="https://cdn.bridgepour.com/images/badges/Farming.png"/>
                        <div className="three fields">
                            <div className="field">
                                <select>
                                    <option value="">Not selected</option>
                                    <option value="0">Cut Tomato</option>
                                    <option value="1">Cut Onion</option>
                                    <option value="2">Cut Broccoli</option>
                                    <option value="3">Cut Beetroot</option>
                                    <option value="4">Cut Carrot</option>
                                    <option value="5">Cut Corn</option>
                                </select>
                            </div>
                            <div className="field">
                                <select>
                                    <option value="">Not selected</option>
                                    <option value="0">Cut Tomato</option>
                                    <option value="1">Cut Onion</option>
                                    <option value="2">Cut Broccoli</option>
                                    <option value="3">Cut Beetroot</option>
                                    <option value="4">Cut Carrot</option>
                                    <option value="5">Cut Corn</option>
                                </select>
                            </div>
                            <div className="field">
                                <select>
                                    <option value="">Not selected</option>
                                    <option value="0">Cut Tomato</option>
                                    <option value="1">Cut Onion</option>
                                    <option value="2">Cut Broccoli</option>
                                    <option value="3">Cut Beetroot</option>
                                    <option value="4">Cut Carrot</option>
                                    <option value="5">Cut Corn</option>
                                </select>
                            </div>
                        </div>
                        <div className="three fields">
                            <div className="field">
                                <input type="number" id="weight"/>
                            </div>
                            <div className="field">
                                <input type="number" id="weight"/>
                            </div>
                            <div className="field">
                                <input type="number" id="weight"/>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        )
    };
}

export class PageNeedsInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
            item_interest: "items" + Cookies.get("item_interest")
        }
    }

    async requestInformation() {
        const url = API_URL + "/api/interest/" + this.state.item.safe_name;
        const response = await fetch(url);
        let data = await response.json();

        this.setState({
            item_interest: [Cookies.get("item_interest"), this.state.item.safe_name].join("|")
        })

        Cookies.set("item_interest", [Cookies.get("item_interest"), this.state.item.safe_name].join("|"), {
            expires: 7,
        });
    }

    render() {
        return (<>
            <details className="item-faq ui segment">
                <summary className="title">
                    <SectionTitle title={`${this.state.item.name} needs you!`}
                                  iconURL="https://cdn.bridgepour.com/images/items/thumbs/no_image.png"/>
                </summary>
                <div className="item-faq-content">
                    <p>As you can imagine, adding bespoke information to over 300 in-game items alone is quite the
                        challenge. This is where you come in!</p>
                    <p>We have two options, either;</p>
                    {this.state.item_interest.includes(this.state.item.safe_name) ? (
                        <>
                            <span className="ui button brand center disabled"><i className="ui icon question"/> Request information</span>
                            <div className="ui message inverted">
                                Thanks for requesting information on {this.state.item.name} - we've received your
                                request and will be looking to add it soon.
                            </div>
                        </>
                    ) : (
                        <>
                            <span className="ui button brand center" onClick={() => this.requestInformation()}><i
                                className="ui icon question"/> Request information</span>
                            <p><strong>Request information</strong>: This will ping a message off to ItchyBeard or the
                                Wiki Contributors and let them know you are interested in information for this item
                                being added.</p>
                        </>
                    )}
                    <br/>
                    <a href={"https://docs.google.com/forms/d/e/1FAIpQLSeNx8sXGZ295VTJQ2CkFtf4HxL5210cOQ5QfB3OvRj_IfqcUA/viewform?usp=pp_url&entry.944418253=" + this.state.item.safe_name}
                       target="blank" rel="noopener noreferer" className="ui button brand center"><i
                        className="ui icon exclamation"/> Submit information</a>
                    <p><strong>Submit information</strong>: If you'd like to submit some information to be displayed on
                        this page, please follow the submit information link to submit it.</p>
                    <br/>
                    <div className="ui message inverted">
                        Note: this doesn't guarantee the information will be added, it may not meet the writing
                        standards of the wiki. Any submitted text may be edited by ItchyBeard or the Wiki Contributors
                        to be more in line with the other pages.
                        <br/>
                        Submitting, or requesting information, just lets us know you're interested in having information
                        on this specific page.
                    </div>
                </div>
            </details>
        </>)
    };
}

export class PoleRequirementTable extends React.Component {
    state = {
        current_item: this.props.current_item || ""
    }

    render() {
        return (
            <>
                <table className="ui table basic inverted striped unstackable">
                    <thead>
                    <tr>
                        <th>Pole</th>
                        <th>Required Catch</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className={this.state.current_item === "my-first-fishing-pole" && 'highlight'}>
                        <td><ItemTooltip item_lookup="my-first-fishing-pole" showIcon/></td>
                        <td>Purchase from stores.</td>
                    </tr>
                    <tr className={this.state.current_item === "wonky-fishing-pole" && 'highlight'}>
                        <td><ItemTooltip item_lookup="wonky-fishing-pole" showIcon/></td>
                        <td><ItemTooltip item_lookup="prawn" showIcon/></td>
                    </tr>
                    <tr className={this.state.current_item === "ol-slippy-grip-fishing-pole" && 'highlight'}>
                        <td><ItemTooltip item_lookup="ol-slippy-grip-fishing-pole" showIcon/></td>
                        <td><ItemTooltip item_lookup="pufferfish" showIcon/></td>
                    </tr>
                    <tr className={this.state.current_item === "the-plaice-holder-fishing-pole" && 'highlight'}>
                        <td><ItemTooltip item_lookup="the-plaice-holder-fishing-pole" showIcon/></td>
                        <td><ItemTooltip item_lookup="crab" showIcon/></td>
                    </tr>
                    <tr className={this.state.current_item === "emerald-fishing-pole" && 'highlight'}>
                        <td><ItemTooltip item_lookup="emerald-fishing-pole" showIcon/></td>
                        <td><ItemTooltip item_lookup="swordfish" showIcon/></td>
                    </tr>
                    <tr className={this.state.current_item === "a-reel-dream-fishing-pole" && 'highlight'}>
                        <td><ItemTooltip item_lookup="a-reel-dream-fishing-pole" showIcon/></td>
                        <td><ItemTooltip item_lookup="monkfish" showIcon/></td>
                    </tr>
                    <tr className={this.state.current_item === "the-night-rod-fishing-pole" && 'highlight'}>
                        <td><ItemTooltip item_lookup="the-night-rod-fishing-pole" showIcon/></td>
                        <td><ItemTooltip item_lookup="shark" showIcon/></td>
                    </tr>
                    <tr className={this.state.current_item === "master-fishing-pole" && 'highlight'}>
                        <td><ItemTooltip item_lookup="master-fishing-pole" showIcon/></td>
                        <td><ItemTooltip item_lookup="lobster" showIcon/></td>
                    </tr>
                    </tbody>
                </table>
            </>
        )
    }
}

export const MasterworkRequirementTableRedo = ({current_item = "", specific_island = ""}) => {
    const isHighlighted = (itemAlias) => current_item.includes(itemAlias) ? 'highlight' : '';
    const {settings} = useContext(SettingsContext);

    return (
        <>
            {specific_island === "" && (<TabNavigation/>)}

            <div className={`ui bottom attached tab segment mwdtable ${specific_island ? (specific_island === 'bastion' ? 'active' : '') : (settings.favouriteIsland === 'bastion' ? 'active' : '')}`}>
                <table className="ui table basic inverted striped unstackable">
                    <thead>
                    <tr>
                        <th width="70%">Design (Item)</th>
                        <th width="30%">Requirements <span style={{float: "right"}}>(weight)</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className={isHighlighted("forged-iron-intake")}>
                        <td><ItemTooltip item_lookup="forged-iron-intake-booster-design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="forged-iron-intake-booster-hook" showIcon/></small></td>
                        <td>
                            {[
                                {resource: "iron-bar", amount: 60}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("forged-iron-drill")}>
                        <td><ItemTooltip item_lookup="forged-iron-drill-masterwork-design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="forged-iron-drill" showIcon/></small></td>
                        <td>
                            {[
                                {resource: "iron-bar", amount: 300}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("forged-iron-harvester")}>
                        <td><ItemTooltip item_lookup="forged-iron-harvester-masterwork-design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="forged-iron-harvester" showIcon/></small></td>
                        <td>
                            {[
                                {resource: "iron-bar", amount: 100}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("cloutium-intake")}>
                        <td><ItemTooltip item_lookup="cloutium-intake-booster-design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="cloutium-intake-booster-hook" showIcon/></small></td>
                        <td>
                            {[
                                {resource: "cloutium-bar", amount: 50}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("cloutium-drill")}>
                        <td><ItemTooltip item_lookup="cloutium-drill-masterwork-design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="cloutium-drill" showIcon/></small></td>
                        <td>
                            {[
                                {resource: "iron-bar", amount: 1000},
                                {resource: "cloutium-bar", amount: 150}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("cloutium-harvester")}>
                        <td><ItemTooltip item_lookup="cloutium-harvester-masterwork-design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="cloutium-harvester" showIcon/></small></td>
                        <td>
                            {[
                                {resource: "iron-bar", amount: 500},
                                {resource: "cloutium-bar", amount: 50}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("corestone-intake")}>
                        <td><ItemTooltip item_lookup="corestone-intake-booster-design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="corestone-intake-booster-hook" showIcon/></small></td>
                        <td>
                            {[
                                {resource: "corestone-bar", amount: 300}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("corestone-drill")}>
                        <td><ItemTooltip item_lookup="corestone-drill-masterwork-design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="corestone-drill" showIcon/></small></td>
                        <td>
                            {[
                                {resource: "cloutium-bar", amount: 500},
                                {resource: "corestone-bar", amount: 800}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("corestone-harvester")}>
                        <td><ItemTooltip item_lookup="corestone-harvester-masterwork-design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="corestone-harvester" showIcon/></small></td>
                        <td>
                            {[
                                {resource: "cloutium-bar", amount: 200},
                                {resource: "corestone-bar", amount: 600}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className={`ui bottom attached tab segment mwdtable ${specific_island ? (specific_island === 'volcalidus' ? 'active' : '') : (settings.favouriteIsland === 'volcalidus' ? 'active' : '')}`}>
                <table className="ui table basic inverted striped unstackable">
                    <thead>
                    <tr>
                        <th width="70%">Design (Item)</th>
                        <th width="30%">Requirements <span style={{float: "right"}}>(weight)</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className={isHighlighted("Lava Drill")}>
                        <td>
                            <ItemTooltip item_lookup="Lava Drill Masterwork Design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="Lava Drill" showIcon/></small>
                        </td>
                        <td>
                            {[
                                {resource: "iron-bar", amount: 500},
                                {resource: "cloutium-bar", amount: 100}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("Lava Harvester")}>
                        <td>
                            <ItemTooltip item_lookup="Lava Harvester Masterwork Design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="Lava Harvester" showIcon/></small>
                        </td>
                        <td>
                            {[
                                {resource: "iron-bar", amount: 400},
                                {resource: "cloutium-bar", amount: 80}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("Lava Intake Booster Hook")}>
                        <td>
                            <ItemTooltip item_lookup="Lava Intake Booster Masterwork Design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="Lava Intake Booster Hook" showIcon/></small>
                        </td>
                        <td>
                            {[
                                {resource: "cloutium-bar", amount: 300},
                                {resource: "corestone-bar", amount: 800}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("Dull Icepick")}>
                        <td>
                            <ItemTooltip item_lookup="Dull Icepick Masterwork Design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="Dull Icepick" showIcon/></small>
                        </td>
                        <td>
                            {[
                                {resource: "iron-bar", amount: 80}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("Common Icepick")}>
                        <td>
                            <ItemTooltip item_lookup="Common Icepick Masterwork Design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="Common Icepick" showIcon/></small>
                        </td>
                        <td>
                            {[
                                {resource: "iron-bar", amount: 620},
                                {resource: "gold-bar", amount: 540}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("Advanced Icepick")}>
                        <td>
                            <ItemTooltip item_lookup="Advanced Icepick Masterwork Design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="Advanced Icepick" showIcon/></small>
                        </td>
                        <td>
                            {[
                                {resource: "cloutium-bar", amount: 120},
                                {resource: "corestone-bar", amount: 370}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("Dull Ice Shovel")}>
                        <td>
                            <ItemTooltip item_lookup="Dull Ice Shovel Masterwork Design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="Dull Ice Shovel" showIcon/></small>
                        </td>
                        <td>
                            {[
                                {resource: "iron-bar", amount: 80}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("Common Ice Shovel")}>
                        <td>
                            <ItemTooltip item_lookup="Common Ice Shovel Masterwork Design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="Common Ice Shovel" showIcon/></small>
                        </td>
                        <td>
                            {[
                                {resource: "iron-bar", amount: 620},
                                {resource: "gold-bar", amount: 540}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("Advanced Ice Shovel")}>
                        <td>
                            <ItemTooltip item_lookup="Advanced Ice Shovel Masterwork Design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="Advanced Ice Shovel" showIcon/></small>
                        </td>
                        <td>
                            {[
                                {resource: "cloutium-bar", amount: 120},
                                {resource: "corestone-bar", amount: 370}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("Heavy Lava Drill")}>
                        <td>
                            <ItemTooltip item_lookup="Heavy Lava Drill Masterwork Design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="Heavy Lava Drill" showIcon/></small>
                        </td>
                        <td>
                            {[
                                {resource: "cloutium-bar", amount: 400},
                                {resource: "corestone-bar", amount: 700}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    <tr className={isHighlighted("Heavy Lava Harvester")}>
                        <td>
                            <ItemTooltip item_lookup="Heavy Lava Harvester Masterwork Design" showIcon/><br/>
                            <small>&emsp; ↪ <ItemTooltip item_lookup="Heavy Lava Harvester" showIcon/></small>
                        </td>
                        <td>
                            {[
                                {resource: "cloutium-bar", amount: 300},
                                {resource: "corestone-bar", amount: 600}
                            ].map(res => (
                                <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                            ))}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export const ConstructionCrateRequirementsTable = ({current_item = ""}) => {
    const isHighlighted = (itemAlias) => current_item.includes(itemAlias) ? 'highlight' : '';

    return (
        <>
            <table className="ui table basic inverted striped unstackable">
                <thead>
                <tr>
                    <th width="70%">Construction Crate</th>
                    <th width="30%">Requirements <span style={{float: "right"}}>(weight)</span></th>
                </tr>
                </thead>
                <tbody>
                <tr className={isHighlighted("jeweller-construction-crate")}>
                    <td><ItemTooltip item_lookup="jeweller-construction-crate" showIcon/></td>
                    <td>
                        {[
                            {resource: "hardstone-block", amount: 40},
                            {resource: "iron-bar", amount: 50},
                        ].map(res => (
                            <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                        ))}
                    </td>
                </tr>
                <tr className={isHighlighted("ticket-office-construction-crate")}>
                    <td><ItemTooltip item_lookup="ticket-office-construction-crate" showIcon/></td>
                    <td>
                        {[
                            {resource: "hardstone-block", amount: 180},
                            {resource: "iron-bar", amount: 200},
                            {resource: "cloutium-bar", amount: 10},
                        ].map(res => (
                            <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                        ))}
                    </td>
                </tr>
                <tr className={isHighlighted("tier-1-construction-crate")}>
                    <td><ItemTooltip item_lookup="tier-1-construction-crate" showIcon/></td>
                    <td>
                        {[
                            {resource: "hardstone-block", amount: 20},
                            {resource: "iron-bar", amount: 30}
                        ].map(res => (
                            <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                        ))}
                    </td>
                </tr>
                <tr className={isHighlighted("tier-2-construction-crate")}>
                    <td><ItemTooltip item_lookup="tier-2-construction-crate" showIcon/></td>
                    <td>
                        {[
                            {resource: "hardstone-block", amount: 700},
                            {resource: "iron-bar", amount: 800},
                            {resource: "cloutium-bar", amount: 40},
                        ].map(res => (
                            <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                        ))}
                    </td>
                </tr>
                <tr className={isHighlighted("tier-3-construction-crate")}>
                    <td><ItemTooltip item_lookup="tier-3-construction-crate" showIcon/></td>
                    <td>
                        {[
                            {resource: "hardstone-block", amount: 5400},
                            {resource: "corestone-bar", amount: 400},
                            {resource: "cloutium-bar", amount: 30},
                        ].map(res => (
                            <><ItemTooltip item_lookup={res.resource} showIcon/> <span style={{float: "right"}}>({res.amount})</span><br/></>
                        ))}
                    </td>
                </tr>
                </tbody>
            </table>
        </>
    );
};

export class CropTable extends React.Component {
    state = {
        current_item: this.props.current_item || ""
    }

    render() {
        return (
            <>
                <table className="ui table basic inverted striped unstackable">
                    <thead>
                    <tr>
                        <th>Seed Bag</th>
                        <th>Growth Time</th>
                        <th>Veg</th>
                    </tr>
                    </thead>
                    <tbody>
                    {[
                        {name: "tomato", time: 600, veg: true},
                        {name: "carrot", time: 800, veg: true},
                        {name: "corn", time: 1000, veg: true},
                        {name: "onion", time: 1200, veg: true},
                        {name: "broccoli", time: 1400, veg: true},
                        {name: "beetroot", time: 1600, veg: true},
                        {name: "cactus", time: 1200, veg: false},
                        {name: "bells", time: 1200, veg: false},
                        {name: "flower", time: 1200, veg: false},
                        {name: "daisy", time: 1200, veg: false},
                        {name: "bush", time: 1200, veg: false},
                        {name: "birch", time: 1200, veg: false},
                        {name: "pine-tree", time: 1200, veg: false},
                        {name: "maple-tree", time: 1200, veg: false}
                    ].map(crop => (
                        <tr className={this.state.current_item.toLowerCase().includes(crop.name) && 'highlight'}>
                            <td><ItemTooltip item_lookup={crop.name + "-seed"} showIcon/></td>
                            <td title={crop.time + " Seconds"}>~{Math.round(crop.time / 60)} Minutes</td>
                            <td>{crop.veg && (<ItemTooltip item_lookup={crop.name.toLowerCase()} showIcon/>)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        )
    }
}

export class AnvilCarousel extends React.Component {
    state = {
        current_item: this.props.current_item || ""
    }

    render() {

        return (
            <>
                <AliceCarousel>
                    <div className="anvilSlide">
                        <h2>Dagger</h2>
                        <img src="https://cdn.bridgepour.com/images/anvil/T_UI_Request_Icon_dagger-numbers.png"
                             alt="crafted item" width="128px"/>
                        <table className="anvilSlotTable">
                            <tr>
                                <td>
                                    <select>
                                        <option>Bar</option>
                                        <option>Scrap Bar</option>
                                        <option>Iron Bar</option>
                                        <option>Gold Bar</option>
                                        <option>Cloutium Bar</option>
                                        <option>Corestone Bar</option>
                                    </select>
                                </td>
                                <td>
                                    <select disabled>
                                        <option>None</option>
                                    </select></td>
                                <td>
                                    <select disabled>
                                        <option>None</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="anvilSlide">
                        <h2>Axe</h2>
                        <img src="https://cdn.bridgepour.com/images/anvil/T_UI_Request_Icon_axe-numbers.png"
                             alt="crafted item"
                             width="128px"/>
                        <table className="anvilSlotTable">
                            <tr>
                                <td>
                                    <select>
                                        <option>Bar</option>
                                        <option>Scrap Bar</option>
                                        <option>Iron Bar</option>
                                        <option>Gold Bar</option>
                                        <option>Cloutium Bar</option>
                                        <option>Corestone Bar</option>
                                    </select>
                                </td>
                                <td>
                                    <select>
                                        <option>Bar</option>
                                        <option>Scrap Bar</option>
                                        <option>Iron Bar</option>
                                        <option>Gold Bar</option>
                                        <option>Cloutium Bar</option>
                                        <option>Corestone Bar</option>
                                    </select></td>
                                <td>
                                    <select disabled>
                                        <option>None</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="anvilSlide">
                        <h2>Sword</h2>
                        <img src="https://cdn.bridgepour.com/images/anvil/T_UI_Request_Icon_sword-numbers.png"
                             alt="crafted item"
                             width="128px"/>
                        <table className="anvilSlotTable">
                            <tr>
                                <td>
                                    <select>
                                        <option>Bar</option>
                                        <option>Scrap Bar</option>
                                        <option>Iron Bar</option>
                                        <option>Gold Bar</option>
                                        <option>Cloutium Bar</option>
                                        <option>Corestone Bar</option>
                                    </select>
                                </td>
                                <td>
                                    <select>
                                        <option>Bar</option>
                                        <option>Scrap Bar</option>
                                        <option>Iron Bar</option>
                                        <option>Gold Bar</option>
                                        <option>Cloutium Bar</option>
                                        <option>Corestone Bar</option>
                                    </select>
                                </td>
                                <td>
                                    <select>
                                        <option>Bar</option>
                                        <option>Scrap Bar</option>
                                        <option>Iron Bar</option>
                                        <option>Gold Bar</option>
                                        <option>Cloutium Bar</option>
                                        <option>Corestone Bar</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="anvilSlide">
                        <h2>Ring</h2>
                        <img src="https://cdn.bridgepour.com/images/anvil/T_UI_Request_Icon_ring-numbers.png"
                             alt="crafted item"
                             width="128px"/>
                        <table className="anvilSlotTable">
                            <tr>
                                <td>
                                    <select>
                                        <option>Bar</option>
                                        <option>Scrap Bar</option>
                                        <option>Iron Bar</option>
                                        <option>Gold Bar</option>
                                        <option>Cloutium Bar</option>
                                        <option>Corestone Bar</option>
                                    </select>
                                </td>
                                <td>
                                    <select>
                                        <option>Gem</option>
                                        <option>Emerald</option>
                                        <option>Sapphire</option>
                                        <option>Ruby</option>
                                        <option>Diamond</option>
                                        <option>Onyx</option>
                                    </select>
                                </td>
                                <td>
                                    <select disabled>
                                        <option>None</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="anvilSlide">
                        <h2>Necklace</h2>
                        <img src="https://cdn.bridgepour.com/images/anvil/T_UI_Request_Icon_necklace-numbers.png"
                             alt="crafted item"
                             width="128px"/>
                        <table className="anvilSlotTable">
                            <tr>
                                <td>
                                    <select>
                                        <option>Bar</option>
                                        <option>Scrap Bar</option>
                                        <option>Iron Bar</option>
                                        <option>Gold Bar</option>
                                        <option>Cloutium Bar</option>
                                        <option>Corestone Bar</option>
                                    </select>
                                </td>
                                <td>
                                    <select>
                                        <option>Bar</option>
                                        <option>Scrap Bar</option>
                                        <option>Iron Bar</option>
                                        <option>Gold Bar</option>
                                        <option>Cloutium Bar</option>
                                        <option>Corestone Bar</option>
                                    </select>
                                </td>
                                <td>
                                    <select>
                                        <option>Gem</option>
                                        <option>Emerald</option>
                                        <option>Sapphire</option>
                                        <option>Ruby</option>
                                        <option>Diamond</option>
                                        <option>Onyx</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                    </div>
                </AliceCarousel>
            </>
        )
    }
}

export class RecipeTableNew extends React.Component {
    state = {
        current_item: this.props.current_item || ""
    }

    render() {
        return (
            <>
                <table className="ui table basic inverted striped unstackable anvilSlotTable">
                    <thead>
                    <tr>
                        <th>Item</th>
                        <th>Slot 1</th>
                        <th>Slot 2</th>
                        <th>Slot 3</th>
                    </tr>
                    </thead>
                    <tbody>
                    {[
                        {
                            name: "dagger",
                            slots: [["Bar", "Scrap Bar", "Iron Bar", "Gold Bar", "Cloutium Bar", "Corestone Bar"], ["None"], ["None"]]
                        },
                        {
                            name: "axe",
                            slots: [["Bar", "Scrap Bar", "Iron Bar", "Gold Bar", "Cloutium Bar", "Corestone Bar"], ["Bar", "Scrap Bar", "Iron Bar", "Gold Bar", "Cloutium Bar", "Corestone Bar"], ["None"]]
                        },
                        {
                            name: "sword",
                            slots: [["Bar", "Scrap Bar", "Iron Bar", "Gold Bar", "Cloutium Bar", "Corestone Bar"], ["Bar", "Scrap Bar", "Iron Bar", "Gold Bar", "Cloutium Bar", "Corestone Bar"], ["Bar", "Scrap Bar", "Iron Bar", "Gold Bar", "Cloutium Bar", "Corestone Bar"]]
                        },
                        {
                            name: "ring",
                            slots: [["Bar", "Scrap Bar", "Iron Bar", "Gold Bar", "Cloutium Bar", "Corestone Bar"], ["Gem", "Emerald", "Sapphire", "Ruby", "Diamond", "Onyx"], ["None"]]
                        },
                        {
                            name: "necklace",
                            slots: [["Bar", "Scrap Bar", "Iron Bar", "Gold Bar", "Cloutium Bar", "Corestone Bar"], ["Bar", "Scrap Bar", "Iron Bar", "Gold Bar", "Cloutium Bar", "Corestone Bar"], ["Gem", "Emerald", "Sapphire", "Ruby", "Diamond", "Onyx"]]
                        }
                    ].map(craftable => (
                        <tr className={this.state.current_item.toLowerCase().includes(craftable.name) && 'highlight'}>
                            <td><ItemTooltip item_lookup={craftable.name} showIcon/></td>
                            {craftable.slots.map(slot => (
                                <td>
                                    <select disabled={slot.length === 1}>
                                        {slot.map(item => (
                                            <option>{item}</option>
                                        ))}
                                    </select>
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        )
    }
}

export class FishRateTable extends React.Component {
    state = {
        current_item: this.props.current_item || ""
    }

    render() {
        return (
            <>
                <table className="ui table basic inverted striped unstackable anvilSlotTable">
                    <thead>
                    <tr>
                        <th width="36%">Item</th>
                        <th width="8%" style={{textAlign: "right"}}>1</th>
                        <th width="8%" style={{textAlign: "right"}}>2</th>
                        <th width="8%" style={{textAlign: "right"}}>3</th>
                        <th width="8%" style={{textAlign: "right"}}>4</th>
                        <th width="8%" style={{textAlign: "right"}}>5</th>
                        <th width="8%" style={{textAlign: "right"}}>6</th>
                        <th width="8%" style={{textAlign: "right"}}>7</th>
                        <th width="8%" style={{textAlign: "right"}}>8</th>
                    </tr>
                    </thead>
                    <tbody>
                    {[
                        {
                            name: "sardine",
                            tiers: [49.9, 13.3, 0, 0, 0, 0, 0, 4.3]
                        }, {
                            name: "herring",
                            tiers: [24.2, 54.8, 60, 44.4, 3.7, 0, 0, 0]
                        }, {
                            name: "mackerel",
                            tiers: [8.6, 13.3, 16.1, 24.5, 56, 42.3, 3.1, 0]
                        }, {
                            name: "cod",
                            tiers: [5.3, 6.2, 9, 11.2, 14, 23.8, 53.9, 44.2]
                        }, {
                            name: "tuna",
                            tiers: [3.6, 2.8, 2.8, 5.2, 6.9, 11.5, 11.7, 22.1]
                        }, {
                            name: "boot",
                            tiers: [2.1, 2.6, 2.9, 2.6, 4.3, 5.6, 0, 9.8]
                        }, {
                            name: "flyingfish",
                            tiers: [2.5, 1.9, 2.4, 2.4, 3.5, 3.3, 4.5, 6.6]
                        }, {
                            name: "prawn",
                            tiers: [2.8, 1.9, 1.8, 2, 2, 2.7, 3, 3]
                        }, {
                            name: "pufferfish",
                            tiers: [1, 1.5, 2.2, 1.9, 2.2, 1.5, 2.1, 2]
                        }, {
                            name: "crab",
                            tiers: [0, 1.7, 1.5, 1.7, 1.6, 1.8, 2.4, 1.8]
                        }, {
                            name: "swordfish",
                            tiers: [0, 0, 1.3, 2, 1.8, 1.1, 1.1, 2]
                        }, {
                            name: "monkfish",
                            tiers: [0, 0, 0, 2.1, 1.2, 0.9, 2, 1.6]
                        }, {
                            name: "shark",
                            tiers: [0, 0, 0, 0, 3, 1.1, 0.9, 1.5]
                        }, {
                            name: "lobster",
                            tiers: [0, 0, 0, 0, 0, 3.7, 1.9, 1.1]
                        }
                    ].map(fish => (
                        <tr className={this.state.current_item.toLowerCase().includes(fish.name) && 'highlight'}>
                            <td><ItemTooltip item_lookup={fish.name} showIcon/></td>
                            <td style={{textAlign: "right"}}>{fish.tiers[0]}%</td>
                            <td style={{textAlign: "right"}}>{fish.tiers[1]}%</td>
                            <td style={{textAlign: "right"}}>{fish.tiers[2]}%</td>
                            <td style={{textAlign: "right"}}>{fish.tiers[3]}%</td>
                            <td style={{textAlign: "right"}}>{fish.tiers[4]}%</td>
                            <td style={{textAlign: "right"}}>{fish.tiers[5]}%</td>
                            <td style={{textAlign: "right"}}>{fish.tiers[6]}%</td>
                            <td style={{textAlign: "right"}}>{fish.tiers[7]}%</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        )
    }
}

export class TierOneInformationLink extends React.Component {
    state = {
        current_item: this.props.current_item || ""
    }

    render() {
        return (
            <>
                <div className="ui segment">
                    <div className="ui header">
                        <p>First time with Machines? Check out our Tier One Machines guide!</p>
                    </div>
                    <p>This guide assumes you have already completed the Hydroneer Beginner Tutorial (accessed from the
                        main menu of the game) and understand the basics.</p>
                    <a className="ui button brand" href="/guide-book/guide/tier-one" style={{marginTop: "-7px"}}>Tier
                        One Guide</a>
                </div>
            </>
        )
    }
}

export class ShelfStorageItemsInformation extends React.Component {
    state = {
        current_item: this.props.current_item || ""
    }

    render() {
        const shelfItems = {
            "Fishing Pot": ["Cod", "Crab", "Flyingfish", "Herring", "Lobster", "Mackerel", "Monkfish", "Prawn", "Pufferfish", "Sardine", "Shark", "Swordfish", "Tuna"],
            "Fish Trophy": ["Cod", "Crab", "Flyingfish", "Herring", "Lobster", "Mackerel", "Monkfish", "Prawn", "Pufferfish", "Sardine", "Shark", "Swordfish", "Tuna"],
            "Golden Head Statue": ["Mining Helmet", "Hard Hat", "Flight Cap", "Prospecting Helmet", "Necklace"],
            "Plinth": ["Shard", "Necklace", "Ring", "Emerald", "Sapphire", "Ruby", "Onyx", "Arrow Relic", "Spyglass Relic", "Skull Relic", "Goblet Relic", "Cross Relic", "Sword Relic"],
            "Rod Rack": ["My First Fishing Pole", "Wonky Fishing Pole", "'ol' slippy grip' Fishing Pole", "'the plaice holder' Fishing Pole", "Emerald Fishing Pole", "'a reel dream' Fishing Pole", "'The Night Rod' Fishing Pole", "Master Fishing Pole"],
            "Safe": ["Scrap Bar", "Shard Bar", "Iron Bar", "Gold Bar", "Corestone Bar", "Cloutium Bar"],
            "Tool Rack": ["Dull Shovel", "Common Shovel", "Advanced Shovel", "Dull Pickaxe", "Common Pickaxe", "Advanced Pickaxe", "Dull Rake", "Common Rake", "Advanced Rake", "Brush", "Construction Hammer", "Blacksmithing Hammer", "Magnet On a Stick", "Saw", "Spanner", "Magnifying Glass", "Gravity Tool", "Creative Wand", "Sickle", "Trowel", "Cooking Knife", "Gem Chisel"],
            "Extended Tool Rack": ["Dull Shovel", "Common Shovel", "Advanced Shovel", "Dull Pickaxe", "Common Pickaxe", "Advanced Pickaxe", "Dull Rake", "Common Rake", "Advanced Rake", "Brush", "Construction Hammer", "Blacksmithing Hammer", "Magnet On a Stick", "Saw", "Spanner", "Magnifying Glass", "Gravity Tool", "Creative Wand", "Sickle", "Trowel", "Cooking Knife", "Gem Chisel"],
            "Seed Box": ["Beetroot Seed", "Broccoli Seed", "Carrot Seed", "Corn Seed", "Onion Seed", "Tomato Seed"],
            "Vertical Weapon Rack": ["Dagger", "Axe", "Sword"],
            "Angled Weapon Rack": ["Dagger", "Axe", "Sword"],
            "Curved Weapon Rack": ["Dagger", "Axe", "Sword"],
            "Task Board": ["Task Note", "World Map"]
        }


        return (
            <>
                <details className="item-faq ui segment">
                    <summary className="title">
                        <SectionTitle title={`${this.props.current_item} is a shelf and can store certain items.`}
                                      iconURL="https://cdn.bridgepour.com/images/badges/Shelf.png"/>
                    </summary>
                    <div className="item-faq-content">
                        <p>You can store items in this shelf by holding the item you wish to store, and pressing
                            the <kbd>E</kbd> (drop) key.</p>
                        <p>You can retrieve a stored item by looking at the item you with to retrieve and pressing
                            the <kbd>E</kbd> (pickup) key again.</p>

                        <InventoryGrid items={shelfItems[this.props.current_item]}/>
                    </div>
                </details>
            </>
        )
    }
}
