import React, { useContext, useEffect, useState } from 'react';
import Loader from "../../Utils/Loader";
import {API_URL} from "../../Utils/Config";
import SettingsContext from "../../SettingsContext";
import TabNavigation from "../../Layouts/TabNavigation";
import PageTitle from "../../Helpers/PageTitle";
import {parseHTMLString} from "../../Utils/Utils";

const GuidebookTowns = () => {
    const { settings } = useContext(SettingsContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const url = API_URL + "/api/towns/all/";
            const response = await fetch(url);
            const data = await response.json();
            setData(data.results);
            setLoading(false);
        };

        fetchData();
    }, []);


    return (
        <>
            <PageTitle title={"The towns of " + settings.favouriteIsland.charAt(0).toUpperCase() + settings.favouriteIsland.slice(1) + ""} subheading={`Get to know the towns of ${settings.favouriteIsland.charAt(0).toUpperCase() + settings.favouriteIsland.slice(1)} along with local store inventories and sellables.`} imageClass={settings.favouriteIsland} />

            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <a href="/guide-book" className="section">Guide Book</a>
                    <i className="right angle icon divider"/>
                    <span className="section">{settings.favouriteIsland}</span>
                    <i className="right angle icon divider"/>
                    <div className="active section">Towns</div>
                </div>

                <TabNavigation/>

                <div className="ui bottom attached tab segment active">
                    <>
                    {loading || !data ? (
                            <Loader/>
                        ) : (
                            <div className="Town-list">
                                {data.filter(town => town.gameVersion ? town.gameVersion.includes(settings.favouriteIsland) : 'bastion').map(town => (
                                    <div key={town.id} className={"ui segment " + town.town_safe_name}>
                                        <h3>
                                            {town.shops.length > 0 ? (
                                                <a href={`/guide-book/towns/${town.town_safe_name}/${town.town_id}`}>{town.name}</a>
                                            ) : (
                                                town.name
                                            )}
                                        </h3>
                                        <p>{parseHTMLString(town.description)}</p>
                                        {town.shops.length > 0 && (
                                            <>
                                                <h5>Notable Locations</h5>
                                                <div className="button-grid">
                                                    {town.shops.map(shop => (
                                                        <>
                                                            <a href={`/guide-book/towns/${town.town_safe_name}/${shop.safe_name}/${shop.id}`}
                                                               className="column four wide ui brand button"
                                                               key={shop.shop_id}
                                                                title={(shop.constructionRequirements && shop.constructionRequirements !== "") ? 'Requires construction' : ''}
                                                            >
                                                                {(shop.constructionRequirements && shop.constructionRequirements !== "") && (
                                                                    <i className="hammer icon" />
                                                                )}

                                                                {shop.name}
                                                            </a>
                                                        </>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                </div>
            </div>
        </>
    );
};

export default GuidebookTowns;
