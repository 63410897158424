import React from 'react';

export default class ScrollToTop extends React.Component {
    constructor() {
        super();
        this.state = {
            isVisible: false
        };
        this.toggleVisibility = this.toggleVisibility.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.toggleVisibility);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.toggleVisibility);
    }

    toggleVisibility() {
        if (window.pageYOffset > 100) {
            this.setState({
                isVisible: true
            });
        } else {
            this.setState({
                isVisible: false
            });
        }
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        });
    }

    render() {
        return (
            <button
                title="Back to top"
                className={`ui button mini brown icon top-link ${this.state.isVisible ? '' : 'hidden'}`}
                onClick={this.scrollToTop}
                style={{ display: this.state.isVisible ? 'inline-block' : 'none' }}
            >
                BACK TO TOP <i className="up triangle icon"/>
            </button>
        );
    }
}
