import React, {useContext, useState} from "react";
import ItemTooltip from "./ItemTooltip";
import SettingsContext from "../../SettingsContext";

const resourceChancesSimple = [
    {
        "resource": "iron-ore",
        "0": "5000"
    },
    {
        "resource": "gold-ore",
        "0": "3730"
    },
    {
        "resource": "corestone-ore",
        "0": "200"
    },
    {
        "resource": "cloutium-ore",
        "0": "110"
    },
    {
        "resource": "shard",
        "0": "470"
    },
    {
        "resource": "uncut-emerald",
        "0": "260"
    },
    {
        "resource": "uncut-sapphire",
        "0": "150"
    },
    {
        "resource": "uncut-ruby",
        "0": "80"
    },
    {
        "resource": "uncut-onyx",
        "0": "10"
    }
]

const resourceChancesSimpleJtV = [
    {
        "resource": "hardstone",
        "0": "2240"
    },
    {
        "resource": "iron-ore",
        "0": "3795"
    },
    {
        "resource": "gold-ore",
        "0": "2827"
    },
    {
        "resource": "corestone-ore",
        "0": "149"
    },
    {
        "resource": "cloutium-ore",
        "0": "82"
    },
    {
        "resource": "shard",
        "0": "357"
    },
    {
        "resource": "uncut-emerald",
        "0": "201"
    },
    {
        "resource": "uncut-sapphire",
        "0": "112"
    },
    {
        "resource": "uncut-ruby",
        "0": "60"
    },
    {
        "resource": "uncut-diamond",
        "0": "22"
    },
    {
        "resource": "uncut-onyx",
        "0": "7"
    }
]

const resourceChances = [
    {
        "resource": "iron-ore",
        "1": "5262",
        "2": "5207",
        "3": "5197",
        "4": "5246",
        "5": "5294",
        "6": "5169",
        "7": "5224",
        "8": "5205",
        "9": "5038",
        "10": "4962",
        "11": "5032",
        "12": "5079",
        "13": "5030",
        "14": "4937",
        "15": "4973",
        "16": "4974",
        "17": "5001",
        "18": "5036",
        "19": "5014",
        "20": "4989",
        "21": "4958",
        "22": "4964",
        "23": "5050",
        "24": "4991",
        "25": "5042",
        "26": "5011",
        "27": "4947",
        "28": "4922",
        "29": "4977",
        "30": "5002"
    },
    {
        "resource": "gold-ore",
        "1": "3763",
        "2": "3771",
        "3": "3728",
        "4": "3654",
        "5": "3667",
        "6": "3707",
        "7": "3660",
        "8": "3689",
        "9": "3678",
        "10": "3761",
        "11": "3706",
        "12": "3653",
        "13": "3733",
        "14": "3776",
        "15": "3775",
        "16": "3774",
        "17": "3712",
        "18": "3698",
        "19": "3674",
        "20": "3716",
        "21": "3817",
        "22": "3759",
        "23": "3683",
        "24": "3709",
        "25": "3733",
        "26": "3666",
        "27": "3781",
        "28": "3784",
        "29": "3759",
        "30": "3764"
    },
    {
        "resource": "corestone-ore",
        "1": "0",
        "2": "0",
        "3": "0",
        "4": "0",
        "5": "0",
        "6": "0",
        "7": "0",
        "8": "0",
        "9": "193",
        "10": "196",
        "11": "190",
        "12": "183",
        "13": "210",
        "14": "176",
        "15": "170",
        "16": "191",
        "17": "203",
        "18": "167",
        "19": "219",
        "20": "201",
        "21": "174",
        "22": "192",
        "23": "206",
        "24": "168",
        "25": "197",
        "26": "210",
        "27": "172",
        "28": "191",
        "29": "202",
        "30": "165"
    },
    {
        "resource": "cloutium-ore",
        "1": "0",
        "2": "115",
        "3": "104",
        "4": "119",
        "5": "108",
        "6": "104",
        "7": "113",
        "8": "93",
        "9": "100",
        "10": "95",
        "11": "114",
        "12": "116",
        "13": "106",
        "14": "106",
        "15": "117",
        "16": "107",
        "17": "114",
        "18": "120",
        "19": "104",
        "20": "114",
        "21": "95",
        "22": "97",
        "23": "95",
        "24": "124",
        "25": "83",
        "26": "106",
        "27": "112",
        "28": "118",
        "29": "128",
        "30": "108"
    },
    {
        "resource": "shard",
        "1": "462",
        "2": "430",
        "3": "506",
        "4": "483",
        "5": "444",
        "6": "506",
        "7": "506",
        "8": "491",
        "9": "485",
        "10": "462",
        "11": "473",
        "12": "452",
        "13": "455",
        "14": "494",
        "15": "446",
        "16": "471",
        "17": "477",
        "18": "475",
        "19": "468",
        "20": "455",
        "21": "428",
        "22": "463",
        "23": "466",
        "24": "491",
        "25": "443",
        "26": "492",
        "27": "430",
        "28": "502",
        "29": "439",
        "30": "476"
    },
    {
        "resource": "uncut-emerald",
        "1": "259",
        "2": "263",
        "3": "267",
        "4": "251",
        "5": "262",
        "6": "273",
        "7": "272",
        "8": "261",
        "9": "265",
        "10": "266",
        "11": "245",
        "12": "260",
        "13": "232",
        "14": "288",
        "15": "286",
        "16": "252",
        "17": "260",
        "18": "259",
        "19": "276",
        "20": "264",
        "21": "277",
        "22": "282",
        "23": "263",
        "24": "278",
        "25": "273",
        "26": "260",
        "27": "295",
        "28": "285",
        "29": "254",
        "30": "275"
    },
    {
        "resource": "uncut-sapphire",
        "1": "166",
        "2": "136",
        "3": "125",
        "4": "153",
        "5": "130",
        "6": "158",
        "7": "133",
        "8": "170",
        "9": "158",
        "10": "164",
        "11": "157",
        "12": "164",
        "13": "149",
        "14": "129",
        "15": "145",
        "16": "142",
        "17": "139",
        "18": "155",
        "19": "141",
        "20": "169",
        "21": "157",
        "22": "141",
        "23": "136",
        "24": "156",
        "25": "151",
        "26": "152",
        "27": "163",
        "28": "110",
        "29": "161",
        "30": "133"
    },
    {
        "resource": "uncut-ruby",
        "1": "78",
        "2": "69",
        "3": "66",
        "4": "81",
        "5": "89",
        "6": "72",
        "7": "85",
        "8": "81",
        "9": "72",
        "10": "84",
        "11": "72",
        "12": "82",
        "13": "76",
        "14": "82",
        "15": "76",
        "16": "81",
        "17": "82",
        "18": "76",
        "19": "96",
        "20": "77",
        "21": "85",
        "22": "96",
        "23": "91",
        "24": "76",
        "25": "69",
        "26": "88",
        "27": "90",
        "28": "79",
        "29": "65",
        "30": "70"
    },
    {
        "resource": "uncut-onyx",
        "1": "10",
        "2": "9",
        "3": "7",
        "4": "13",
        "5": "6",
        "6": "11",
        "7": "7",
        "8": "10",
        "9": "11",
        "10": "10",
        "11": "11",
        "12": "11",
        "13": "9",
        "14": "12",
        "15": "12",
        "16": "8",
        "17": "12",
        "18": "14",
        "19": "8",
        "20": "15",
        "21": "9",
        "22": "6",
        "23": "10",
        "24": "7",
        "25": "9",
        "26": "15",
        "27": "10",
        "28": "9",
        "29": "15",
        "30": "7"
    }
]

const ResourceChanceTable = ({ current_item = "" }) => {
    const {settings, updateSettings} = useContext(SettingsContext);
    const [currentLevel, setCurrentLevel] = useState(1);
    const [currentTab, setCurrentTab] = useState("simple");

    const handleChange = (event) => {
        setCurrentLevel(event.target.value);
    };

    const handleClick = (event) => {
        setCurrentTab(event.target.id);
    };

    const renderRow = (resource, isAdvanced = false) => {
        const highlightClass = current_item !== "" && resource.resource.replace("-bar", "").replace("-ore", "").includes(current_item.replace("-bar", "").replace("-ore", "")) ? 'highlight' : '';
        return (
            <tr className={highlightClass} key={resource.resource}>
                <td><ItemTooltip item_lookup={resource.resource} showIcon/></td>
                <td>{isAdvanced ? (resource[currentLevel] / 100) + '%' : (resource['0'] / 100) + '%'}</td>
            </tr>
        );
    };

    return (
        <>
            <div className="ui top attached tabular menu">
                <div className={currentTab === "simple" ? "item active" : "item"}
                     onClick={() => setCurrentTab("simple")} id="simple">
                    Simple
                </div>
                <div className={currentTab === "advanced" ? "item active" : "item"}
                     onClick={() => setCurrentTab("advanced")} id="advanced">
                    Advanced
                </div>
            </div>

            {currentTab === "simple" ? (
                <div className="ui bottom attached segment">
                    <p className="muted">The numbers below are assuming you are already in tier 3.</p>
                    <table className="ui table basic inverted striped unstackable">
                        <thead>
                        <tr>
                            <th>Resource</th>
                            <th>Chance</th>
                        </tr>
                        </thead>
                        <tbody>
                            {settings.favouriteIsland === 'bastion' ?
                                (
                                    resourceChancesSimple.map(resource => renderRow(resource))
                                ) : (
                                    resourceChancesSimpleJtV.map(resource => renderRow(resource))
                                )

                            }
                        </tbody>
                    </table>
                </div>
            ) : (
                <>
                    {settings.favouriteIsland === 'bastion' ? (
                        <div className="ui bottom attached segment">
                            <p className="muted">Data consists of 10,000 resources per grid square deep over 22 grid squares. (NOTE: Hydroneer only has 22 grid squares available)</p>
                            <table className="ui table basic inverted striped unstackable">
                                <thead>
                                <tr>
                                    <th>Resource</th>
                                    <th>Chance</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Block level: {currentLevel}</td>
                                    <td>
                                        <div className="slidecontainer">
                                            <input type="range" min="1" max="30" className="slider" value={currentLevel} id="myRange" onChange={handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                                {resourceChances.map(resource => renderRow(resource, true))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="ui bottom attached segment">
                            <div className="ui message inverted">
                                Journey to Volcalidus data is being collected, please bear with us whilst we gather the numbers.
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default ResourceChanceTable;
