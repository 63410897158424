import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {API_URL, CDN_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import Helmet from "react-helmet";
import ItemCardRedo from "../Components/Items/ItemCardRedo";
import GroupedItems from "../Components/Items/GroupedItems";
import {
    ItemBuildItem,
    ItemTakesDamage,
    ItemRequiresWater,
    ItemHooks,
    ItemStores,
    ItemCreativeInfo,
    ItemResourceCalculator,
    ItemRequiresLogic,
    ItemHatInfo,
    PageNeedsInfo,
    PoleRequirementTable,
    CropTable,
    AnvilCarousel,
    RecipeTableNew,
    FishRateTable,
    TierOneInformationLink,
    ShelfStorageItemsInformation, ConstructionCrateRequirementsTable, MasterworkRequirementTableRedo
} from "../Components/Items/ItemDetails";
import RecipeTable from "../Components/Items/RecipeTable";
import ShareButton from "../Components/ShareAPI";
import WorldMap from "../Components/Map/Map";
import ItemTooltip from "../Components/Items/ItemTooltip";
import {parseHTMLString, safeName} from "../Utils/Utils";
import SectionTitle from "../Helpers/SectionTitle";
import ResourceChanceTable from "../Components/Items/ResourceChanceTable";
import GoogleAd from "../Components/GoogleAd";
import RelicItem from "../Components/Items/RelicItem";
import TabNavigation from "../Layouts/TabNavigation";
import PageTitle from "../Helpers/PageTitle";


const DatabaseView = () => {
    const {item_safe_name} = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const url = `${API_URL}/api/items/exact/${item_safe_name}/`;
            const response = await fetch(url);
            const json = await response.json();
            setData(json.results[0]);
            setLoading(false);
        };

        fetchData();
    }, [item_safe_name]);


    if (loading) {
        return <Loader/>;
    }


    return (
        <>
            <PageTitle
                title={data.name}
                subheading={data.description}
                imageClass={`item-header ${(data.alias + "").trim().toLowerCase().includes('fish') ? 'water' : ''} ${data.gameVersion === 'volcalidus' ? 'volcalidus' : 'bastion'}`}
                itemBadges={data.badges}
                itemIcon={`${CDN_URL}/images/items/${safeName(data.name)}.png`}
            />

            <div className="ui container">
                <Helmet>
                    <title>{data.name} | Bridgepour | Official Hydroneer Wiki</title>
                    <meta name="description" content={data.description}/>
                </Helmet>
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <a href="/database" className="section">Item Database</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">{data.name}</div>
                </div>

                {data.modded && (
                    <p className="ui message inverted"><i className="info circle icon"/> This item is
                        added via a mod and is only active when the corresponding mod has been
                        activated.</p>
                )}

                <div className="ui two column grid mobile reversed stackable">
                    <div className="eleven wide column">
                        {data.group.name === "Creative" && (
                            <>
                                <p className="ui message inverted">This item is only available to purchase
                                    when in Creative Mode!</p>
                            </>
                        )}

                        {data.details ? (
                            <>
                                {!data.details.includes("N/A") && (
                                    <div className="ui segment itemDetails">
                                        {parseHTMLString(data.details)}
                                    </div>
                                )}
                            </>
                        ) : (
                            <PageNeedsInfo item={data}/>
                        )}

                        {data.name.includes("Construction Crate") && (
                            <>
                                <div className="ui segment">
                                    <h3>{data.name} is used to construct a store in New Glade.</h3>

                                    <p>When purchased, take this crate to one of the five construction sites in New Glade and drop the crate in the green area. Once done, you will see a board with the construction requirements on it.</p>
                                    <p>Simply gather up your resources (preferably a single block / bar for each resource) - making sure to read the WEIGHT not VALUE if checking on <ItemTooltip item_lookup="scales" />! Drop the resources in the open construction crate to deposit. Once the requirements have been met, hit the Red button on the sign.</p>
                                    <p>If you put too much resource in the box than required, you will get the left over resource back, so don't worry about going over.</p>

                                    <ConstructionCrateRequirementsTable current_item={safeName(data.name)}/>

                                    <a className="ui button brand" href="/guide-book/towns/new-glade/progress">Track your progress</a>
                                </div>
                            </>
                        )}

                        {data.name === "World Map" && (
                            <>
                                <div className="ui segment">
                                    <h3>Current World Map</h3>
                                    <p>The World Map displays the map of the game and is the only way to
                                        view the map in the game.</p>

                                    <WorldMap/>
                                </div>
                            </>
                        )}

                        {data.name === "Anvil" && (
                            <>
                                <div className="ui segment">
                                    <h3>Anvil Crafting</h3>
                                    <p>Below are the resources which can be used in each slot, to craft each
                                        item available on the Anvil.</p>
                                    <AnvilCarousel/>
                                </div>
                            </>
                        )}

                        {["Forged Iron Drill", "Forged Iron Harvester"].includes(data.name) && (
                            <>
                                <TierOneInformationLink/>
                            </>
                        )}

                        {(["Masterworking", "Drills", "Harvesters"]).includes(data.group.name) && (
                            <>
                                {data.gameVersion && data.gameVersion.includes('volcalidus') && (
                                    <div className="ui segment">
                                        <SectionTitle title={`Volcalidus Masterworking`}
                                                      iconURL={`https://cdn.bridgepour.com/images/items/forge-mark.png`}/>
                                        <p>When forging on the Volcalidus Masterwork Forge, players can use <ItemTooltip item_lookup="forge-mark" showIcon/> for a chance to improve, or nerf the forged item.</p>
                                        <p>For more information on Forge Mark "buffs" - check out the guide.</p>
                                        <a href="/" className="ui button brand"><i className="book icon"/> Forge Mark Guide</a>
                                    </div>
                                )}

                                <details className="item-faq ui segment">
                                    <summary className="title">
                                        <SectionTitle title={`Masterwork Designs`}
                                                      iconURL={`https://cdn.bridgepour.com/images/items/${safeName(data.name.replace(" Masterwork Design", "-masterwork-design").replace(" Intake Booster Design", "-intake-booster-design"))}.png`}
                                                      subtitle="Below are the current Masterwork Designs and the requirements (in weight) to forge them."/>
                                    </summary>
                                    <div className="item-faq-content">
                                        <p>Read more on the Masterwork Forge in our <a href="/guide-book/guide/masterwork-forge">Masterwork Forge</a> guide.</p>

                                        <MasterworkRequirementTableRedo current_item={safeName(data.name)}/>
                                    </div>
                                </details>
                            </>
                        )}

                        {data.badges.includes("BuildItem") && (
                            <ItemBuildItem item={data}/>
                        )}

                        {data.badges.includes("Shelf") && (
                            <ShelfStorageItemsInformation current_item={data.name}/>
                        )}

                        {data.badges.includes("Hook") && (
                            <ItemHooks item={data}/>
                        )}

                        {data.badges.includes("TakesDamage") && (
                            <ItemTakesDamage item={data}/>
                        )}

                        {data.badges.includes("Water") && (
                            <ItemRequiresWater item={data}/>
                        )}

                        {data.badges.includes("Logic") && (
                            <ItemRequiresLogic item={data}/>
                        )}

                        {data.group.name === "Creative" && (
                            <ItemCreativeInfo item={data}/>
                        )}

                        {data.badges.includes("Hat") && (
                            <ItemHatInfo item={data}/>
                        )}

                        {data.item_type === "Resource Item" && (
                            <>
                                {!((["Dagger", "Axe", "Sword", "Ring", "Necklace"]).includes(data.name)) && (
                                    <>
                                        <ItemResourceCalculator item={data}/>

                                        <RecipeTable item={data}/>

                                        {data.group.name !== "Fishing" && (
                                            <div className="ui segment">
                                                <h3 className="ui header">
                                                    <div className="content">
                                                        What are the spawn chances
                                                        for {data.name.replace(" Bar", "").replace(" Ore", "").replace("Uncut", "")}?
                                                    </div>
                                                </h3>

                                                <TabNavigation/>
                                                <div className="ui segment attached">
                                                    <ResourceChanceTable current_item={safeName(data.name)}/>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}

                        {data.name === "Task Note" && (
                            <>
                                <div className="ui segment">
                                    <h3 className="ui header">
                                        <div className="content">
                                            Where can I find the NPC's?
                                        </div>
                                    </h3>
                                    <p>Not all NPC's give tasks - but they're shown on this map. To find all
                                        Task Giving NPC's, visit our <a href="/guide-book/guide/npc">NPC
                                            infromation</a> page.</p>
                                    <WorldMap npcs/>
                                </div>
                            </>
                        )}

                        {((["Dagger", "Axe", "Sword", "Ring", "Necklace"]).includes(data.name)) && (
                            <>
                                <div className="ui segment">
                                    <h3 className="ui header">
                                        <div className="content">
                                            What resources are needed to craft the {data.name}
                                        </div>
                                    </h3>

                                    <p>Items can be crafted using any bar and if required, any gem. Check
                                        the table below to see which slots are required and which resources
                                        can be used.</p>

                                    <p>For information on how to craft, take a look at the <ItemTooltip
                                        item_lookup="anvil"/>.</p>

                                    <RecipeTableNew current_item={data.name}/>
                                </div>
                            </>
                        )}

                        {data.name.includes("Seed") && (
                            <details className="item-faq ui segment">
                                <summary className="title">
                                    <SectionTitle title={`What is the growth time of seeds?`}
                                                  iconURL={"https://cdn.bridgepour.com/images/items/thumbs/" + safeName(data.name) + ".png"}/>
                                </summary>
                                <div className="item-faq-content">
                                    <p>Growing crops varies from plant to plant, but here's a handy dandy
                                        table of time each crop takes to grow.</p>
                                    <CropTable current_item={data.name}/>
                                </div>
                            </details>
                        )}

                        {/*{data.name.includes("Cut ") && (*/}
                        {/*    <SoupCalculator/>*/}
                        {/*)}*/}

                        {data.group.name === "Fishing Pole" && (
                            <>
                                <div className="ui segment">
                                    <h3 className="ui header">
                                        <div className="content">
                                            How to unlock the {data.name}
                                        </div>
                                    </h3>
                                    <p>{data.name} can be unlocked by handing in a specific catch
                                        to the Pole Trader in Burnscroft:</p>

                                    <PoleRequirementTable current_item={safeName(data.name)}/>
                                </div>
                            </>
                        )}

                        {data.group.name.includes("Fishing") && (
                            <>
                                <details className="item-faq ui segment">
                                    <summary className="title">
                                        <SectionTitle title={`What are the chances of catching Fish`}
                                                      iconURL={"https://cdn.bridgepour.com/images/items/thumbs/" + safeName(data.name) + ".png"}/>
                                    </summary>
                                    <div className="item-faq-content">
                                        <p>For each tier of <a href="/database/groups/fishing-pole">Pole</a>,
                                            below are the chances of each fish to be caught.</p>
                                        <p className="muted">Data consists of 1,000 catches for each
                                            rod.</p>

                                        <FishRateTable current_item={data.name}/>
                                    </div>
                                </details>
                            </>
                        )}

                        {!(["Relic Item", "Currency Item"]).includes(data.item_type) && (
                            <ItemStores item={data}/>
                        )}

                        {(["Relic Item"]).includes(data.item_type) && (
                            <>
                                <RelicItem/>
                            </>
                        )}

                        {data.group.name !== "Ungrouped" && (
                            <GroupedItems group_name={data.group.name}
                                          current_item={data.name}/>
                        )}
                    </div>

                    <div className="sidebar five wide column">
                        <ItemCardRedo item={data} hide_details no_link/>

                        <br/>

                        <ShareButton/>

                        <br/>

                        <GoogleAd slot="4076766254"/>

                        <br/>

                        <div className="ui segment feedback">
                            <div>
                                <h3>Submit Feedback</h3>
                                <p>Feel like this page could do with a bit more
                                    info, or something is wrong? <br/><br/>
                                    <small>We aim to review all submissions within 24 hours!</small>
                                </p>
                                <a className="ui button brand fluid" target="_blank"
                                   rel="noopener noreferrer" href="https://forms.gle/jYnowg5HWoTPUvmB8">Submit
                                    Feedback</a>
                            </div>
                            <i className="thumbs up icon"></i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DatabaseView;