import React from 'react';
import {API_URL} from "../../../Utils/Config";
import Loader from "../../../Utils/Loader";
import WorldMap from "../../../Components/Map/Map";

export default class KingsQuest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            proceed: false,
            data: null
        }
    }

    handleClick = (event) => {
        this.setState({proceed: event.target.value})
    };

    render() {
        return (
            <>
                <div className="ui container">
                    <div className="ui breadcrumb inverted">
                        <a href="/" className="section">Bridgepour Wiki Home</a>
                        <i className="right angle icon divider"/>
                        <a href="/guide-book" className="section">Guide Book</a>
                        <i className="right angle icon divider"/>
                        <div className="active section">Kings Quest</div>
                    </div>

                    <div className="Page-header">
                        <h1>
                            Kings Quest
                            <div className="sub-heading">Warning, SPOILERS AHEAD</div>
                        </h1>
                    </div>

                    <div className="ui container">
                        {!this.state.proceed ? (
                            <>
                              <div className="ui segment">
                                  <h2>WARNING SPOLERS AHEAD</h2>
                                  <div className="ui message inverted">
                                      Ahead you will see spoilers for a super secret thing in Hydroneer. Only proceed if you actually want it spoiled.
                                  </div>

                                  <div className="ui checkbox">
                                      <input type="checkbox" name="proceed" id="proceed" onChange={this.handleClick} />
                                      <label for="proceed" style={{color: "white"}}>I understand the risks, and want to proceed.</label>
                                  </div>
                              </div>
                            </>
                        ) : (
                            <>
                                <div className="ui segment">
                                    <h2>WARNING SPOLERS AHEAD</h2>
                                    <div className="ui message inverted">
                                        Ahead you will see spoilers for a super secret thing in Hydroneer. Only proceed if you actually want it spoiled.
                                    </div>
                                </div>

                                <div className="ui segment">
                                    <h2>The Initiation</h2>
                                    <p>Begin by talking to the Guard outside the Castle. He'll give you the task to create a Dagger.</p>
                                    <p>1. 200 weight Gold Bar</p>
                                    <br/>
                                    <p>REWARD: 805 Prospect Guild Tokens</p>
                                </div>

                                <div className="ui segment">
                                    <h2>Feeding the Guards</h2>
                                    <p>Inside the castle, infront of the left stairs, the guard will ask for a Soup.</p>
                                    <p>1. 300 weight Beetroot</p>
                                    <p>2. 300 weight Broccoli</p>
                                    <br/>
                                    <p>REWARD: 4,685 Farm Guild Tokens</p>
                                </div>

                                <div className="ui segment">
                                    <h2>Custom Weapon</h2>
                                    <p>The guard on the right will ask for a Sword.</p>
                                    <p>Remember, order of resources is very important.</p>
                                    <p>1. 400 weight Corestone Bar</p>
                                    <p>2. 1000 weight Gold Bar</p>
                                    <p>3. 5000 weight Iron Bar</p>
                                    <br/>
                                    <p>REWARD: 18,805 Prospect Guild Tokens</p>
                                </div>

                                <div className="ui segment">
                                    <h2>Feeling Shellfish</h2>
                                    <p>You'll be allowed to progress up the stairs, but be greeted with a shellfish request.</p>
                                    <p>1. 1.5 weight Lobster</p>
                                    <br/>
                                    <p>REWARD: 1,200 Hydrocoins</p>
                                </div>

                                <div className="ui segment">
                                    <h2>For the King!</h2>
                                    <p>Finally, you get to meet the King!</p>
                                    <p>1. 2000 weight Cloutium Bar</p>
                                    <p>2. 700 weight Onyx</p>
                                    <br/>
                                    <p>REWARD: [REDACTED] <br/><span className="ib">By order of the King, the reward shall not be visible on the Wiki.</span></p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>

        )
    }
}