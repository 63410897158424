import React from 'react';
import ItemTooltip from "../../../Components/Items/ItemTooltip";
import ItemCardRedo from "../../../Components/Items/ItemCardRedo";

export default function FishingCatches() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted"><a href="/" className="section">Bridgepour Wiki Home</a><i
                className="right angle icon divider"></i><a href="/guide-book" className="section">Guide Book</a><i
                className="right angle icon divider"></i><a href="/guide-book/guide/fishing"
                                                            className="section">Fishing</a><i
                className="right angle icon divider"></i>
                <div className="active section">Fishing Catches</div>
            </div>
            <div className="Page-header">
                <h1>What can be caught in Hydroneer?
                    <div className="sub-heading">Learn about all catchable sea creatures here!</div>
                </h1>
            </div>
            <div className="ui segment">
                <div className="ui stackable grid">
                    <div className="column">
                        <h2>What fish can be caught in Hydroneer?</h2>
                        <p>You can try to catch a variety of exciting, new sea creatures!</p>
                        <p>
                            In doing so, you have the opportunity to upgrade your Fishing
                            Pole, up to rank 8! Doing so will allow you to catch bigger, rarer fish - in turn, bringing
                            more income to fund your operation!
                        </p>
                    </div>
                </div>
            </div>
            <div className="ui segment"><h3>What can I do with my catch?</h3><p>You've got a couple of options. If you
                are proud of your catch, you can display it on the wall of your base, using a <ItemTooltip item_lookup="fish-trophy" />. Try to catch
                the biggest of each type of fish and display all of them on your wall of achievement!</p><p>You can also
                cook your fish by dropping it on a <ItemTooltip item_lookup="small-furnace" />, for a chance at 10% extra profit! But be
                careful, if you burn your catch, it'll be worthless!</p><p>If you don't want to risk burning your
                profits, you can simply sell raw fish to the Bastion Keep stock market, or any Jeweler.</p></div>
            <div className="ui segment">
                <h3>Common Catches</h3>
                <div className="Item-grid">
                    {(["sardine", "herring", "cod", "flyingfish", "mackerel", "tuna"]).map(x => (
                        <ItemCardRedo item_lookup={x} hide_details/>
                    ))}
                </div>
            </div>
            <div className="ui segment">
                <h3>Rare Catches</h3>

                <div className="Item-grid">
                    {(["prawn", "pufferfish", "monkfish", "swordfish", "crab", "shark", "lobster"]).map(x => (
                        <ItemCardRedo item_lookup={x} hide_details/>
                    ))}
                </div>
            </div>
            <a className="ui brand button" href="/guide-book/guide/fishing"><i
                className="left arrow icon"></i> Fishing
                Intro</a><a className="ui brand button right floated" href="/guide-book/guide/fishing-poles">Fishing
            Tools! <i className="right arrow icon"></i></a></div>
    )
}