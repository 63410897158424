import React from 'react';
import {Link} from "react-router-dom";
import ItemTooltip from "../../../Components/Items/ItemTooltip";
import ResourceChanceTable from "../../../Components/Items/ResourceChanceTable";

export default function GuideGettingStarted() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted">
                <a href="/" className="section">Bridgepour Wiki Home</a>
                <i className="right angle icon divider"/>
                <a href="/guide-book" className="section">Guide Book</a>
                <i className="right angle icon divider"/>
                <div className="active section">Getting Started</div>
            </div>

            <div className="Page-header">
                <h1>
                    Getting Started
                    <div className="sub-heading">Your first few hours within the world of Hydroneer will look like this.</div>
                </h1>
            </div>

            <div className="ui message inverted">
                <p>If you've never played before and need a rundown of the controls, view the <a
                    href="/guide-book/guide/first-timer">First Timers' Guide</a> instead!</p>
            </div>

            <div className="ui segment">
                <div className="ui two column stackable grid">
                    <div className="column">
                        <h2>Hey you, you're finally awake.</h2>
                        <p>When you first arrive in the world of Hydroneer, you will be dropped off at the Ember
                            Cradle digsite, near the town of Burnscroft.</p>
                        <p>Gracefully perched on the hill, you'll see the infamous Bastion Keep fortification!</p>
                    </div>
                    <div className="column">
                        <img src="/assets/images/ember-cradle.jpg" className="ui fluid rounded image" alt=""/>
                    </div>
                </div>
            </div>

            <div className="ui segment">
                <div className="ui two column stackable grid">
                    <div className="column">
                        <h2>It's dangerous to go alone, take these.</h2>
                        <p>On your newly acquired parcel of land you'll notice a few items are lying around for the taking which
                            will help you begin your adventure. Don't worry about trying to understand them all for now, you'll
                            learn about each of them in the next few minutes. This is just so you have a reference of what each
                            item looks like.
                        </p>
                    </div>
                    <div className="column">
                        <table className="ui table basic inverted striped unstackable">
                            <tbody>
                                <tr>
                                    <td width="32px">
                                        <Link to="/database/dull-shovel" item="dull-shovel">
                                            <img src="https://cdn.bridgepour.com/images/items/thumbs/dull-shovel.png" alt="Basic Shovel"/>
                                        </Link>
                                    </td>
                                    <td width="30%"><a href="/database/dull-shovel">Dull Shovel</a>
                                    </td>
                                    <td className="hide-mobile">
                                        Dig dirt into bucket and pour into pan. The deeper you dig, the better the resources!
                                    </td>
                                </tr>
                                <tr>
                                    <td width="32px">
                                        <Link to="/database/bucket" item="bucket">
                                            <img src="https://cdn.bridgepour.com/images/items/thumbs/bucket.png" alt="Bucket"/>
                                        </Link>
                                    </td>
                                    <td width="30%"><a href="/database/bucket">Bucket</a>
                                    </td>
                                    <td className="hide-mobile">
                                        Stores resources, coins, water, and dirt.
                                    </td>
                                </tr>
                                <tr>
                                    <td width="32px">
                                        <Link to="/database/pan" item="pan">
                                            <img src="https://cdn.bridgepour.com/images/items/thumbs/pan.png" alt="Pan"/>
                                        </Link>
                                    </td>
                                    <td width="30%"><a href="/database/pan">Pan</a>
                                    </td>
                                    <td className="hide-mobile">
                                        Fill with water and dirt, use the brush to scrub for resources.
                                    </td>
                                </tr>
                                <tr>
                                    <td width="32px">
                                        <Link to="/database/brush" item="brush">
                                            <img src="https://cdn.bridgepour.com/images/items/thumbs/brush.png" alt="Brush"/>
                                        </Link>
                                    </td>
                                    <td width="30%"><a href="/database/brush">Brush</a>
                                    </td>
                                    <td className="hide-mobile">
                                        Used with a Pan to scrub through dirt and water to find resources.
                                    </td>
                                </tr>
                                <tr>
                                    <td width="32px">
                                        <Link to="/database/lantern" item="lantern">
                                            <img src="https://cdn.bridgepour.com/images/items/thumbs/lantern.png" alt="Lantern"/>
                                        </Link>
                                    </td>
                                    <td width="30%"><a href="/database/lantern">Lantern</a>
                                    </td>
                                    <td className="hide-mobile">
                                        Handheld Lighting Device
                                    </td>
                                </tr>
                                <tr>
                                    <td width="32px">
                                        <Link to="/database/world-map" item="world-map">
                                            <img src="https://cdn.bridgepour.com/images/items/thumbs/world-map.png" alt="World Map"/>
                                        </Link>
                                    </td>
                                    <td width="30%"><a href="/database/world-map">World Map</a>
                                    </td>
                                    <td className="hide-mobile">
                                        Navigate around Hydroneer!
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="ui segment">
                <div className="ui two column stackable grid">
                    <div className="column">
                        <h2>Let's get started!</h2>
                        <p>Grab the <ItemTooltip item_lookup="dull-shovel"/> by pressing <kbd>E</kbd> whilst your cursor is on it,
                            it's leaning up against the light post, then head over to your <ItemTooltip
                                item_lookup="bucket"/> and <ItemTooltip item_lookup="pan"/> that you'll see sitting next to the bridge.</p>
                    </div>
                    <div className="column">
                        <img src="/assets/images/starter-tools.jpg" className="ui fluid rounded image" alt=""/>
                    </div>
                </div>
            </div>

            <div className="ui segment">
                <div className="ui two column stackable grid">
                    <div className="column">
                        <h2>Getting dirty</h2>
                        <p>Make sure you're still holding the Basic Shovel, then look at the bumpy dirt and <kbd>Left
                            click</kbd> to scoop some dirt up. Now, look at your Bucket and <kbd>Left
                            click</kbd> again to place
                            the Dirt in the Bucket. Do this four times and you'll have a full Bucket.</p>
                    </div>
                    <div className="column">
                        <img src="/assets/images/dirt-bucket.jpg" className="ui fluid rounded image" alt=""/>
                    </div>
                </div>
            </div>

            <div className="ui segment">
                <div className="ui two column stackable grid">
                    <div className="column">
                        <h2>Hitting the jackpot</h2>
                        <p>
                            Drop the Basic Shovel by pressing <kbd>E</kbd>. In preparation, grab the <ItemTooltip item_lookup="brush"/>
                            &nbsp;from inside the Pan, and drop it so it's not in the Pan any more. Now pick your Bucket up,
                            look
                            at the floor and you'll see a green circle, line the circle up so it's inside the Pan and <kbd>Left
                            click</kbd> to pour the Dirt from the Bucket into the Pan.
                        </p>
                        <p>
                            To add water to the Pan, pick it up and drop it in the river. Pick the Pan back up again. Don't
                            worry about getting a little wet, you'll soon dry off. Drop the Pan on the river bank. Now grab the
                            Brush you dropped on the floor earlier, look at the dirty water in the Pan and <kbd>Left click</kbd>
                            twice to brush the Dirt. Fingers crossed you get little nuggets in the pan, there is a slight chance
                            no resources will be in that lump of Dirt, keep trying!
                        </p>
                    </div>
                    <div className="column">
                        <ResourceChanceTable />
                    </div>
                </div>
            </div>

            <div className="ui segment">
                <h2>Getting rich... ish</h2>
                <p>
                    Okay, now that you know how to dig for resources, you should do that for a while, some people say 5
                    Buckets full, some people say 30 minutes. Honestly, you do how much you feel like you can do, then
                    come back here.
                </p>
                <p>
                    <strong>Top Tip</strong>: You do not need to empty the Pan before tipping the next ball of Dirt in,
                    any resources will stay inside.
                </p>
                {/*<img src="/assets/images/ember-cradle.jpg" className="ui fluid rounded image" alt=""/>*/}
            </div>

            <div className="ui segment">
                <div className="ui two column stackable grid">
                    <div className="column">
                        <h2>How do I make money?</h2>
                        <p>
                            Now you have a good chunk of resources in the Pan, pick the Pan up and take a trip up the hill
                            towards Bastion Keep. You'll see the Jewelers' store. Drop the Pan on the table, you should see the
                            sale price on the board to the left of their table. Press the button on the price board by pressing
                            <kbd>Left click</kbd>.
                        </p>
                    </div>
                    <div className="column">
                        <img src="/assets/images/jeweler.jpg" className="ui fluid rounded image" alt=""/>
                    </div>
                </div>
            </div>

            <div className="ui segment">
                <h2>Alright... what do I do now..?</h2>
                <p>
                    You're only just starting, read the next article to find out how to refine your resources!
                </p>
            </div>

            <a className="ui brand button" href="/guide-book/guide/first-timer"><i className="left arrow icon"/> First timer</a>
            <a className="ui brand button right floated" href="/guide-book/guide/smithing-crafting">How to smith and craft items <i className="right arrow icon"/></a>
        </div>
    )
}