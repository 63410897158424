import React from 'react';
import ItemCardRedo from "../../../Components/Items/ItemCardRedo";
import ItemTooltip from "../../../Components/Items/ItemTooltip";

export default function TierOneGuide() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted">
                <a href="/" className="section">Bridgepour Wiki Home</a>
                <i className="right angle icon divider"/>
                <a href="/guide-book" className="section">Guide Book</a>
                <i className="right angle icon divider"/>
                <div className="active section">Tier One Guide</div>
            </div>

            <div className="Page-header">
                <h1>
                    Tier 1 Machines
                    <div className="sub-heading">Automation in Hydroneer!</div>
                </h1>
            </div>

            <div className="ui message inverted">
                This guide assumes you have already completed the Hydroneer Beginner Tutorial (accessed from the main menu of the game) and understand the basics.
            </div>

            <div className="ui segment">
                <h2>What will you need to get started?</h2>
                <p>To get started with Tier 1 machies, you will need to accumulate 951 or more Hydrocoins. Once you've got yourself enough coins take your cart and visit the following stores and buy the items below - then bring them back to Ember Cradle (your digsite).</p>

                <h3>Travelling Merchant</h3>
                <div className="Item-grid">
                    <ItemCardRedo item_lookup="forged-iron-drill" />
                    <ItemCardRedo item_lookup="forged-iron-harvester" />
                </div>

                <h3>Tools & Equipment</h3>

                <p>Note - You will need 5x Forged Iron Hydro Pipe (Straight Pipe)</p>

                <div className="Item-grid">
                    <ItemCardRedo item_lookup="forged-iron-intake-water-pipe" showIcon />
                    <ItemCardRedo item_lookup="forged-iron-hydro-pipe" showIcon />
                    <ItemCardRedo item_lookup="forged-iron-hydro-t-pipe" showIcon />
                    <ItemCardRedo item_lookup="forged-iron-hydro-elbow-pipe" showIcon />
                </div>
            </div>

            <div className="ui segment">
                <h2>How do I set this up!?</h2>
                <p>First, we want to place the Intake Pipe, pick it up from your cart, and head over to the stream. Place the intake at the waters edge. You'll know if you placed it right, if water is dripping from the front.<br/>
                    (Tip: Use <kbd>R</kbd> rotate the intake so it's facing the diggable dirt).</p>

                <p>Next place four straight pipes connecting to the intake pipe to ensure the water can travel past the hill.<br/>
                    (Tip: Some items can also be rotated on all axis, for this you can use <kbd>R</kbd>, <kbd>T</kbd> and <kbd>Y</kbd>).</p>

                <p>Here we need the water to head in two directions, so we need to utilise the T Pipe. Place this on the end of the straight pipes, with the shorter side pointing down.</p>

                <p>Next up is the Elbow Pipe, this needs to be rotated so that it bends round from the bottom of the T Pipe you just placed, towards your Digsite. We'll then need the last straight pipe attached to the elbow.</p>

                <p>Before we place the machines, we'll want to ensure the Drill will have some dirt to mine. Grab your shovel, and get a scoop of dirt. Head back to your pipes. Look at the middle of the last pipe we placed, and left click to place a dirt clump on the pipe.</p>

                <p>Now we're ready for the machines, grab your Forged Iron Drill and place it so that it's connected to the T pipe - remember to rotate it correctly so the intake on the drill is attached to the pipe. Next grab your Harvester and attach it to the last straight pipe, the one with dirt on it.</p>

                <p>Hit that red lever on the Drill and stand back to admire your first automated setup!</p>

                <p>You're going to want to place a pan infront of the harvester, otherwise it'll make a mess.</p>

                <img src="/assets/images/guides/t1-basic-setup.png" alt="How to set up your Tier One machines" />

            </div>

            <a className="ui brand button" href="/guide-book/guide/smithing-crafting"><i className="left arrow icon"/> Smithing and Crafting</a>
            <br/>
        </div>
    )
}