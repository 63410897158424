import WorldMap from "../Components/Map/Map";
import ItemTooltip from "../Components/Items/ItemTooltip";
import ItemCardRedo from "../Components/Items/ItemCardRedo";
import parse from "html-react-parser";
import React from "react";
import ItemTableRow from "../Components/Items/ItemTableRow";
import ResourceChanceTable from "../Components/Items/ResourceChanceTable";
import {MasterworkRequirementTableRedo} from "../Components/Items/ItemDetails";

export function safeName(item) {
    return item.replace(/\s+/g, '-').replace(/,/g, "").replace(/'/g, "").toLowerCase();
}

export function numberWithSuffix(number) {
    let num = number.toString();
    if (num.endsWith("11")) return num + "th";
    if (num.endsWith("12")) return num + "th";
    if (num.endsWith("13")) return num + "th";
    if (num.endsWith("1")) return num + "st";
    if (num.endsWith("2")) return num + "nd";
    if (num.endsWith("3")) return num + "rd";
    return num + "th";
}

export const parseHTMLString = (htmlString) => {
    const elements = htmlString.split("{{");
    const reactElements = [];

    elements.forEach((element) => {
        const parts = element.split("}}");
        let parsedElement;

        if (parts[0].startsWith("WorldMap")) {
            parsedElement = <WorldMap />;
        } else if (parts[0].startsWith("WorldMapLocation")) {
            const location = parts[0].split("|")[1];
            parsedElement = <WorldMap location={location} />;
        } else if (parts[0].startsWith("ItemTooltip")) {
            const itemLookup = parts[0].split("|")[1];
            parsedElement = <ItemTooltip item_lookup={itemLookup} />;
        } else if (parts[0].startsWith("ItemCard")) {
            const itemLookup = parts[0].split("|")[1];
            parsedElement = <ItemCardRedo item_lookup={itemLookup}/>;
        } else if (parts[0].startsWith("BastionResourceChanceTable")) {
            parsedElement = <ResourceChanceTable/>;
        } else if (parts[0].startsWith("MasterworkForgeRequirementsTable")) {
            parsedElement = <MasterworkRequirementTableRedo specific_island={parts[0].split("|")[1]} />;
        } else if (parts[0].startsWith("ItemTable")) {
            const items = parts[0].split("|")[1].split(",");
            parsedElement =
                <table className="ui table basic inverted striped unstackable Item-table">
                    <thead>
                    <tr>
                        <th>Item</th>
                        {parts[0].includes("*") ? (
                            <th style={{textAlign: 'right'}}>(Weight)</th>
                        ) : (
                            <>
                                <th>Badges</th>
                                <th>Value</th>
                            </>
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    {items.map(item => {
                            const parts = item.split("*");
                            const itemName = parts[0];
                            const itemAmount = parts.length > 1 ? (parseInt(parts[1], 10)).toLocaleString(undefined, {maximumFractionDigits: 0}) : undefined;

                            return (
                                <React.Fragment key={itemName}>
                                    <ItemTableRow item_lookup={itemName} amt={itemAmount} />
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>;
        } else {
            parsedElement = parse(parts[0]);
        }

        reactElements.push(parsedElement);

        if (parts[1]) {
            reactElements.push(parse(parts[1]));
        }
    });

    return reactElements;
};

