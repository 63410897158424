import React from 'react';
import ItemCardRedo from "../../../Components/Items/ItemCardRedo";

export default function GuideFirstTimer() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted">
                <a href="/" className="section">Bridgepour Wiki Home</a>
                <i className="right angle icon divider"/>
                <a href="/guide-book" className="section">Guide Book</a>
                <i className="right angle icon divider"/>
                <div className="active section">First Timer</div>
            </div>

            <div className="Page-header">
                <h1>
                    First time here?
                    <div className="sub-heading">The absolute basics on what and why Hydroneer is.</div>
                </h1>
            </div>

            <div className="ui segment">
                <div className="ui message inverted">
                    <p>This guide is purely for those who haven't played Hydroneer before and need to know the basics. If you already know the basics, head on over to the <a href="/guide-book/guide/getting-started">Getting Started Guide</a> instead.</p>
                </div>
                <h2>Default keybinds</h2>
                <p><kbd>W</kbd> <kbd>A</kbd> <kbd>S</kbd> <kbd>D</kbd> - Walk forward, strafe left, walk back and strafe right</p>
                <p><kbd>E</kbd> - Pickup / drop / interact</p>
                <p><kbd>C</kbd> - Crouch</p>
                <p><kbd>Left Shift</kbd> - Sprint</p>
                <p><kbd>SpaceBar</kbd> - Jump / handbrake (driving)</p>
                <p><kbd>R</kbd> <kbd>T</kbd> <kbd>Y</kbd> - Rotate Z, X, Y</p>
                <p><kbd>Q</kbd> - Enter / exit vehicle</p>
                <p><kbd>B</kbd> - Open Wiki page of object on cursor</p>
                <p><kbd>Left Click</kbd> - Place object / interact</p>
                <p><kbd>Scroll Wheel</kbd> - Move further / closer when in place mode</p>
            </div>

            <div className="ui segment">
                <h2>So.. what's the idea?</h2>
                <p>The overall steps to playing Hydroneer is to mine, refine, craft and sell in order to purchase better
                     tools, equipment, and bigger parcels of land which are more lucrative for the budding prospector.
                    Eventually your mining operation can be automated the mining and refinement process.</p>
            </div>

            <div className="ui segment">
                <h2>Mine</h2>
                <p>The resources you will find, in order from most common to rare, are;</p>
                <div className="Item-grid">
                    {(["iron-ore", "gold-ore", "uncut-emerald", "uncut-sapphire", "uncut-ruby", "corestone-ore", "shard", "cloutium-ore", "uncut-onyx"]).map(x => (
                        <ItemCardRedo item_lookup={x} hide_details />
                    ))}
                </div>
            </div>

            <div className="ui segment">
                <h2>Refine</h2>
                <p>You can cast / polish your fresh dug resources into various refined materials.</p>
                <p>In order from most common to rare, are;</p>
                <div className="Item-grid">
                    {(["iron-bar", "gold-bar", "emerald", "sapphire", "ruby", "shard-bar", "corestone-bar", "cloutium-bar", "onyx"]).map(x => (
                        <ItemCardRedo item_lookup={x} hide_details />
                    ))}
                </div>
            </div>

            <div className="ui segment">
                <h2>Craft</h2>
                <p>Once you've mined a few resources and refined them into bars / polished gems you can then use an
                    Anvil and craft various items which you are able to sell to the various Jewelers around the world,
                    or try your luck at the Stock Markets to see if you can get a better deal!</p>
                <div className="Item-grid">
                    {(["dagger", "axe", "sword", "ring", "necklace"]).map(x => (
                        <ItemCardRedo item_lookup={x} hide_details />
                    ))}
                </div>
            </div>

            <a className="ui brand button right floated" href="/guide-book/guide/getting-started">Getting started <i className="right arrow icon" /></a>
            <p>Next up, learn how to get started and play the game!</p>
            
            <br/>
        </div>
    )
}