import React, {Component} from "react";


function shareAPI() {
    navigator.share({
        title: window.location.title,
        text: window.location.title,
        url: window.location.href,
    }).then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
}

class ShareButton extends Component {
    render() {
        if (navigator.canShare) {
            return (
                <span className="ui brand button mini fluid" onClick={shareAPI}><i className="share alternate icon"/> Share this page</span>
            )
        } else {
            return <></>
        }
    }
}

export default ShareButton;