import React, {useState, useEffect, useContext} from 'react';
import { numberWithSuffix } from "../Utils/Utils";
import { API_URL } from "../Utils/Config";
import Loader from "../Utils/Loader";
import Moment from "react-moment";
import PageTitle from "../Helpers/PageTitle";
import SettingsContext from "../SettingsContext";

const SpeedrunAll = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filteredRuns, setFilteredRuns] = useState([]);
    const [filter, setFilter] = useState({
        player: "none",
        plot: "none",
        version: "none",
        challenge: "none",
        gameVersion: "none",
        setup: "none",
    });
    const {settings} = useContext(SettingsContext);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(API_URL + "/api/speedruns/");
            const jsonData = await response.json();
            setData(jsonData.results);
            setLoading(false);
        };
        fetchData();
    }, []);

    useEffect(() => {
        const filtered = data.filter(run => {
            return (filter.player === "none" || run.player === filter.player) &&
                (filter.plot === "none" || run.plot === filter.plot) &&
                (filter.version === "none" || run.version === filter.version) &&
                (filter.challenge === "none" || run.challenge === filter.challenge) &&
                (filter.gameVersion === "none" || run.gameVersion === filter.gameVersion) &&
                (filter.setup === "none" || run.setup === filter.setup);
        });
        setLoading(false);
        setFilteredRuns(filtered);
    }, [filter, data]);

    const handleFilterChange = (type, value) => {
        setFilter(prev => ({
            ...prev,
            [type]: value,
        }));
    };

    // Helper component for unique filters
    const SelectFilter = ({ label, options, queryType }) => (
        <div className="field">
            <select value={filter[queryType]} className="ui search dropdown" onChange={e => handleFilterChange(queryType, e.target.value)}>
                <option value="none">{label}</option>
                {options.sort().map(option => (
                    <option key={option} value={option}>{option}</option>
                ))}
            </select>
        </div>
    );

    return (
        <>
            <PageTitle title="ALL Hydroneer Speedruns" subheading="Explore the fastest players in Hydroneer" imageClass={settings.favouriteIsland} />

            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <a href="/speedrun" className="section">Speedruns</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">All</div>
                </div>

                <div className="ui segment">
                    <div className="ui form">
                        <h3>Filter the Speedruns</h3>
                        <div className="five fields">
                            <SelectFilter label="Select User" options={[...new Set(data.map(run => run.player))]} queryType="player" />
                            <SelectFilter label="Select Plot" options={[...new Set(data.map(run => run.plot))]} queryType="plot" />
                            <SelectFilter label="Select Game Version" options={[...new Set(data.map(run => run.version))]} queryType="version" />
                            <SelectFilter label="Select Challenge" options={[...new Set(data.map(run => run.challenge))]} queryType="challenge" />
                            <SelectFilter label="Select Setup" options={[...new Set(data.map(run => run.setup))]} queryType="setup" />
                            <SelectFilter label="Select Location" options={[...new Set(data.map(run => run.gameVersion))]} queryType="gameVersion" />
                        </div>
                    </div>
                    {loading ? <Loader /> : (
                        <table className="ui table basic inverted striped">
                            <thead>
                            <tr>
                                <th width="32px">Rank</th>
                                <th>Player</th>
                                <th>Time</th>
                                <th>Challenge</th>
                                <th>Setup</th>
                                <th>Version</th>
                                <th>Submitted</th>
                                <th style={{ textAlign: "center" }}><i className="ui video camera icon" /></th>
                                <th style={{ textAlign: "center" }}><i className="ui map icon" /></th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredRuns.length === 0 ? (
                                <tr><td colSpan="8">Sorry, no results for that selection.</td></tr>
                            ) : filteredRuns.map((run, index) => (
                                <tr key={index}>
                                    <td style={{textAlign: "center"}}>{numberWithSuffix(index + 1)}</td>
                                    <td>{run.player}</td>
                                    <td>{run.time}</td>
                                    <td>{run.plot} - {run.challenge}</td>
                                    <td>{run.setup}</td>
                                    <td>{run.version}</td>
                                    <td>
                                        <Moment fromNow date={run.timestamp} utc titleFormat="dddd, DD MMMM YYYY"/>
                                    </td>
                                    <td className="selectable" style={{textAlign: "center"}}>
                                        <a href={run.videolink} rel="noopener noreferrer" target="_blank">
                                            <i className={"ui icon " + run.videoProvider}/>
                                        </a>
                                    </td>
                                    <td style={{textAlign: "center"}} title={run.gameVersion}>
                                        {run.gameVersion && run.gameVersion.includes('volcalidus') ? (
                                            <i className="ui icon bone" />
                                        ) : (
                                            <i className="ui icon crown"/>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    );
};

export default SpeedrunAll;
