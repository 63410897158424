import React from "react";
import parse from "html-react-parser";
import PageTitle from "../Helpers/PageTitle";
import FAQItems from "../Data/FAQItems.json";

export default class FAQ extends React.Component {
    render() {
        return (
            <>
                <PageTitle title="Hydroneer FAQ" subheading="Hydroneers' top frequently asked questions." />

                <div className="ui container">
                    <div className="ui segment">
                        <div className="ui inverted divided items">
                            {FAQItems.map(item => (
                                <>
                                    <div className="item">
                                        <div className="content">
                                            <p className="header">{item.name}</p>
                                            <div className="description">{parse(item.description)}</div>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
