import React from 'react';
import './ItemCard.css';
import Loader from "../../Utils/Loader";
import {API_URL, CDN_URL} from "../../Utils/Config";
import {safeName} from "../../Utils/Utils";
import {useParams} from "react-router-dom";
import ItemCardRedo from "./ItemCardRedo";
import PageTitle from "../../Helpers/PageTitle";
import ItemTableRow from "./ItemTableRow";

function withParams(Component) {
    return props => <Component {...props} params={useParams()}/>;
}

class GroupedItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            group_name: props.group_name || this.props.params.group_name,
            current_item: props.current_item,
            data: null
        }
    }

    async componentDidMount() {
        const url = API_URL + `/api/groups/${this.state.group_name}/`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results[0], loading: false})
    }

    addDefaultSrc = (e) => (
        e.target.src = CDN_URL + `/images/items/no_image.png`
    )

    render() {
        return (
            <>

                {!this.state.current_item ? (this.state.loading || !this.state.data ? (
                        <Loader/>
                    ) : (
                        <>
                            <PageTitle title={this.state.data.name} subheading={this.state.data.description}/>
                            <div className="ui container">
                                <div className="ui breadcrumb inverted">
                                    <a href="/" className="section">Bridgepour Wiki Home</a><i
                                    className="right angle icon divider"></i>
                                    <a href="/database" className="section">Item Database</a><i
                                    className="right angle icon divider"></i>
                                    <div className="active section">{this.state.group_name}</div>
                                </div>

                                <div className="ui segment">

                                    <table
                                        className="ui table basic inverted striped unstackable Item-table">
                                        <thead>
                                        <tr>
                                            <th>Item</th>
                                            <th>Badges</th>
                                            <th>Value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.data.items.sort((a, b) => a.name.localeCompare(b.name)).map(item => (
                                            <ItemTableRow item={item} key={item.name}/>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    )
                ) : (
                    this.state.loading || !this.state.data ?
                        (
                            <Loader/>
                        ) : (
                            <>
                                <div className="ui segment">
                                    <a className="ui brand left ribbon label"
                                       href={"/database/groups/" + safeName(this.state.group_name)}><i
                                        className="linkify icon"></i> {this.state.group_name}</a><br/><br/>

                                    <div className="ui horizontal link list">
                                        {this.state.data.items.sort((a, b) => a.name.localeCompare(b.name)).map(item => (
                                            <>
                                                {item.name !== this.state.current_item ? (
                                                    <a href={"/database/" + safeName(item.name)} className="item"
                                                       key={safeName(item.name)}>
                                                        {item.name}
                                                        <img onError={this.addDefaultSrc}
                                                             src={CDN_URL + "/images/items/thumbs/" + safeName(item.name) + ".png"}
                                                             alt={item.name}
                                                             className="ui avatar image"
                                                             loading="lazy"
                                                        />
                                                    </a>
                                                ) : (
                                                    <div className="item active" key={safeName(item.name)}>
                                                        <span>{item.name}</span>
                                                        <img onError={this.addDefaultSrc}
                                                             src={CDN_URL + "/images/items/thumbs/" + safeName(item.name) + ".png"}
                                                             alt={item.name}
                                                             className="ui avatar image"
                                                             loading="lazy"
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )
                )}
            </>
        )
    }
}

export default withParams(GroupedItems);
