import React from 'react';
import YouTube from "react-youtube";

export default class RelicItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            proceed: false,
            opts: {
                width: "100%",
                playerVars: {
                    rel: 0,
                    controls: 0,
                    showinfo: 0
                }
            }
        }
    }

    handleClick = (event) => {
        this.setState({proceed: event.target.value})
    };

    render() {
        return (
            <>
                {!this.state.proceed ? (
                    <>
                      <div className="ui segment">
                          <h2>WARNING SPOILERS AHEAD</h2>
                          <div className="ui message inverted">
                              Ahead you will see spoilers for a super secret thing in Hydroneer. Only proceed if you actually want it spoiled.
                          </div>

                          <div className="ui checkbox">
                              <input type="checkbox" name="proceed" id="proceed" onChange={this.handleClick} />
                              <label for="proceed" style={{color: "white"}}>I understand the risks, and want to proceed.</label>
                          </div>
                      </div>
                    </>
                ) : (
                    <>
                        <div className="ui segment">
                            <h2>WARNING SPOILERS AHEAD</h2>
                            <div className="ui message inverted">
                                Ahead you will see spoilers for a super secret thing in Hydroneer. Only proceed if you actually want it spoiled.
                            </div>

                            <p>The bit beneath here will contain spoilers for Relics, their locations
                                and their reward. Only read on if you truly want to find out.</p>

                            <p><strong>Are you sure? Because there are actually spoilers are below here.
                                You have been warned. No more warnings, just pure spoilers.</strong></p>

                            <p>Okay, okay - spoilers coming up.</p>


                            <div style={{marginLeft: "auto", marginRight: "auto", width: "720px", maxWidth: "100%"}}>
                                <YouTube videoId="PLqrtpnUdiY" opts={this.state.opts} onReady={this._onReady} />
                            </div>
                            <p className="muted">Video credit 100% CrystalDazz on Discord.</p>
                        </div>
                    </>
                )}
            </>

        )
    }
}