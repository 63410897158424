import React, {useState, useEffect, useContext} from 'react';
import {numberWithSuffix} from "../Utils/Utils";
import {API_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import PageTitle from "../Helpers/PageTitle";
import SettingsContext from "../SettingsContext";
import TabNavigation from "../Layouts/TabNavigation";
import moment from 'moment';

const Speedrun = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const {settings} = useContext(SettingsContext);
    const [filteredChallenges, setFilteredChallenges] = useState([]);

    useEffect(() => {
        const fetchChallenges = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/api/speedruns/challenges/`);
                const {results} = await response.json();
                const favouriteIslandChallenges = results.filter(challenge =>
                    challenge.runs.some(run => run.gameVersion ? run.gameVersion.includes(settings.favouriteIsland) : 'bastion')
                );
                setFilteredChallenges(favouriteIslandChallenges);
            } catch (error) {
                console.error('Error fetching challenges:', error);
                // Handle the error as needed
            }
            setLoading(false);
        };

        fetchChallenges();
    }, [settings.favouriteIsland]); // Re-run effect when FavouriteIsland changes

    return (
        <>
            <PageTitle title="Hydroneer Speedruns" subheading="Explore the fastest players in Hydroneer" imageClass={settings.favouriteIsland} />

            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">Speedrun</div>
                </div>

                {loading ? (
                    <Loader/>
                ) : (
                    <>
                        <TabNavigation/>

                        <div className="ui bottom attached tab segment active">
                            <div className="ui vertically divided grid">
                                {filteredChallenges.length > 0 ? (
                                    filteredChallenges.map((challenge, index) => (
                                        <div key={index} className="eight wide column">
                                            <div className="ui segment">
                                                <h3>Top {challenge.runs.filter(run => run.gameVersion ? run.gameVersion.includes(settings.favouriteIsland) : 'bastion').length} - {challenge.challenge}</h3>
                                                <table className="ui table basic inverted striped">
                                                    <tbody>
                                                    {
                                                        challenge.runs.filter(run => run.gameVersion ? run.gameVersion.includes(settings.favouriteIsland) : 'bastion').length === 0 ? (
                                                                <tr>
                                                                    <td colSpan="4">There are currently no speed runs for
                                                                        this
                                                                        category on {settings.favouriteIsland}</td>
                                                                </tr>
                                                            )
                                                            : (
                                                                challenge.runs.filter(run => run.gameVersion ? run.gameVersion.includes(settings.favouriteIsland) : 'bastion').map((run, runIndex) => (
                                                                    <tr key={runIndex}
                                                                        title={moment(run.timestamp).utc().format('dddd, DD MMMM YYYY')}>
                                                                        <td width="5%">{numberWithSuffix(runIndex + 1)}</td>
                                                                        <td width="45%">{run.player}</td>
                                                                        <td width="25%">{run.time}</td>
                                                                        <td width="5%" className="selectable"
                                                                            style={{textAlign: "center"}}><a
                                                                            href={run.videolink}
                                                                            rel="noopener noreferrer" target="_blank"><i
                                                                            className={"ui icon " + run.videoProvider}/></a>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ))) : (
                                    <p><br/> There are no submitted challenges for this island, why not submit
                                        one!?<br/><br/></p>
                                )}
                            </div>
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <a className="ui brand button centered" href="/speedrun/all">View all speedruns</a>
                        </div>
                    </>
                )}

                <div className="ui segment two column grid stackable">
                    <div className="column">
                        <h3>Submit via Google Form</h3>
                        <p>If you wish to submit a speed run entry via google form, please click the link below and fill in the required information.</p>
                        <a className="ui button brand" href="https://forms.gle/QkDw9jbj7fsMgSXJ8" target="_blank" rel="noopener noreferrer"><i className="ui icon stopwatch" /> Speedrun Submission Form</a>
                    </div>
                    <div className="column">
                        <h3>Submit via Discord</h3>
                        <p>If you wish to submit a speed run entry, please send a message in the Hydroneer
                            Discord and tag @ItchyBeard - with the following information;
                            <ul>
                                <li>Plot: <span
                                    className="muted">[Ember Cradle, Dreck Quarry, Mildew's Aquifer, South Hope]</span>
                                </li>
                                <li>Time: <span className="muted">01h 02m 03s</span></li>
                                <li>Game Version: <span className="muted">1.5.1</span></li>
                                <li>Challenge: <span className="muted">1M coins (No mods)</span></li>
                                <li>Setup: <span className="muted">D/G</span></li>
                                <li>Video URL: <span className="muted">https://www.youtube.com/watch?v=dQw4w9WgXcQ</span></li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
        ;
};

export default Speedrun;
