import React, { useState } from 'react';
import {Link} from "react-router-dom";
import Header from "../Layouts/Header";

const fullNavLinks = [
    {
        path: "/",
        icon: "home",
        label: "Hydroneer Wiki Home"
    }, {
        path: "/about-hydroneer",
        icon: "question",
        label: "What is Hydroneer?"
    }, {
        path: "/database",
        icon: "database",
        label: "Item Database"
    }, {
        path: "/database/vehicles",
        icon: "pickup truck",
        label: "Vehicles"
    }, {
        path: "/guide-book",
        icon: "book",
        label: "Guide Book"
    }, {
        path: "/guide-book/digsites",
        icon: "mountain",
        label: "Digsites"
    }, {
        path: "/patch-notes",
        icon: "bug",
        label: "Patch Notes"
    }, {
        path: "/speedrun",
        icon: "stopwatch",
        label: "Speedrun"
    }, {
        path: "/faq",
        icon: "list",
        label: "FAQ"
    }, {
        path: "/mods",
        icon: "wrench",
        label: "Mods"
    }, {
        path: "/about",
        icon: "info",
        label: "About"
    }, {
        path: "/search",
        icon: "search",
        label: "Search"
    }
    // , {
    //     path: "/settings",
    //     icon: "cogs",
    //     label: "Settings"
    // }
]

export default function AppNavigation() {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <>
            <div className={`Navigation ${menuOpen ? ' open' : ' closed'}`}>
                <Header/>
                <div className={`Navigation-main`}>
                    <div className="Navigation-link icon opener" onClick={() => setMenuOpen(!menuOpen)}>
                        <i className="bars icon"/>
                    </div>

                    <div className="navLinks">
                        {fullNavLinks.map(navlink => (
                            <Link className={`Navigation-link icon`} to={navlink.path} onClick={() => setMenuOpen(false)}>
                                <span>{navlink.label}</span>
                                <i className={navlink.icon + " icon"}/>
                            </Link>
                        ))}
                        <a className="Navigation-link icon" rel="noopener noreferrer" target="_blank"
                           href="//store.steampowered.com/app/1106840/Hydroneer/">
                            <span>Buy Hydroneer</span>
                            <i className="steam icon"/>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}