import React, { useState, useEffect } from 'react';
import { API_URL, CDN_URL } from "../Utils/Config";
import Loader from "../Utils/Loader";
import { Link, useSearchParams } from "react-router-dom";
import { safeName } from "../Utils/Utils";

const Search = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.get("q");
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (query) {
                try {
                    const url = `${API_URL}/api/query/${query.replace(/\s+/g, '-').replace(/,/g, "").toLowerCase()}/`;
                    const response = await fetch(url);
                    const data = await response.json();
                    setData(data);
                } catch (error) {
                    console.error('Failed to fetch search results:', error);
                }
            } else {
                setData([]);
            }
            setLoading(false);
        };
        fetchData();
    }, [query]);

    const addDefaultSrc = (e) => (
        e.target.src = `${CDN_URL}/images/items/thumbs/no_image.png`
    );

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="ui container">
            <h3>Search the database</h3>
            <p>Enter your query to search; Items, Shops, Towns and Digsites.</p>

            <form className="search-input-max">
                <div className="ui action fluid input">
                    <input type="text" name="q" placeholder={query} />
                    <button className="ui icon brand button">
                        <i className="search icon" />
                    </button>
                </div>
            </form>

            {data && query && (
                <div className="ui segment">
                    <p>Your search for "<em>{query}</em>" returned {data.count} results.</p>
                    {data.success && (
                        <RenderSearchResults data={data.results} addDefaultSrc={addDefaultSrc} />
                    )}
                </div>
            )}
        </div>
    );
};

const RenderSearchResults = ({ data, addDefaultSrc }) => (
    <table className="ui table basic striped unstackable">
        <tbody>
        {data.map((r, index) => (
            <React.Fragment key={index}>
                {RenderResultRow(r, addDefaultSrc)}
            </React.Fragment>
        ))}
        </tbody>
    </table>
);

const RenderResultRow = (r, addDefaultSrc) => {
    let content, label;

    if (r.inventory !== undefined) {
        label = "STORE";
        content = r.name && <Link to={`/link/store-${r.id}`}>{r.name}</Link>;
    } else if ('topspeed' in r) {
        label = "VEHICLE";
        content = <Link to={`/database/vehicles`}>{r.name}</Link>;
    } else if (r.content !== undefined) {
        label = "NOTE";
        content = r.title && <Link to={`/link/release-${safeName(r.title)}`}>{r.title}</Link>;
    } else if (r.name !== undefined) {
        label = "ITEM";
        content = <Link to={`/link/${safeName(r.name)}`}>{r.name}</Link>;
    } else if (r.html_body !== undefined) {
        label = "BLOG";
        content = <Link to={`/link/blog-${r.id}`}>{r.title}</Link>;
    } else if ('previous_guide_id' in r) {
        label = "GUIDE";
        content = <Link to={`/guide-book/guide/${r.id}/${safeName(r.title)}`}>{r.title}</Link>;
    } else if (r.summary !== undefined) {
        label = "PATCH";
        content = <Link to={`/link/patch-${safeName(r.summary)}`}>{r.summary}</Link>;
    }

    return (
        <tr>
            <td width="50px" style={{ textAlign: "center" }}>
                {r.name ? (
                    <img onError={addDefaultSrc} src={`${CDN_URL}/images/items/thumbs/${safeName(r.name)}.png`} alt={r.name} />
                ) : (
                    <small>{label}</small>
                )}
            </td>
            <td>
                {content}
            </td>
        </tr>
    );
};

export default Search;
