import React from 'react';
import {API_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import parse from 'html-react-parser';
import PageTitle from "../Helpers/PageTitle";

export default class About extends React.Component {
    state = {
        loading: true,
        data: null
    }

    async componentDidMount() {
        const url = API_URL + "/api/releasenotes/";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, loading: false})
    }

    render() {
        return (
            <>
                <PageTitle title="About Bridgepour Wiki" subheading="What is Bridgepour and how did it come about?"/>
                <div className="ui container">
                    <div className="ui breadcrumb inverted">
                        <a href="/" className="section">Bridgepour Wiki Home</a>
                        <i className="right angle icon divider"/>
                        <div className="active section">About Bridgepour Wiki</div>
                    </div>

                    <div className="ui segment">
                        <p>Bridgepour.com was founded by a guy with the peculiar name of <strong>ItchyBeard</strong> on various
                            socials.</p>
                        <p>I spent many hours watching various streamers and youtubers playing Hydroneer, and finally caved in
                            and spent £7.19 and met one of the best game community groups. I felt the desire to give something
                            back to that community that have been nothing but helpful and warming, not only to myself, but to
                            all newcomers to the community.</p>
                        <p>When I first started playing Hydroneer, I had a little idea of what I needed to do due to watching
                            countless hours of lets plays, I knew I had to keep visiting the biggest town in the game to stock
                            up on building supplies and machinery.</p>
                        <p>But there was just... <strong>something</strong> missing.. A hole that needed filling.. </p>
                        <h3>Welcome to Bridgepour Wiki (the Official Hydroneer Wiki!)</h3>
                        <p>Bridgepour Wiki is the official, purpose built
                            fansite of the game Hydroneer and is aimed to act as a "tour guide", bestow
                            important knowledge, tips'n'tricks and provide useful tools to help players of all skill levels.
                        </p>
                        <p>Bridgepour Wiki is maintained by ItchyBeard who is a developer of Hydroneer. Bridgepour Wiki
                            is a passion project and maintained outside of my work time. If you want to support the Foulball Hangover and you don't already own the game,
                            please go and purchase it on Steam. After all, that's the
                            reason this fansite exists! You'll notice there are a handful of adverts around the this website,
                            this is to help with maintenance and hosting costs, any ad revenue received will go directly back
                            into helping Bridgepour Wiki be more awesome. If you feel like supporting me directly you can &nbsp;
                            <a target="_blank" rel="noopener noreferrer" href="https://www.buymeacoffee.com/ItchyBeard"><span role="img" aria-label="Drink">🥤</span> <span>Buy me a Monster</span></a>, any donations are greatly
                            appreciated &lt;3!
                        </p>
                        <p className="ib">
                            ItchyBeard
                        </p>
                    </div>

                    <h3>Special Thanks</h3>
                    <p>The people mentioned below have helped Bridgepour become what it is today.</p>
                    <div className="ui four column grid stackable">
                        <div className="column">
                            <div className="ui fluid inverted card">
                                <div className="content">
                                    <div className="header">Max</div>
                                    <div className="meta">Hydroneer Developer</div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui fluid inverted card">
                                <div className="content">
                                    <div className="header">Joe, JB, Sunny, DanSounds</div>
                                    <div className="meta">For their help and support.</div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui fluid inverted card">
                                <div className="content">
                                    <div className="header">Gamerkuipers</div>
                                    <div className="meta">Exceptional Hydroneer Modder.</div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui fluid inverted card">
                                <div className="content">
                                    <div className="header">Heilos</div>
                                    <div className="meta">Exceptional Hydroneer Modder.</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3>Wiki Contributors</h3>
                    <p>The people mentioned below have helped shape the content of Bridgepour for all players.</p>
                    <div className="ui four column grid stackable">
                        <div className="column">
                            <div className="ui fluid inverted card">
                                <div className="content">
                                <div className="header">Steef</div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui fluid inverted card">
                                <div className="content">
                                    <div className="header">CherryC0p</div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui fluid inverted card">
                                <div className="content">
                                    <div className="header">CrystalDazz</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3>Latest Bridgepour Updates</h3>
                    {this.state.loading || !this.state.data ?
                        (
                            <Loader/>
                        ) : (
                            <>
                                {this.state.data.slice(0, 5).map((data, index) => (
                                    <div className="ui segment" key={data.title}>
                                        <details open={0 === index}>
                                            <summary>
                                                {data.title} &emsp;
                                                <small className="muted">({data.date})</small>
                                            </summary>
                                            <>
                                                <br/>
                                                {parse(data.content)}
                                            </>
                                        </details>
                                    </div>
                                ))}
                            </>
                        )
                    }

                    <div className="ui segment">
                        <details>
                            <summary>
                                Planned Updates &emsp;
                                <small className="muted">(no current ETA or any specific order)</small>
                            </summary>
                            <>
                                <br/>
                                <ul>
                                    <li>Drill Efficiency Calculator.</li>
                                    <li>Blueprint guides</li>
                                </ul>
                            </>
                        </details>
                    </div>
                </div>
            </>
        )
    }
}
