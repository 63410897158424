import React, {useEffect, useState} from 'react';
import Loader from "../../Utils/Loader";
import {API_URL} from "../../Utils/Config";
import {useParams} from "react-router-dom";
import ItemTooltip from "../../Components/Items/ItemTooltip";
import InventoryGrid from "../../Components/Items/InventoryGrid";
import PageTitle from "../../Helpers/PageTitle";

const GuidebookTownView = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const {town_id, town_safe_name} = useParams();
    const [construction, setConstruction] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const url = API_URL + "/api/towns/all/";
            const response = await fetch(url);
            const data = await response.json();
            setData(data.results.filter(town => String(town.town_id) === String(town_id))[0]);
            setConstruction(data.results.filter(town => String(town.town_id) === String(town_id))[0].shops.filter(shop => shop.constructionRequirements !== null && shop.constructionRequirements !== "").length > 0);

            setLoading(false);
        };

        fetchData();
    }, []);

    return (
        loading || !data ? (
            <Loader/>
        ) : (
            <>
                <PageTitle title={data.name} subheading={data.description} imageClass={data.town_safe_name} />

                <div className="ui container">
                    <div className="ui breadcrumb inverted">
                        <a href="/" className="section">Bridgepour Wiki Home</a>
                        <i className="right angle icon divider"/>
                        <a href="/guide-book" className="section">Guide Book</a>
                        <i className="right angle icon divider"/>
                        <a href="/guide-book/towns" className="section">Towns</a>
                        <i className="right angle icon divider"/>
                        <div className="active section">{town_safe_name.replace("-", " ")}</div>
                    </div>

                    <>
                        <div className="Town-list">
                            <>

                                {/*{construction && (*/}
                                {/*    <div className="ui message inverted">*/}
                                {/*        {data.name} has stores that can be built! <a*/}
                                {/*        href={`/guide-book/towns/${data.town_safe_name}/progress`}>Click here</a> to*/}
                                {/*        track your progress!*/}
                                {/*    </div>*/}
                                {/*)}*/}

                                <div>
                                    {data.shops.map(shop => (
                                        <div className="ui segment" key={shop.id}>
                                            <h3>
                                                <a href={`/guide-book/towns/${data.town_safe_name}/${shop.safe_name}/${shop.id}`}
                                                   key={shop.shop_id}>{shop.name}</a>
                                            </h3>
                                            <p>{shop.description}</p>

                                            {(["store", "carts", "logic-power", "stocks", "jeweler", "grocer"]).includes(shop.category) && (
                                                <p>
                                                    A selection of items the store
                                                    {(["store", "carts", "logic-power"]).includes(shop.category) &&
                                                        <span> sells </span>
                                                    }
                                                    {(["stocks", "jeweler", "grocer"]).includes(shop.category) &&
                                                        <span> buys </span>
                                                    }
                                                    in exchange for <ItemTooltip item_lookup={shop.currency}/> can
                                                    be seen below:
                                                </p>
                                            )}

                                            <br/>

                                            <InventoryGrid itemarray={shop.inventory.sort((a, b) => a.name.localeCompare(b.name))}/>

                                        </div>
                                    ))}
                                </div>
                            </>
                        </div>
                    </>
                </div>
            </>
        )
    );
};

export default GuidebookTownView;
