import React from 'react';
import './ItemCard.css';
import {safeName} from "../../Utils/Utils";
import {API_URL, CDN_URL} from "../../Utils/Config";
import Loader from "../../Utils/Loader";

export default class ItemTableRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            item: props.item
        }
    }

    async componentDidMount() {
        if (this.props.item_lookup) {
            const url = API_URL + `/api/items/exact/${this.props.item_lookup}/`;
            const response = await fetch(url);
            const data = await response.json();
            this.setState({item: data.results[0], loading: false})
        } else {
            this.setState({loading: false})
        }
    }

    addDefaultSrc = (e) => (
        e.target.src = `${CDN_URL}/images/items/no_image.png`
    )

    render() {
        return (
            <>
                {this.state.loading || !this.state.item ?
                    (
                        <Loader/>
                    ) : (
                        <>
                            <tr className={`${this.state.item.alias === 'fish' ? 'water' : ''}`}>
                                <td className="selectable" width="70%">
                                    <a href={"/database/" + safeName(this.state.item.name)} className="Item-name-cell">
                                        <img onError={this.addDefaultSrc}
                                             src={CDN_URL + "/images/items/" + safeName(this.state.item.name) + ".png"}
                                             alt={this.state.item.name}
                                             loading="lazy"
                                        />
                                        <div className="content">
                                            {this.state.item.name} <br/>
                                            <span>{this.state.item.description}</span>
                                        </div>
                                    </a>
                                </td>
                                {!this.props.amt && (
                                    <td>
                                        <div className="badges">
                                            {this.state.item.badges.replace("(", "").replace(")", "").split(",").map(badge => (
                                                <>
                                                <div className="badge" title={badge} key={badge}>
                                                        <img src={`${CDN_URL}/images/badges/${badge}.png`}
                                                             alt={badge} loading="lazy" width="32px"/>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </td>
                                )}

                                <td className="text-right">
                                    {this.state.item.value !== 0 && (
                                        <>
                                            {(this.state.item.value).toLocaleString(undefined, {maximumFractionDigits: 0})} {this.props.currency && (
                                                <>
                                                    {this.props.currency === 'hydrocoins' ? 'c' : 't'}
                                                </>
                                            )}
                                        </>
                                    )}

                                    {this.props.amt && (
                                        <>({this.props.amt})</>
                                    )}
                                </td>
                            </tr>
                        </>
                    )}
            </>
        )
    }
}
