import React from 'react';
import {Link} from "react-router-dom";
import logo from './logo.png';
export default function Header() {
    return (
        <header className="App-header">
            <Link to="/"><img src={logo} className="Logo" title="The Official Hydroneer Wiki" alt="Bridgepour wiki - the Hydroneer Wiki"/></Link>
        </header>
    )
}

